import { useState } from 'react';

import { format, isBefore, isToday, isTomorrow } from 'date-fns';

import { useMutation } from '@apollo/client';
import ChatIcon from '@mui/icons-material/Chat';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Box, Divider, IconButton, Typography } from '@mui/material';

import { JOIN_PLAN } from '../../../api/mutation/likePlan';
import { IPlannerDetails } from '../../../api/types/planner';
import { RoutePath } from '../../../constants';
import { IconSubmitButton } from '../../components/icon-submit-button';
import { ProfilePicture } from '../../components/profile-picture';
import { RouterLink } from '../../components/router-link';
import { cardSelectFontStyles } from '../../styles/card-select-font-styles';
import { cardSmallSelectFontStyles } from '../../styles/card-small-select-font-styles';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { useIsMobile } from '../../utils/use-is-mobile';
import { Joins } from '../joins';
import { CreatorPlanOptionsPopover } from './creator-plan-popover-options';
import { PlanComments } from './plan-comments';

interface IPlannerPostProps {
  plan: IPlannerDetails;
  defaultOpenComments?: boolean;
  refetchPlannerVariables: {
    userId: string;
    limit: number;
    offset: number;
    next24Hrs: boolean;
  };
}

export const PlannerPost: React.FC<IPlannerPostProps> = ({
  plan,
  refetchPlannerVariables,
  defaultOpenComments = false,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const [commentsOpen, setCommentsOpen] = useState(defaultOpenComments);
  const colours = useColours();

  const [planJoined, setPlanJoined] = useState(false);

  const [joinPlan, { loading: joinLoading }] = useMutation(JOIN_PLAN);

  const likeButtonFilled = planJoined || plan.joinFilled;

  const handleClickJoin = (id: string) => {
    if (likeButtonFilled) {
      return;
    }

    joinPlan({
      variables: {
        id,
        userId: cookies.signedInUser,
      },
    }).then(() => setPlanJoined(true));
  };

  const getDateFormatOfClimb = (dateOfClimb: string) => {
    const parsedDate = new Date(parseInt(dateOfClimb));
    if (isToday(parsedDate)) {
      return `Today at ${format(parsedDate, 'p')}`;
    }

    if (isTomorrow(parsedDate)) {
      return `Tomorrow at ${format(parsedDate, 'p')}`;
    }

    return format(parsedDate, 'iii do MMM - p');
  };

  const isMobile = useIsMobile();
  const isInPast = isBefore(new Date(parseInt(plan.planDateTime)), new Date());
  return (
    <Box
      sx={{
        opacity: isInPast ? 0.5 : 1,
        width: '100%',
        marginBottom: '0.8rem',
        borderRadius: isMobile ? 0 : '0.8rem',
        boxShadow: '0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%)',
        backgroundColor: colours.elevatedComponentColour,
      }}
    >
      <Box p={1} pb={0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ height: 50, width: 50, mr: 2 }}>
              <ProfilePicture userId={plan.user.id} navigable />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <RouterLink href={`${RoutePath.PROFILE}${plan.user.id}`}>
                <Typography sx={cardTitleFontStyles}>{getNameFromUser(plan.user)}</Typography>
              </RouterLink>
              <Typography sx={cardSubtitleFontStyles(colours)}>
                Is climbing at {plan.location}.
              </Typography>
              <Typography mb={1} sx={cardSubtitleFontStyles(colours)}>
                {getDateFormatOfClimb(plan.planDateTime)}
              </Typography>
              <Typography mb={1} sx={cardSmallSubtitleFontStyles(colours)}>
                Plan made {getDateSinceNowDisplayText(plan.datePosted)}
              </Typography>
            </Box>
          </Box>
          {Boolean(plan.user.id === cookies.signedInUser) && (
            <CreatorPlanOptionsPopover
              plan={plan}
              refetchPlansVariables={refetchPlannerVariables}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ p: 1, pl: 2, pr: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Joins plan={plan} planJoined={planJoined} />
        <Typography
          sx={cardSmallSelectFontStyles(colours)}
          onClick={() => setCommentsOpen(!commentsOpen)}
        >{`${plan.commentsLength || 0} comments`}</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          textAlign: 'center',
          borderBottomLeftRadius: '0.8rem',
          borderBottomRightRadius: '0.8rem',
          p: 1,
        }}
      >
        <IconSubmitButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: '0.8em',
          }}
          inheritColour
          disabled={isInPast || plan?.user?.id === cookies.signedInUser}
          onClick={() => handleClickJoin(plan.id)}
          icon={
            likeButtonFilled ? (
              <GroupAddIcon sx={{ height: 24, width: 24, mr: 1 }} />
            ) : (
              <GroupAddOutlinedIcon sx={{ height: 24, width: 24, mr: 1 }} />
            )
          }
          loading={joinLoading}
          label={likeButtonFilled ? 'Joined' : 'Join'}
          labelSx={cardSelectFontStyles(colours)}
        />
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: '0.8em',
          }}
          disabled={isInPast}
          onClick={() => setCommentsOpen(!commentsOpen)}
        >
          <ChatIcon sx={{ height: 24, width: 24, mr: 1 }} />
          <Typography sx={cardSelectFontStyles(colours)}>Comment</Typography>
        </IconButton>
      </Box>
      {Boolean(commentsOpen) && (
        <PlanComments plan={plan} getPlannerVariables={refetchPlannerVariables} />
      )}
    </Box>
  );
};
