import React, { useState } from 'react';

import { SubmitHandler } from 'react-hook-form';

import { useMutation } from '@apollo/client';

import { ADD_ACTIVITY } from '../../../api/mutation/addActivity';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getCommunityAchievements';
import { GET_GOAL } from '../../../api/query/getGoal';
import { ClimbingType } from '../../../pages/my-progress/types';
import { isGoalComplete } from '../../../utils/is-goal-complete';
import { LogActivityFormInputs } from '../../../validation/schemas/log-activity-form/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { gradeToNumber } from '../progress-graph/utils/grade-to-number';
import { LogActivityFormComponent } from './form';

interface ICommunityLogActivityFormProps {
  goals?: IGoalDetails[];
  refetchActivitiesVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  setCropperOpen?: (isOpen: boolean) => void;
  onSuccess: (goalComplete: boolean, achievementSaved: boolean) => void;
}

export const CommunityLogActivityForm: React.FC<ICommunityLogActivityFormProps> = ({
  goals,
  setCropperOpen = () => null,
  onSuccess,
  refetchActivitiesVariables,
}) => {
  const { cookies } = useClimbingCommunityCookies();

  // const [imageForUpload, setImageForUpload] = useState<Blob | undefined>();

  const refetchQueryGoalData = (data: any, result: any) => {
    switch (result?.data?.addActivity.type) {
      case ClimbingType.BOULDER: {
        const boulderGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.BOULDER);
        const goalComplete = isGoalComplete({
          goal: boulderGoal,
          addActivity: result?.data?.addActivity,
        });
        if (goalComplete) {
          return {
            getGoal: [...(data.getGoal || []).filter((f: any) => f.type !== ClimbingType.BOULDER)],
          };
        }
        break;
      }
      case ClimbingType.LEAD: {
        const leadGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.LEAD);
        const goalComplete = isGoalComplete({
          goal: leadGoal,
          addActivity: result?.data?.addActivity,
        });
        if (goalComplete) {
          return {
            getGoal: [...(data?.getGoal || []).filter((f: any) => f.type !== ClimbingType.LEAD)],
          };
        }
        break;
      }
      case ClimbingType.TOP_ROPE: {
        const topRopeGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.TOP_ROPE);
        const goalComplete = isGoalComplete({
          goal: topRopeGoal,
          addActivity: result?.data?.addActivity,
        });
        if (goalComplete) {
          return {
            getGoal: [
              ...(data?.getGoal || []).filter((f: any) => f.type !== ClimbingType.TOP_ROPE),
            ],
          };
        }
        break;
      }
    }
  };

  const [addActivity, { loading, error }] = useMutation(ADD_ACTIVITY, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_COMMUNITY_ACHIEVEMENTS,
          variables: refetchActivitiesVariables,
        },
        (data) => ({
          getCommunityAchievements: {
            data: [
              {
                id: result?.data?.addActivity.achievement.id,
                user: result?.data?.addActivity.achievement.user,
                comment: result?.data?.addActivity.comment,
                activity: result?.data?.addActivity,
                date: new Date().getTime(),
                goal: null,
                commentsLength: 0,
                likesLength: 0,
                likeFilled: false,
                reported: false,
              },
              ...(data?.getCommunityAchievements?.data || []),
            ],
            count: data?.getCommunityAchievements?.count + 1,
          },
        }),
      );
      cache.updateQuery(
        {
          query: GET_GOAL,
          variables: { userId: cookies.signedInUser },
        },
        (data) => refetchQueryGoalData(data, result),
      );
    },
  });

  const [completed, setComplete] = useState<boolean>(false);
  const [achievement] = useState<boolean>(true);

  const onSubmit: SubmitHandler<LogActivityFormInputs> = async (data) => {
    await addActivity({
      variables: {
        input: {
          userId: cookies.signedInUser,
          attempts: data.attempts,
          comment: data.comment?.trim(),
          completed: completed,
          achievement: achievement,
          date: data.date,
          grade: gradeToNumber({
            boulderingGradeType: cookies.boulderingGradePreference,
            ropeGradeType: cookies.ropeGradePreference,
            grade: data.grade,
            type: data.type,
          })?.toString(),
          intensity: data.intensity,
          type: data.type,
        },
      },
    }).then(async (result) => {
      const goalComplete = isGoalComplete({
        goal: goals?.find((f) => f.type === result.data.addActivity.type),
        addActivity: result.data.addActivity,
      });

      onSuccess(goalComplete, Boolean(result?.data?.addActivity?.achievement?.id));
    });
  };

  return (
    <LogActivityFormComponent
      onSubmit={onSubmit}
      completed={completed}
      achievement={true}
      setImageForUpload={() => null}
      setComplete={setComplete}
      setAchievement={() => null}
      error={error}
      loading={loading}
      setCropperOpen={setCropperOpen}
      communityForm
    />
  );
};
