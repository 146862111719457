import { TFrenchRopeGrades, TYDSRopeGrades } from '../../../../constants/rope-grades';

export const numberToFrenchRopeGrade = (value: number): TFrenchRopeGrades | '' => {
  switch (value) {
    case 0:
      return '';
    case 1:
      return '2';
    case 2:
      return '3';
    case 3:
      return '4';
    case 4:
      return '5a';
    case 5:
      return '5b';
    case 6:
      return '5c';
    case 7:
      return '6a';
    case 8:
      return '6a+';
    case 9:
      return '6b';
    case 10:
      return '6b+';
    case 11:
      return '6c';
    case 12:
      return '6c+';
    case 13:
      return '7a';
    case 14:
      return '7a+';
    case 15:
      return '7b';
    case 16:
      return '7b+';
    case 17:
      return '7c';
    case 18:
      return '7c+';
    case 19:
      return '8a';
    case 20:
      return '8a+';
    case 21:
      return '8b';
    case 22:
      return '8b+';
    case 23:
      return '8c';
    case 24:
      return '8c+';
    case 25:
      return '9a';
    case 26:
      return '9a+';
    case 27:
      return '9b';
    case 28:
      return '9b+';
    case 29:
      return '9c';
    case 30:
      return '9c+';
    default:
      return '';
  }
};

export const numberToYDSRopeGrade = (value: number): TYDSRopeGrades | '' => {
  switch (value) {
    case 0:
      return '';
    case 1:
      return '5.3';
    case 2:
      return '5.4';
    case 3:
      return '5.5';
    case 4:
      return '5.6';
    case 5:
      return '5.7';
    case 6:
      return '5.8';
    case 7:
      return '5.9';
    case 8:
      return '5.10a';
    case 9:
      return '5.10b';
    case 10:
      return '5.10c';
    case 11:
      return '5.10d';
    case 12:
      return '5.11a';
    case 13:
      return '5.11b';
    case 14:
      return '5.11c';
    case 15:
      return '5.11d';
    case 16:
      return '5.12a';
    case 17:
      return '5.12b';
    case 18:
      return '5.12c';
    case 19:
      return '5.12d';
    case 20:
      return '5.13a';
    case 21:
      return '5.13b';
    case 22:
      return '5.13c';
    case 23:
      return '5.13d';
    case 24:
      return '5.14a';
    case 25:
      return '5.14b';
    case 26:
      return '5.14c';
    case 27:
      return '5.14d';
    case 28:
      return '5.15a';
    case 29:
      return '5.15b';
    case 30:
      return '5.15c';
    default:
      return '';
  }
};
