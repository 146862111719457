import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Typography,
} from '@mui/material';

import { ACCEPT_FRIEND_REQUEST } from '../../../api/mutation/acceptFriendRequest';
import { DECLINE_FRIEND_REQUEST } from '../../../api/mutation/declineFriendRequest';
import { GET_FRIEND_LIST } from '../../../api/query/getFriendList';
import { GET_FRIEND_REQUEST_LIST } from '../../../api/query/getFriendRequestList';
import { FriendRequest } from '../../../api/types/friend-request';
import { RoutePath } from '../../../constants';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ProfilePicture } from '../../components/profile-picture';
import { RouterLink } from '../../components/router-link';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IManageCommunityRequestsProps {
  isClub?: boolean;
}

export const ManageCommunityRequests: React.FC<IManageCommunityRequestsProps> = ({ isClub }) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const {
    data: friendRequestResult,
    loading: friendRequestLoading,
    error: friendRequestError,
    refetch,
    fetchMore,
  } = useQuery(GET_FRIEND_REQUEST_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  const [acceptRequest, { loading: acceptLoading, error: acceptError }] = useMutation(
    ACCEPT_FRIEND_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_FRIEND_REQUEST_LIST,
            variables: {
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            },
          },
          (data) => ({
            getFriendRequestList: {
              data: [
                ...(data?.getFriendRequestList?.data || []).filter(
                  (f: any) => f?.sender?.id !== result?.data?.acceptFriendRequest?.friend?.id,
                ),
              ],
              count: data?.getFriendRequestList?.count - 1,
            },
          }),
        );
        cache.updateQuery(
          {
            query: GET_FRIEND_LIST,
            variables: {
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            },
          },
          (data) => ({
            getFriendList: {
              data: [...(data?.getFriendList.data || []), result?.data?.acceptFriendRequest],
              count: (data?.getFriendList?.count ?? 0) + 1,
            },
          }),
        );
      },
    },
  );

  const [declineRequest, { loading: declineLoading, error: declineError }] = useMutation(
    DECLINE_FRIEND_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_FRIEND_REQUEST_LIST,
            variables: {
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            },
          },
          (data) => {
            return {
              getFriendRequestList: {
                data: [
                  ...(data?.getFriendRequestList?.data || []).filter(
                    (f: any) => f?.id !== result?.data?.declineFriendRequest,
                  ),
                ],
                count: data?.getFriendRequestList?.count - 1,
              },
            };
          },
        );
      },
    },
  );

  const handleAddFriend = (id: string) => {
    acceptRequest({ variables: { id, isClub } });
  };

  const handleDeclineFriend = (id: string) => {
    declineRequest({ variables: { id } });
  };

  return (
    <List sx={{ bgcolor: 'background.paper', paddingBottom: 0, p: 2 }} component="nav">
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center' }}>
          Requests
        </Typography>
        <Button
          size="small"
          sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          color="primary"
          onClick={() =>
            refetch({
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {friendRequestLoading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading community requests...'}
        />
      )}
      {Boolean(friendRequestResult?.getFriendRequestList?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          You have no new community requests
        </Alert>
      )}

      <ErrorAlert
        showError={Boolean(
          friendRequestError?.message || acceptError?.message || declineError?.message,
        )}
      />
      {friendRequestResult?.getFriendRequestList?.data?.map((request: FriendRequest) => (
        <React.Fragment key={request.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: 25, width: 25, mr: 2 }}>
                <ProfilePicture userId={request.sender.id} navigable />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <RouterLink href={`${RoutePath.PROFILE}${request.sender.id}`}>
                  <Typography sx={cardTitleFontStyles}>
                    {getNameFromUser(request.sender)}
                  </Typography>
                </RouterLink>
                <Typography
                  sx={cardSubtitleFontStyles(colours)}
                >{`${request.sender.email}`}</Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                disabled={acceptLoading || declineLoading}
                onClick={() => handleDeclineFriend(request.id)}
              >
                {declineLoading ? <CircularProgress size={20} thickness={3} /> : <CloseIcon />}
              </IconButton>
              <IconButton
                disabled={acceptLoading || declineLoading}
                onClick={() => handleAddFriend(request.id)}
              >
                {acceptLoading ? <CircularProgress size={20} thickness={3} /> : <DoneIcon />}
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </React.Fragment>
      ))}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          friendRequestResult?.getFriendRequestList?.data?.length > 0 &&
            friendRequestResult?.getFriendRequestList?.data?.length <
              friendRequestResult?.getFriendRequestList?.count,
        ) && (
          <SubmitButton
            variant="outlined"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={friendRequestLoading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: friendRequestResult?.getFriendRequestList?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getFriendRequestList: {
                      data: [
                        ...previousResult.getFriendRequestList.data,
                        ...fetchMoreResult.getFriendRequestList.data,
                      ],
                      count: fetchMoreResult.getFriendRequestList.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </List>
  );
};
