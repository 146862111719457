import React from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { ClimbingType } from '../../../pages/my-progress/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { ModalHeader } from '../../components/modal-header';
import { GoalContent } from '../goals-content';
import { GoalNotification } from './goal-notification';

interface IGoalsModalProps {
  open: boolean;
  showNotification: boolean;
  tab: ClimbingType;
  setTab: (value: ClimbingType) => void;
  goals?: {
    bouldering: IGoalDetails;
    topRope: IGoalDetails;
    lead: IGoalDetails;
  };
  setOpen: () => void;
  onClose: (value: boolean) => void;
}

export const GoalsModal: React.FC<IGoalsModalProps> = ({
  open,
  tab,
  setTab,
  showNotification,
  goals,
  onClose,
  setOpen,
}) => {
  return (
    <>
      <GoalNotification showNotification={showNotification} handleClick={() => setOpen()} />
      <Dialog open={open} onClose={onClose} fullScreen>
        <ModalHeader onClose={() => onClose(false)} title={'My Goals'} />
        <DialogContent sx={{ padding: 0 }} dividers>
          <GoalContent setTab={setTab} tab={tab} goals={goals} />
        </DialogContent>
      </Dialog>
    </>
  );
};
