import React from 'react';

import { differenceInDays, format } from 'date-fns';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Slider, Typography } from '@mui/material';

import { ClimbingType } from '../../../../pages/my-progress/types';
import { IGoalDetails } from '../../../../validation/schemas/set-goal-form/types';
import { cardSmallSubtitleFontStyles } from '../../../styles/card-small-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { gradeToString } from '../../progress-graph/utils/grade-to-string';
import { numberToGrade } from '../../progress-graph/utils/number-to-grade';

interface IGoalsDetailsProps {
  goalDetails: IGoalDetails;
  showTitle?: boolean;
}

export const GoalsDetails: React.FC<IGoalsDetailsProps> = ({ showTitle = false, goalDetails }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const targetDate = new Date(parseInt(goalDetails.targetDate));
  const beginningDate = new Date(parseInt(goalDetails.beginningDate));
  const totalDays = differenceInDays(targetDate, beginningDate);
  const daysSpent = differenceInDays(new Date(), beginningDate);
  const daysRemaining = differenceInDays(targetDate, new Date());

  return (
    <Grid container>
      {showTitle && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography sx={cardTitleFontStyles}>
            {gradeToString({ type: goalDetails?.type as ClimbingType | undefined })}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Grade</Typography>
        <Typography sx={cardTitleFontStyles}>
          {numberToGrade({
            boulderingGradeType: cookies.boulderingGradePreference,
            ropeGradeType: cookies.ropeGradePreference,
            grade: goalDetails.grade,
            type: goalDetails.type,
          })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Flash</Typography>
        <Typography sx={{ display: 'flex' }}>
          {goalDetails.flash ? <CheckCircleIcon color="primary" /> : <CancelIcon color="action" />}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Beginning date:</Typography>
        <Typography sx={cardTitleFontStyles}>{format(beginningDate, 'do LLL yyyy')}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Target date:</Typography>
        <Typography sx={cardTitleFontStyles}>{format(targetDate, 'do LLL yyyy')}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Days spent:</Typography>
        <Typography sx={cardTitleFontStyles}>{daysSpent}</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>Days remaining:</Typography>
        <Typography sx={cardTitleFontStyles}>{daysRemaining}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pl: 5,
          pr: 5,
          mt: 2,
          mb: 2,
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>{`Timeline (days)`}</Typography>
        <Slider
          min={0}
          marks={[
            {
              value: 0,
              label: totalDays,
            },
            { value: totalDays, label: 'Finish' },
          ]}
          value={daysSpent}
          max={totalDays}
        />
      </Grid>
    </Grid>
  );
};
