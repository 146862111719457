import React from 'react';

import { format } from 'date-fns';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import { IActivityDay, IActivityDetails } from '../../../../pages/my-progress/types';
import { ModalHeader } from '../../../components/modal-header';
import { getNumberOfAttempts } from '../../../utils/get-number-of-attempts';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { numberToGrade } from '../../progress-graph/utils/number-to-grade';

interface IActivityDetailsModalListProps {
  activityDay?: IActivityDay;
  onClose: () => void;
  onSelect: (activity: IActivityDetails) => void;
}

export const ActivityDetailsModalList: React.FC<IActivityDetailsModalListProps> = ({
  activityDay,
  onClose,
  onSelect,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  return (
    <>
      <ModalHeader onClose={onClose} title={'Session Climbs'} />
      <DialogContent sx={{ padding: 2 }} dividers>
        {Boolean(activityDay?.date && !activityDay?.activities.length) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
            <Typography>
              {`You don't have any climbs logged on ${format(
                new Date(parseInt(activityDay!.date)),
                'dd/MM/yyyy',
              )}`}
            </Typography>
          </Box>
        )}
        {activityDay?.activities.length ? (
          <List
            sx={{
              bgcolor: 'background.paper',
              paddingBottom: 0,
              maxHeight: `calc(${window.innerHeight}px - 170px - 56px)`,
              overflow: 'auto',
            }}
          >
            {activityDay?.activities?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <ListItemButton
                    sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onClick={() => onSelect(item)}
                  >
                    <ListItem
                      alignItems="center"
                      sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        primary={
                          <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body1"
                              color="text.primaryInverseColour"
                            >
                              {`${numberToGrade({
                                boulderingGradeType: cookies.boulderingGradePreference,
                                ropeGradeType: cookies.ropeGradePreference,
                                grade: item.grade,
                                type: item.type,
                              })} — ${getNumberOfAttempts(item.attempts, item.completed)}`}
                            </Typography>
                            {item.completed ? (
                              <CheckCircleIcon color="primary" />
                            ) : (
                              <CancelIcon color="action" />
                            )}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </ListItemButton>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              );
            })}
          </List>
        ) : null}
      </DialogContent>
    </>
  );
};
