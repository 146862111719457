import { formatDistance } from 'date-fns';

export const getDateSinceNowDisplayText = (date?: string) => {
  if (!date) {
    return '';
  }

  const convertedDate = new Date(parseInt(date));

  return formatDistance(new Date(convertedDate), new Date(), {
    addSuffix: true,
  });
};
