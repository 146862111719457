import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import { SEARCH_USERS } from '../../../api/query/searchUsers';
import { SEND_FRIEND_REQUEST } from '../../../api/query/sendFriendRequest';
import { IUser } from '../../../api/types';
import { RoutePath } from '../../../constants';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { SearchFriendFormSchema } from '../../../validation/schemas/search-friend-form';
import { SearchFriendFormInputs } from '../../../validation/schemas/search-friend-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { ModalHeader } from '../../components/modal-header';
import { ProfilePicture } from '../../components/profile-picture';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IAddCommunityMemberModal {
  isOpen: boolean;
  onClose: () => void;
}

export const AddCommunityMemberModal: React.FC<IAddCommunityMemberModal> = ({
  isOpen,
  onClose,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState<boolean>(true);
  const [sentRequests, setSentRequests] = useState<string[]>([]);

  const [sendFriendRequest, { loading: sendLoading, error: sendError, reset }] =
    useMutation(SEND_FRIEND_REQUEST);

  const [getSearch, { loading, error, data }] = useLazyQuery(SEARCH_USERS, {
    variables: { email: '', firstname: '', lastname: '' },
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SearchFriendFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(SearchFriendFormSchema),
  });

  // Reset form values on toggle
  useEffect(() => {
    setValue('email', '');
    setValue('firstname', '');
    setValue('lastname', '');
  }, [setValue, showForm]);

  const handleAddFriend = (id: string) => {
    if (Boolean(sentRequests.find((f) => f === id))) {
      return;
    }
    sendFriendRequest({
      variables: { senderUserId: cookies.signedInUser, receiverUserId: id },
    }).then(() => {
      setSentRequests([...sentRequests, id]);
    });
  };

  const onSubmit: SubmitHandler<SearchFriendFormInputs> = (data) => {
    if (Boolean(!data.email && !data.firstname && !data.lastname)) {
      return;
    }

    getSearch({
      variables: {
        email: data.email ?? '',
        firstname: data.firstname ?? '',
        lastname: data.lastname ?? '',
      },
    }).then(() => {
      reset();
      setShowForm(false);
    });
  };

  const handleNavigateToFriend = (id: string) => {
    navigate(`${RoutePath.PROFILE}${id}`);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title={'Find a user'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        {Boolean(!showForm) && (
          <Button
            fullWidth
            sx={{ mb: 2 }}
            endIcon={<SearchIcon />}
            variant="contained"
            onClick={() => setShowForm(true)}
          >
            Change search
          </Button>
        )}
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {showForm && (
            <>
              <Typography sx={cardSubtitleFontStyles(colours)}>
                Search for a user by their account details.
              </Typography>
              <Typography sx={{ ...cardSubtitleFontStyles(colours), mb: 1 }}>
                You don't have to use all the fields, but the ones you do use should match exactly.
              </Typography>
              <TextField
                fullWidth
                sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
                id="email"
                label="Email"
                variant="outlined"
                {...register('email')}
              />
              <TextField
                fullWidth
                sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
                error={Boolean(errors.firstname?.message)}
                helperText={errors.firstname?.message}
                id="firstname"
                label="First name"
                variant="outlined"
                {...register('firstname')}
              />
              <TextField
                fullWidth
                sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
                error={Boolean(errors.lastname?.message)}
                helperText={errors.lastname?.message}
                id="lastname"
                label="Last name"
                variant="outlined"
                {...register('lastname')}
              />
              <SubmitButton sx={{ mb: 1 }} loading={loading} label={'Search'} />
            </>
          )}
        </form>
        {Boolean(!showForm && data?.searchUsers?.length === 0) && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
            No users match the search
          </Alert>
        )}
        {!showForm && (
          <ErrorAlert
            showError={Boolean(error?.message || sendError?.message)}
            message={sendError?.message}
          />
        )}
        {!showForm &&
          data?.searchUsers?.map((user: IUser) => {
            const requestSent = Boolean(sentRequests.find((f) => f === user.id));
            return (
              <React.Fragment key={user.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                      <ProfilePicture userId={user.id} navigable />
                    </Box>
                    <Box
                      sx={{ overflow: 'hidden' }}
                      onClick={() => handleNavigateToFriend(user.id)}
                    >
                      <Typography sx={cardTitleFontStyles}>{getNameFromUser(user)} </Typography>
                      <Typography
                        sx={cardSubtitleFontStyles(colours)}
                      >{`${user.email}`}</Typography>
                    </Box>
                  </Box>
                  <IconButton
                    disabled={sendLoading || requestSent}
                    onClick={() => handleAddFriend(user.id)}
                  >
                    {requestSent ? (
                      <DoneIcon />
                    ) : sendLoading ? (
                      <CircularProgress size={20} thickness={3} />
                    ) : (
                      <PersonAddAltIcon />
                    )}
                  </IconButton>
                </Box>
                <Divider />
              </React.Fragment>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};
