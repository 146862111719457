import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';

import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';

interface ILoadingSpinnerProps {
  label?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ label, size = 40, sx }) => {
  const colours = useColours();

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CircularProgress size={size} thickness={5} data-testid="loading-splinner" />
      {Boolean(label) && (
        <Typography sx={{ ...cardSubtitleFontStyles(colours), mt: 2 }}>{label}</Typography>
      )}
    </Box>
  );
};
