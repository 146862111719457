import { IActivityDetails } from '../../../../pages/my-progress/types';

export const getBackgroundColourData = (items: IActivityDetails[]) => {
  return items.map((item) => {
    if (item.completed) {
      // if (item.attempts === 1) {
      return 'rgba(75, 192, 192, 0.2)';
      // }
      // if (item.attempts > 1 && item.attempts < 5) {
      //   return 'rgba(255, 205, 86, 0.2)';
      // }
      // if (item.attempts === 5) {
      //   return 'rgba(255, 99, 132, 0.2)';
      // }
    }
    return 'rgba(201, 203, 207, 0.2)';
  });
};
