import { gql } from '@apollo/client';

export const GET_ACHIEVEMENT_COMMENTS = gql`
  query GetAchievementComments($id: String!, $userId: String!, $limit: Int!, $offset: Int!) {
    getAchievementComments(id: $id, userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        comment
        date
        user {
          id
          firstName
          lastName
        }
        reported
      }
      count
    }
  }
`;
