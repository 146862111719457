import { gql } from '@apollo/client';

export const GET_MESSAGE_REQUESTS = gql`
  query GetMessageRequests($userId: String!) {
    getMessageRequests(userId: $userId) {
      id
      message
      gym
      requester {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
