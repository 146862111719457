import React from 'react';

import { useMutation } from '@apollo/client';
import { Box, Button } from '@mui/material';

import { ACCEPT_FRIEND_REQUEST } from '../../../api/mutation/acceptFriendRequest';
import { DECLINE_FRIEND_REQUEST } from '../../../api/mutation/declineFriendRequest';
import { GET_PROFILE } from '../../../api/query/getProfile';
import { SEND_FRIEND_REQUEST } from '../../../api/query/sendFriendRequest';
import { IProfile } from '../../../api/types/profile';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IProfileAddingButtonsProps {
  id?: string;
  getProfile?: IProfile;
}

export const ProfileAddingButtons: React.FC<IProfileAddingButtonsProps> = ({ id, getProfile }) => {
  const { cookies } = useClimbingCommunityCookies();

  const [sendFriendRequest, { loading: sendLoading, error: sendError }] = useMutation(
    SEND_FRIEND_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_PROFILE,
            variables: { userId: id, myUserId: cookies.signedInUser },
          },
          (data) => ({
            getProfile: {
              ...data?.getProfile,
              sentFriendRequest: {
                id: result?.data?.sendFriendRequest,
              },
            },
          }),
        );
      },
    },
  );

  const [acceptRequest, { loading: acceptLoading, error: acceptError }] = useMutation(
    ACCEPT_FRIEND_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_PROFILE,
            variables: { userId: id, myUserId: cookies.signedInUser },
          },
          (data) => ({
            getProfile: {
              ...data?.getProfile,
              friend: {
                id: result?.data?.acceptFriendRequest?.id,
              },
            },
          }),
        );
      },
    },
  );

  const [declineRequest, { loading: declineLoading, error: declineError }] = useMutation(
    DECLINE_FRIEND_REQUEST,
    {
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_PROFILE,
            variables: { userId: id, myUserId: cookies.signedInUser },
          },
          (data) => ({
            getProfile: {
              ...data?.getProfile,
              friendRequest: undefined,
            },
          }),
        );
      },
    },
  );

  const sentFriendRequestId = getProfile?.sentFriendRequest?.id;
  const recievedFriendRequestId = getProfile?.friendRequest?.id;
  const friendshipId = getProfile?.friend?.id;

  if (!getProfile || id === cookies.signedInUser) {
    return null;
  }

  return (
    <>
      <Box sx={{ m: 2 }}>
        <ErrorAlert
          showError={Boolean(declineError?.message || acceptError?.message || sendError?.message)}
        />
        {sentFriendRequestId && (
          <Button variant="contained" disabled fullWidth>
            Request sent
          </Button>
        )}
        {!recievedFriendRequestId && !sentFriendRequestId && !friendshipId && (
          <SubmitButton
            variant="contained"
            fullWidth
            onClick={() =>
              sendFriendRequest({
                variables: { senderUserId: cookies.signedInUser, receiverUserId: id },
              })
            }
            loading={sendLoading}
            label={'Add friend'}
          />
        )}
        {recievedFriendRequestId && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <SubmitButton
              variant="contained"
              fullWidth
              label={'Accept'}
              loading={acceptLoading}
              onClick={() => acceptRequest({ variables: { id: recievedFriendRequestId } })}
            />
            <SubmitButton
              variant="outlined"
              fullWidth
              label={'Decline'}
              loading={declineLoading}
              onClick={() => declineRequest({ variables: { id: recievedFriendRequestId } })}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
