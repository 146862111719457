import React from 'react';

import { Box, Button } from '@mui/material';

import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { GoalsDetails } from './goals-details';

interface IClimbingGoalDetailsWithEditProps {
  selectEditGoal: () => void;
  goalDetails?: IGoalDetails;
}

export const ClimbingGoalDetailsWithEdit: React.FC<IClimbingGoalDetailsWithEditProps> = ({
  selectEditGoal,
  goalDetails,
}) => {
  return (
    <Box p={2}>
      {Boolean(goalDetails) && <GoalsDetails goalDetails={goalDetails!} />}
      <Button sx={{ mt: 2 }} onClick={selectEditGoal} fullWidth variant="contained" color="inherit">
        {goalDetails ? 'Edit Goal' : 'Add Goal'}
      </Button>
    </Box>
  );
};
