import { gql } from '@apollo/client';

export const GET_ACHIEVEMENTS = gql`
  query GetAchievements($userId: String!, $limit: Int!, $offset: Int!) {
    getAchievements(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        comment
        reported
        user {
          id
          email
          firstName
          lastName
        }
        activity {
          id
          attempts
          comment
          completed
          grade
          intensity
          type
        }
        goal {
          id
          type
          active
          grade
          flash
          targetDate
          beginningDate
          completionDate
        }
        commentsLength
        likesLength
        likeFilled
      }
      count
    }
  }
`;
