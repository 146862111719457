import { useState } from 'react';

import { Typography } from '@mui/material';

import { IPlannerDetails } from '../../../api/types/planner';
import { cardSmallSelectFontStyles } from '../../styles/card-small-select-font-styles';
import { useColours } from '../../utils/use-colours';
import { JoinsListModal } from './joins-list-modal';

export interface IJoinsProps {
  plan: IPlannerDetails;
  planJoined: boolean;
}

export const Joins: React.FC<IJoinsProps> = ({ plan, planJoined }) => {
  const colours = useColours();
  const [isJoinsModalOpen, setIsJoinsModalOpen] = useState(false);

  return (
    <>
      {isJoinsModalOpen && (
        <JoinsListModal plan={plan} onCloseModal={() => setIsJoinsModalOpen(false)} />
      )}
      <Typography sx={cardSmallSelectFontStyles(colours)} onClick={() => setIsJoinsModalOpen(true)}>
        {`${(plan.joinsLength || 0) + (planJoined ? 1 : 0)} joiners`}
      </Typography>
    </>
  );
};
