import { SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography } from '@mui/material';

import { SEND_MESSAGE_REQUEST } from '../../../../../api/mutation/sentMessageRequest';
import { MessageRequestFormSchema } from '../../../../../validation/schemas/get-in-touch-form';
import { MessageRequestFormInputs } from '../../../../../validation/schemas/get-in-touch-form/types';
import { ErrorAlert } from '../../../../components/error-alert';
import { SubmitButton } from '../../../../components/submit-button';
import { useClimbingCommunityCookies } from '../../../../utils/use-cookies';

interface IMessageRequestFormProps {
  id?: string;
}

export const MessageRequestForm: React.FC<IMessageRequestFormProps> = ({ id }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MessageRequestFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(MessageRequestFormSchema),
  });

  const { cookies } = useClimbingCommunityCookies();

  const [sendMessageRequest, { loading, error, data }] = useMutation(SEND_MESSAGE_REQUEST);

  const onSubmit: SubmitHandler<MessageRequestFormInputs> = (data) => {
    sendMessageRequest({
      variables: {
        id,
        requesterId: cookies.signedInUser,
        message: data.message?.trim(),
      },
    });
  };

  if (data?.sendMessageRequest) {
    return (
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Typography variant="subtitle1" component="div">
          Done!
        </Typography>
        <Typography variant="subtitle1" component="div">
          Your message has been sent.
        </Typography>
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Typography variant="subtitle1" component="div">
          Provide an email address so they can get in touch with you
        </Typography>
        <Typography variant="subtitle1" component="div">
          Send a quick message about you
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={3}
          error={Boolean(errors.message?.message)}
          helperText={errors.message?.message}
          id="message"
          label="Message"
          variant="outlined"
          {...register('message')}
        />
        <ErrorAlert showError={Boolean(error)} />
        <SubmitButton label="Send message!" loading={loading} type="submit" variant="contained" />
      </Stack>
    </form>
  );
};
