import { gql } from '@apollo/client';

export const COMMENT_PLAN = gql`
  mutation CommentPlan($id: String!, $userId: String!, $comment: String!) {
    commentPlan(id: $id, userId: $userId, comment: $comment) {
      id
      comment
      date
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
