import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material';

import { Friend } from '../../../../api/types/friend';
import { COMPONENT_WIDTHS } from '../../../../constants/component-dimentions';
import { ErrorAlert } from '../../../components/error-alert';
import { SubmitButton } from '../../../components/submit-button';
import { getNameFromUser } from '../../../utils/get-name-from-user';

interface IDeleteFriendConfirmationModalProps {
  loading: boolean;
  deleteFriend?: Friend;
  error?: string;
  handleClickConfirm: () => void;
  handleClickCancel: () => void;
}

export const DeleteFriendConfirmationModal: React.FC<IDeleteFriendConfirmationModalProps> = ({
  deleteFriend,
  loading,
  error,
  handleClickConfirm,
  handleClickCancel,
}) => {
  return (
    <Dialog open={Boolean(deleteFriend)}>
      <Box
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconButton sx={{ padding: 0 }} aria-label="close" onClick={handleClickCancel}>
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ mr: 1 }} variant="h6" component="h4">
            {'Are you sure?'}
          </Typography>
        </Box>
      </Box>
      <DialogContent sx={{ padding: 2, width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }} dividers>
        <ErrorAlert showError={Boolean(error)} />
        <Typography sx={{ mb: 2 }}>
          {`Are you sure you want to remove `}
          <Typography display={'inline'} sx={{ fontWeight: 'bold' }}>
            {getNameFromUser(deleteFriend?.friend)}
          </Typography>
          {` from your community?`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="contained"
            color="inherit"
            onClick={handleClickCancel}
            disabled={loading}
          >
            Cancel
          </Button>
          <SubmitButton
            fullWidth={false}
            onClick={handleClickConfirm}
            label={'Remove'}
            loading={loading}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
