import { gql } from '@apollo/client';

export const GET_SUGGESTED_FRIENDS = gql`
  query GetSuggestedFriends($userId: String!, $limit: Int!, $offset: Int!) {
    getSuggestedFriends(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        friend {
          id
          email
          firstName
          lastName
        }
      }
      count
    }
  }
`;
