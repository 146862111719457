import React, { useState } from 'react';

import { encrypt } from 'n-krypta';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { UPDATE_EMAIL } from '../../../api/mutation/updateEmail';
import { GET_USER } from '../../../api/query/getUser';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { UpdateEmailFormSchema } from '../../../validation/schemas/update-email';
import { UpdateEmailFormInputs } from '../../../validation/schemas/update-email/types';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { ToastNotification } from '../../components/toast-notification';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

export const UpdateEmail: React.FC = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateEmailFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(UpdateEmailFormSchema),
  });

  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();
  const [successToastDisplay, setSuccessToastDisplay] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const [updateEmail, { loading, error }] = useMutation(UPDATE_EMAIL);

  const handleUpdateEmailSubmit = (formData: UpdateEmailFormInputs) => {
    const currentEncryptedPassword = encrypt(formData.setEmailCurrentPassword, ENCRYPTION_SECRET);
    updateEmail({
      variables: {
        userId: cookies.signedInUser,
        currentPassword: currentEncryptedPassword,
        newEmail: formData.newEmail?.trim(),
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              email: result?.data?.updateEmail?.email,
            },
          }),
        );
      },
    }).then(() => {
      setSuccessToastDisplay(true);
      setValue('setEmailCurrentPassword', '');
      setValue('newEmail', '');
    });
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <ToastNotification
        isOpen={successToastDisplay}
        message={'New email address saved!'}
        onClose={() => setSuccessToastDisplay(false)}
      />
      <CardHeader
        avatar={<EmailIcon sx={{ color: colours.subtextColour }} />}
        title={<Typography sx={cardSubtitleFontStyles(colours)}>Update email address</Typography>}
      />
      <CardContent>
        <form onSubmit={handleSubmit(handleUpdateEmailSubmit)}>
          <TextField
            fullWidth
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.newEmail?.message)}
            helperText={errors.newEmail?.message}
            id="newEmail"
            label="New Email"
            variant="outlined"
            inputProps={{
              autoComplete: 'new-password',
            }}
            {...register('newEmail')}
          />
          <TextField
            fullWidth
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.setEmailCurrentPassword?.message)}
            helperText={errors.setEmailCurrentPassword?.message}
            id="setEmailCurrentPassword"
            label="Current Password"
            variant="outlined"
            type={passwordShown ? 'text' : 'password'}
            InputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon onClick={togglePasswordVisiblity} />
                </InputAdornment>
              ),
            }}
            {...register('setEmailCurrentPassword')}
          />
          <ErrorAlert
            showError={Boolean(error?.message)}
            message={
              error?.message.includes('Incorrect') || error?.message.includes('in use')
                ? error.message
                : undefined
            }
          />
          <SubmitButton label={'Update Email'} loading={loading} />
        </form>
      </CardContent>
    </Card>
  );
};
