import React, { useState } from 'react';

import { useNavigate } from 'react-router';
import { HashLink } from 'react-router-hash-link';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Groups';
import IosShareIcon from '@mui/icons-material/IosShare';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import { GET_CLUBS_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getClubsCommunityAchievements';
import { IAchievementDetails } from '../../../api/types/achievement';
import { LoadingSpinner } from '../../../component-library/components/loading-spinner';
import { RouterLink } from '../../../component-library/components/router-link';
import { SubmitButton } from '../../../component-library/components/submit-button';
import { AchievementPost } from '../../../component-library/page-layout/achievement-post';
import { AddNewAchievementModal } from '../../../component-library/page-layout/add-new-achievement-modal';
import { GoalAndAchievementMessageModal } from '../../../component-library/page-layout/goal-and-achievement-message-modal';
import { CommunityLogActivityModal } from '../../../component-library/page-layout/log-activity-modal/community-log-activity-modal';
import { useColours } from '../../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../../component-library/utils/use-cookies';
import { RoutePath } from '../../../constants';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';

export const ClubsCommunityFeed: React.FC = () => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });
  const navigate = useNavigate();

  const [goalAndAchievementMessageModalValues, setGoalAndAchievementMessageModalValues] = useState<{
    goalComplete: boolean;
    achievementSaved: boolean;
  }>({ goalComplete: false, achievementSaved: false });

  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [addNewAchievementOpen, setAddNewAchievementOpen] = useState(false);
  const [logNewAchievementOpen, setLogNewAchievementOpen] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_CLUBS_COMMUNITY_ACHIEVEMENTS, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading your clubs community feed...'}
      />
    );
  }

  return (
    <Box
      id="head-of-community-page"
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
        sx={{
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
          m: 'auto',
        }}
      >
        <GoalAndAchievementMessageModal
          values={goalAndAchievementMessageModalValues}
          setNewGoal={() => {
            setGoalAndAchievementMessageModalValues({
              goalComplete: false,
              achievementSaved: false,
            });
            navigate(RoutePath.GOALS);
          }}
          onClose={() =>
            setGoalAndAchievementMessageModalValues({
              goalComplete: false,
              achievementSaved: false,
            })
          }
        />
        <CommunityLogActivityModal
          isOpen={logNewAchievementOpen}
          onClose={() => setLogNewAchievementOpen(false)}
          onSuccess={(goalComplete: boolean) => {
            setLogNewAchievementOpen(false);
            if (goalComplete) {
              setGoalAndAchievementMessageModalValues({ goalComplete, achievementSaved: false });
            }
          }}
          refetchActivitiesVariables={{
            userId: cookies.signedInUser,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          }}
        />
        <AddNewAchievementModal
          isOpen={addNewAchievementOpen}
          onCloseModal={() => setAddNewAchievementOpen(false)}
          refetchCommunityActivitiesVariables={{
            userId: cookies.signedInUser,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          }}
        />
        <Box
          sx={{
            mb: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: cookies.signedInClub ? 'row-reverse' : 'row',
          }}
        >
          {!cookies.signedInClub && (
            <Box>
              <Button
                size="small"
                sx={{ ml: 1, minWidth: 0, color: colours.primaryInverseColour }}
                variant="contained"
                onClick={() => setLogNewAchievementOpen(true)}
              >
                <AddIcon />
              </Button>
              <Button
                size="small"
                sx={{ ml: 1, minWidth: 0, color: colours.primaryInverseColour }}
                variant="contained"
                onClick={() => setAddNewAchievementOpen(true)}
              >
                <IosShareIcon />
              </Button>
            </Box>
          )}
          <Box>
            {!cookies.signedInClub && (
              <Button
                size="small"
                sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
                variant="contained"
                endIcon={<GroupIcon />}
              >
                <RouterLink href={RoutePath.MANAGE_CLUBS}>See clubs</RouterLink>
              </Button>
            )}
            <Button
              size="small"
              sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
              variant="contained"
              color="primary"
              onClick={() =>
                refetch({
                  userId: cookies.signedInUser,
                  limit: paginationValues.limit,
                  offset: paginationValues.offset,
                })
              }
            >
              <ReplayIcon />
            </Button>
          </Box>
        </Box>
        {error && (
          <Box
            sx={{
              margin: 'auto',
              justifyContent: 'center',
              height: `calc(${window.innerHeight}px - 170px - 56px)`,
              width: '100%',
              p: 2,
              pb: 0,
            }}
          >
            <Alert sx={{ width: '100%' }} severity="error">
              Oops! Something went wrong!
            </Alert>
          </Box>
        )}
        {data?.getClubsCommunityAchievements?.data &&
          !data?.getClubsCommunityAchievements.data?.length && (
            <Box
              sx={{
                margin: 'auto',
                justifyContent: 'center',
                height: `calc(${window.innerHeight}px - 170px - 56px)`,
                width: '100%',
                p: 2,
                pb: 0,
              }}
            >
              <Alert sx={{ width: '100%' }} severity="info">
                {'Nobody in your clubs have posted any achievements!'}
              </Alert>
            </Box>
          )}
        {data?.getClubsCommunityAchievements?.data?.map((achievement: IAchievementDetails) => (
          <React.Fragment key={achievement.id}>
            {/* {Boolean(idx !== 0 && idx % 3 === 0) && <Adsense dataAdSlot="9072317848" />} */}
            <AchievementPost
              achievement={achievement}
              key={achievement.id}
              refetchAchievementsVariables={{
                userId: cookies.signedInUser,
                limit: paginationValues.limit,
                offset: paginationValues.offset,
              }}
            />
          </React.Fragment>
        ))}
        {Boolean(
          data?.getClubsCommunityAchievements?.data?.length > 0 &&
            data?.getClubsCommunityAchievements?.data?.length <
              data?.getClubsCommunityAchievements?.count,
        ) && (
          <SubmitButton
            sx={{ mb: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() => {
              fetchMore({
                variables: {
                  userId: cookies.signedInUser,
                  limit: paginationValues.limit,
                  offset: data?.getClubsCommunityAchievements?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getClubsCommunityAchievements: {
                      __typename: 'GetCommunityAchievementsResult',
                      data: [
                        ...previousResult.getClubsCommunityAchievements.data,
                        ...fetchMoreResult.getClubsCommunityAchievements.data,
                      ],
                      count: fetchMoreResult.getClubsCommunityAchievements.count,
                    },
                  };
                },
              });
            }}
          />
        )}
        {data?.getClubsCommunityAchievements?.data?.length > 5 && (
          <HashLink to="#head-of-community-page" smooth style={{ textDecoration: 'none' }}>
            <Typography sx={{ color: colours.primaryThemeColour, mb: 1 }}>Back to top</Typography>
          </HashLink>
        )}
      </Stack>
    </Box>
  );
};
