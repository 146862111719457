import { ClimbingType } from '../pages/my-progress/types';

export const getClimbingTypeFromType = (type: ClimbingType) => {
  if (type === ClimbingType.BOULDER) {
    return 'bouldering';
  }
  if (type === ClimbingType.LEAD) {
    return 'lead climbing';
  }
  if (type === ClimbingType.TOP_ROPE) {
    return 'top rope climbing';
  }
};
