import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import HttpsIcon from '@mui/icons-material/Https';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Grid, Stack, Typography } from '@mui/material';

import { GET_USER } from '../../api/query/getUser';
import { ErrorAlert } from '../../component-library/components/error-alert';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { Comment } from '../../component-library/page-layout/achievement-post/comments/comment';
import { DeleteAccountModal } from '../../component-library/page-layout/delete-account-modal';
import { ImageUploader } from '../../component-library/page-layout/image-uploader';
import { UpdateClubDescription } from '../../component-library/page-layout/update-club-description';
import { UpdateClubPrivacy } from '../../component-library/page-layout/update-club-privacy';
import { UpdateEmail } from '../../component-library/page-layout/update-email';
import { UpdatePassword } from '../../component-library/page-layout/update-password';
import { cardLargeTitleFontStyles } from '../../component-library/styles/card-large-title-font-styles';
import { getDateInFuture } from '../../component-library/utils/get-date-in-future';
import { getNameFromUser } from '../../component-library/utils/get-name-from-user';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { CookieNames, RoutePath } from '../../constants';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { AvailableLogin, SignedInUserType } from '../../constants/cookie-names';

export const MyClubPage: React.FC = () => {
  const navigate = useNavigate();
  const colours = useColours();
  const { removeCookie, setCookie, cookies } = useClimbingCommunityCookies();

  const [deleteAccountSelected, setDeleteAccountSelected] = useState(false);

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId: cookies.signedInUser },
  });

  const handleOnClick = () => {
    setCookie(
      CookieNames.AVAILABLE_LOGINS,
      [
        ...(cookies.availableLogins ?? []).filter(
          (f: AvailableLogin) => f.type !== SignedInUserType.CLUB,
        ),
      ],
      {
        expires: getDateInFuture({ daysInFuture: 60 }),
      },
    );
    removeCookie(CookieNames.SIGNED_IN_USER);
    removeCookie(CookieNames.ROPE_GRADE_PREFERENCE);
    removeCookie(CookieNames.BOULDERING_GRADE_PREFERENCE);
    removeCookie(CookieNames.SIGNED_IN_CLUB);
    navigate(RoutePath.CLUB_AUTHENTICATION);
  };

  const selectDeleteAccount = () => setDeleteAccountSelected(true);
  const hideDeleteAccountModal = () => setDeleteAccountSelected(false);

  return (
    <Stack direction="column" pt={2} justifyContent="center" alignItems="center">
      <DeleteAccountModal
        isClub={true}
        isOpen={deleteAccountSelected}
        onClose={hideDeleteAccountModal}
      />
      <Grid container spacing={3} sx={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <Grid item xs={12}>
          <ImageUploader />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ ...cardLargeTitleFontStyles, display: 'flex', alignItems: 'center' }}>
            {getNameFromUser(data?.getUser)}
            {data?.getUser.isClubPrivate && (
              <HttpsIcon
                fontSize="small"
                style={{ color: colours.subtextColour, marginLeft: '6px', height: 14 }}
              />
            )}
          </Typography>
        </Grid>
        {data?.getUser?.lastName && (
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <Comment comment={data.getUser.lastName} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate(`${RoutePath.PROFILE}${cookies.signedInUser}`)}
            endIcon={<PersonOutlineIcon />}
          >
            View Club Page
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleOnClick} endIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Grid>
        <Grid item xs={12}>
          {loading && <LoadingSpinner />}
          <ErrorAlert showError={Boolean(error?.message)} />
        </Grid>
        {data?.getUser && (
          <>
            <Grid item xs={12}>
              <UpdateClubDescription />
            </Grid>
            <Grid item xs={12}>
              <UpdateClubPrivacy isClubPrivate={data.getUser.isClubPrivate} />
            </Grid>
            <Grid item xs={12}>
              <UpdateEmail />
            </Grid>
            <Grid item xs={12}>
              <UpdatePassword />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{ mb: 2 }}
                onClick={selectDeleteAccount}
                endIcon={<DeleteIcon />}
              >
                Delete Club
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>
  );
};
