import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import HttpsIcon from '@mui/icons-material/Https';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import { JOIN_CLUB } from '../../../api/mutation/joinClub';
import { SEARCH_CLUBS } from '../../../api/query/searchClubs';
import { IUser } from '../../../api/types';
import { RoutePath } from '../../../constants';
import { SearchFriendFormSchema } from '../../../validation/schemas/search-friend-form';
import { SearchFriendFormInputs } from '../../../validation/schemas/search-friend-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { ProfilePicture } from '../../components/profile-picture';
import { RouterLink } from '../../components/router-link';
import { SubmitButton } from '../../components/submit-button';
import { ToastNotification } from '../../components/toast-notification';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { Comment } from '../achievement-post/comments/comment';

interface IJoinClubModal {}

export const ClubList: React.FC<IJoinClubModal> = () => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();
  const navigate = useNavigate();

  const [sentRequests, setSentRequests] = useState<string[]>([]);
  const [successToastDisplay, setSuccessToastDisplay] = useState(false);
  const [requestSuccessToastDisplay, setRequestSuccessToastDisplay] = useState(false);

  const [joinClub, { loading: sendLoading, error: sendError, reset }] = useMutation(JOIN_CLUB);

  const [getSearch, { loading, error, data }] = useLazyQuery(SEARCH_CLUBS, {
    variables: { userId: cookies.signedInUser, firstname: '' },
    fetchPolicy: 'network-only',
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SearchFriendFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(SearchFriendFormSchema),
  });

  // Reset form values on toggle
  useEffect(() => {
    setValue('firstname', '');
    getSearch({
      variables: {
        firstname: '',
        userId: cookies.signedInUser,
      },
    });
  }, [cookies.signedInUser, getSearch, setValue]);

  const handleAddFriend = (id: string, isClubPrivate: boolean) => {
    if (Boolean(sentRequests.find((f) => f === id))) {
      return;
    }
    joinClub({
      variables: { userId: cookies.signedInUser, clubId: id },
    }).then(() => {
      if (!isClubPrivate) {
        setSuccessToastDisplay(true);
      } else {
        setRequestSuccessToastDisplay(true);
      }
      setSentRequests([...sentRequests, id]);
    });
  };

  const onSubmit: SubmitHandler<SearchFriendFormInputs> = (data) => {
    getSearch({
      variables: {
        firstname: data.firstname ?? '',
        userId: cookies.signedInUser,
      },
    }).then(() => {
      reset();
    });
  };

  const handleNavigateToFriend = (id: string) => {
    navigate(`${RoutePath.PROFILE}${id}`);
  };

  return (
    <Box sx={{ p: 2 }}>
      <ToastNotification
        isOpen={successToastDisplay}
        message={'Joined club!'}
        onClose={() => setSuccessToastDisplay(false)}
      />
      <ToastNotification
        isOpen={requestSuccessToastDisplay}
        message={'Request to join club sent!'}
        onClose={() => setRequestSuccessToastDisplay(false)}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <TextField
          fullWidth
          sx={{ mb: 1, backgroundColor: colours.primaryInverseColour }}
          error={Boolean(errors.firstname?.message)}
          helperText={errors.firstname?.message}
          id="firstname"
          label="Club name"
          variant="outlined"
          {...register('firstname')}
        />
        <SubmitButton sx={{ mb: 2 }} loading={loading} label={'Search'} />
      </form>
      {Boolean(data?.searchClubs?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          No clubs could be found
        </Alert>
      )}
      <ErrorAlert
        showError={Boolean(error?.message || sendError?.message)}
        message={sendError?.message}
      />
      {data?.searchClubs?.map((user: IUser) => {
        const requestSent = Boolean(sentRequests.find((f) => f === user.id));
        return (
          <React.Fragment key={user.id}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                  <ProfilePicture userId={user.id} navigable />
                </Box>
                <Box sx={{ overflow: 'hidden' }} onClick={() => handleNavigateToFriend(user.id)}>
                  <RouterLink href={`${RoutePath.PROFILE}${user.id}`}>
                    <Typography sx={{ ...cardTitleFontStyles, display: 'flex' }}>
                      {getNameFromUser(user)}
                      {user.isClubPrivate && (
                        <HttpsIcon
                          fontSize="small"
                          style={{ color: colours.subtextColour, marginLeft: '6px', height: 14 }}
                        />
                      )}
                    </Typography>
                  </RouterLink>
                  <RouterLink href={`${RoutePath.PROFILE}${user.id}`}>
                    <Comment comment={user.lastName} />
                  </RouterLink>
                </Box>
              </Box>
              <IconButton
                disabled={sendLoading || requestSent}
                onClick={() => handleAddFriend(user.id, user.isClubPrivate)}
              >
                {requestSent ? (
                  <DoneIcon />
                ) : sendLoading ? (
                  <CircularProgress size={20} thickness={3} />
                ) : (
                  <PersonAddAltIcon />
                )}
              </IconButton>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}
    </Box>
  );
};
