import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { IBuddySearch } from '../../../../../api/types';
import { RoutePath } from '../../../../../constants';
import { RouterLink } from '../../../../components/router-link';
import { useColours } from '../../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../../utils/use-cookies';
import { MessageRequestForm } from '../message-request-form';

interface IListingContentProps {
  setViewAllListingsFromUser: () => void;
  item?: IBuddySearch;
}

export const ListingContent: React.FC<IListingContentProps> = ({
  item,
  setViewAllListingsFromUser,
}) => {
  const colours = useColours();
  const [getInTouch, setMessageRequest] = useState(false);

  const { cookies } = useClimbingCommunityCookies();

  if (getInTouch) {
    return (
      <Grid item xs={12}>
        <MessageRequestForm id={item?.id} />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Button
          endIcon={<SearchIcon />}
          variant="contained"
          sx={{ color: colours.primaryInverseColour }}
          fullWidth
          onClick={setViewAllListingsFromUser}
        >
          {`${item?.user.firstName}'s other listings`}
        </Button>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" component="div">
          {item?.ability}
        </Typography>

        <Typography variant="body1" component="div">
          {item?.additionalInformation}
        </Typography>

        <Typography mt={3} variant="subtitle1" component="div">
          I am looking for a climbing buddy at:
        </Typography>
        <Typography sx={{ textTransform: 'capitalize' }} variant="h6" component="div">
          {item?.gym}
        </Typography>

        <Typography mt={3} variant="subtitle1" component="div">
          I am looking to:
        </Typography>
        <Stack
          spacing={1}
          flexDirection="column"
          justifyContent={'space-between'}
          alignContent="flex-start"
        >
          <Chip
            color={item?.bouldering ? 'primary' : 'default'}
            label="Boulder"
            onDelete={() => null}
            deleteIcon={item?.bouldering ? <DoneIcon /> : <CloseIcon />}
          />
          <Chip
            color={item?.topRope ? 'primary' : 'default'}
            label="Top rope"
            onDelete={() => null}
            deleteIcon={item?.topRope ? <DoneIcon /> : <CloseIcon />}
          />
          <Chip
            color={item?.lead ? 'primary' : 'default'}
            label="Lead climb"
            onDelete={() => null}
            deleteIcon={item?.lead ? <DoneIcon /> : <CloseIcon />}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1" component="div">
          My availability is:
        </Typography>
        <Box>
          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Monday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.monAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.monPm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Tuesday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.tueAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.tuePm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Wednesday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.wedAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.wedPm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Thursday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.thuAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.thuPm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Friday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.friAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.friPm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Saturday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.satAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.satPm} />} label="pm" />
            </Box>
          </Stack>

          <Stack flexDirection="row" justifyContent={'space-between'} alignItems="center">
            <Typography>{'Sunday'}</Typography>
            <Box sx={{ display: 'flex', maginTop: '0px' }}>
              <FormControlLabel control={<Checkbox checked={item?.sunAm} />} label="am" />
              <FormControlLabel control={<Checkbox checked={item?.sunPm} />} label="pm" />
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {cookies.signedInUser ? (
          <Button
            variant="contained"
            onClick={() => setMessageRequest(true)}
            sx={{ height: 36.5, color: colours.primaryInverseColour }}
            fullWidth
          >
            Get in touch!
          </Button>
        ) : (
          <RouterLink href={cookies.signedInUser ? RoutePath.MY_ACCOUNT : RoutePath.AUTHENTICATION}>
            <Button
              variant="contained"
              sx={{ height: 36.5, color: colours.primaryInverseColour }}
              fullWidth
            >
              Sign in to connect!
            </Button>
          </RouterLink>
        )}
      </Grid>
    </>
  );
};
