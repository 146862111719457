import { gql } from '@apollo/client';

export const ACCEPT_MESSAGE_REQUEST = gql`
  mutation AcceptMessageRequest($id: String!) {
    acceptMessageRequest(id: $id) {
      id
      lastMessage
      user1 {
        id
        email
        firstName
        lastName
      }
      user2 {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
