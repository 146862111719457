import React from 'react';

import { MyCommunityTab } from '../../../pages/manage-community/types';
import { ManageCommunityFriends } from '../manage-community-friends';
import { ManageCommunityRequests } from '../manage-community-requests';
import { SuggestedFriends } from '../suggested-friends';

interface IManageCommunityContent {
  tab: MyCommunityTab;
}

export const ManageCommunitycontent: React.FC<IManageCommunityContent> = ({ tab }) => {
  if (tab === MyCommunityTab.community) {
    return <ManageCommunityFriends />;
  }
  if (tab === MyCommunityTab.suggested) {
    return <SuggestedFriends />;
  }

  return <ManageCommunityRequests />;
};
