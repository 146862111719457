import { useState } from 'react';

import { useMutation } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, ListItem, ListItemButton, Popover } from '@mui/material';

import { DELETE_COMMENT } from '../../../../api/mutation/deleteComment';
import { GET_ACHIEVEMENT_COMMENTS } from '../../../../api/query/getAchievementComments';
import { GET_ACHIEVEMENTS } from '../../../../api/query/getAchievements';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../../api/query/getCommunityAchievements';
import { IAchievementDetails } from '../../../../api/types/achievement';
import { IComment } from '../../../../api/types/comment';
import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { ReportCommentModal } from '../../report-comment-modal';

interface ICommentOptionsPopoverProps {
  comment: IComment;
  getAchievementCommentsVariables: {
    id: string | undefined;
    userId: string;
    limit: number;
    offset: number;
  };
  getAchievementVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
  profileView?: boolean;
  achievementId: string;
}
export const CommentOptionsPopover: React.FC<ICommentOptionsPopoverProps> = ({
  comment,
  getAchievementCommentsVariables,
  getAchievementVariables,
  profileView,
  achievementId,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [isReportCommentModalOpen, setIsReportCommentModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const getQueryDoc = (profileView?: boolean) => {
    if (profileView) {
      return GET_ACHIEVEMENTS;
    }
    return GET_COMMUNITY_ACHIEVEMENTS;
  };

  const getResultData = (data: any, profileView?: boolean) => {
    const mapper = (item: IAchievementDetails) => {
      if (item.id === achievementId) {
        return {
          ...item,
          commentsLength: (item?.commentsLength || 1) - 1,
        };
      }
      return item;
    };

    if (profileView) {
      return {
        getAchievements: {
          data: [...(data?.getAchievements?.data || []).map(mapper)],
          count: data?.getAchievements?.count,
        },
      };
    }

    return {
      getCommunityAchievements: {
        data: [...(data?.getCommunityAchievements?.data || []).map(mapper)],
        count: data?.getCommunityAchievements?.count,
      },
    };
  };

  const handleOnClickDelete = () => {
    deleteComment({
      variables: { id: comment.id, userId: cookies.signedInUser },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_ACHIEVEMENT_COMMENTS,
            variables: getAchievementCommentsVariables,
          },
          (data) => ({
            getAchievementComments: {
              data: [
                ...(data?.getAchievementComments?.data || []).filter(
                  (c: IComment) => c.id !== comment.id,
                ),
              ],
              count: data?.getAchievementComments?.count - 1,
            },
          }),
        );
        cache.updateQuery(
          {
            query: getQueryDoc(profileView),
            variables: getAchievementVariables,
          },
          (data) => getResultData(data, profileView),
        );
      },
    }).then(() => {
      handleClosePopover();
    });
  };

  const [deleteComment] = useMutation(DELETE_COMMENT);

  const popoverId = Boolean(anchorEl) ? `comment-options-popover-${comment.id}` : undefined;

  return (
    <>
      <ReportCommentModal
        user={comment.user}
        commentId={comment.id}
        isOpen={isReportCommentModalOpen}
        getAchievementCommentsVariables={getAchievementCommentsVariables}
        getCommunityAchievementsVariables={getAchievementVariables}
        onClose={() => setIsReportCommentModalOpen(!isReportCommentModalOpen)}
      />
      <IconButton onClick={handleClickPopover} sx={{ height: 15, p: 0 }}>
        <MoreVertIcon height={14} width={14} sx={selectIconStyles(colours)} />
      </IconButton>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {comment?.user?.id === cookies.signedInUser && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickDelete}>Delete</ListItemButton>
            </ListItem>
          )}
          {comment?.user?.id !== cookies.signedInUser && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => setIsReportCommentModalOpen(true)}>
                Report comment
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
