import React, { useMemo, useState } from 'react';

import { Box, Card, Stack, Tab, Tabs } from '@mui/material';

import { ClubList } from '../../component-library/page-layout/clubs-list';
import { ManageClubs } from '../../component-library/page-layout/manage-clubs';
import { useColours } from '../../component-library/utils/use-colours';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ClubsFeedType } from './types';

export const ManageClubsPage: React.FC = () => {
  const colours = useColours();

  const [tab, setTab] = useState<ClubsFeedType>(ClubsFeedType.MY_CLUBS);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: ClubsFeedType) =>
    setTab(value);

  const PageContentByTab: JSX.Element = useMemo(() => {
    switch (tab) {
      case ClubsFeedType.MY_CLUBS:
        return <ManageClubs />;
      case ClubsFeedType.CLUBS:
        return <ClubList />;
    }
  }, [tab]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box p={2} style={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <Card sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Card
            sx={{
              width: 450,
              maxWidth: '100%',
              backgroundColor: colours.elevatedComponentColour,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Club tabs"
                variant="fullWidth"
              >
                <Tab value={ClubsFeedType.MY_CLUBS} label="My Clubs" />
                <Tab value={ClubsFeedType.CLUBS} label="Find clubs" />
              </Tabs>
            </Box>
            {PageContentByTab}
          </Card>
        </Card>
      </Box>
    </Stack>
  );
};
