import React from 'react';

import { AlertProps, Alert as MuiAlert, Snackbar } from '@mui/material';

import { useColours } from '../../utils/use-colours';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface IToastNotificationProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ToastNotification: React.FC<IToastNotificationProps> = ({
  message,
  isOpen,
  onClose,
}) => {
  const colours = useColours();
  const handleCloseSuccessToast = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
      <Alert
        onClose={handleCloseSuccessToast}
        sx={{ width: '100%', backgroundColor: colours.primaryThemeColour }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
