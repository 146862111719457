import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover,
  Switch,
} from '@mui/material';

import { IFilterState } from './types';

interface IMyProgressFilterPopover {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  filterState: IFilterState;
  handleChangeFilterState: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MyProgressFilterPopover: React.FC<IMyProgressFilterPopover> = ({
  id,
  open,
  anchorEl,
  onClose,
  filterState,
  handleChangeFilterState,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <FormControl component="fieldset" variant="standard" sx={{ p: 2, mt: 2 }}>
        <FormLabel component="legend">Filters</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={filterState.completedOnly}
                onChange={handleChangeFilterState}
                name="completedOnly"
              />
            }
            label="Completed only"
          />
          <FormControlLabel
            control={
              <Switch
                checked={filterState.flashedOnly}
                onChange={handleChangeFilterState}
                name="flashedOnly"
              />
            }
            label="Flashed only"
          />
          <FormControlLabel
            control={
              <Switch
                checked={filterState.fivePlusOnly}
                onChange={handleChangeFilterState}
                name="fivePlusOnly"
              />
            }
            label="5+ attempts only"
          />
          <FormControlLabel
            control={
              <Switch
                checked={filterState.twoToFourOnly}
                onChange={handleChangeFilterState}
                name="twoToFourOnly"
              />
            }
            label="2 - 4 attempts only"
          />
        </FormGroup>
      </FormControl>
    </Popover>
  );
};
