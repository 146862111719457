import * as Yup from 'yup';

import { yupConfirmNewPasswordValidation, yupPasswordValidation } from '../../yup';
import { UpdatePasswordFormInputs } from './types';

export const UpdatePasswordFormSchema: Yup.SchemaOf<UpdatePasswordFormInputs> = Yup.object({
  currentPassword: yupPasswordValidation,
  newPassword: yupPasswordValidation,
  confirmPassword: yupConfirmNewPasswordValidation,
});
