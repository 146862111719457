import React, { useState } from 'react';

import { encrypt } from 'n-krypta';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { DELETE_ACCOUNT } from '../../../api/mutation/deleteAccount';
import { CookieNames } from '../../../constants';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { DeleteAccountFormSchema } from '../../../validation/schemas/delete-account-form';
import { DeleteAccountInputs } from '../../../validation/schemas/delete-account-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { ModalHeader } from '../../components/modal-header';
import { SubmitButton } from '../../components/submit-button';
import { cardBodyFontStyles } from '../../styles/card-font-styles';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IDeleteAccountModalProps {
  isOpen: boolean;
  isClub?: boolean;
  onClose: () => void;
}

export const DeleteAccountModal: React.FC<IDeleteAccountModalProps> = ({
  isOpen,
  isClub,
  onClose,
}) => {
  const { cookies, removeCookie } = useClimbingCommunityCookies();
  const colours = useColours();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DeleteAccountInputs>({
    mode: 'onChange',
    resolver: yupResolver(DeleteAccountFormSchema),
  });

  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  const [deleteAccount, { loading, error }] = useMutation(DELETE_ACCOUNT);

  const handleOnClose = () => {
    if (!loading) {
      setConfirmDeleteAccount(false);
      onClose();
    }
  };

  const handleFinalConfirmation = (formData: DeleteAccountInputs) => {
    const encryptedPassword = encrypt(formData.confirmPassword?.trim(), ENCRYPTION_SECRET);
    deleteAccount({
      variables: { userId: cookies.signedInUser, password: encryptedPassword },
    }).then(() => {
      removeCookie(CookieNames.SIGNED_IN_USER);
      removeCookie(CookieNames.ROPE_GRADE_PREFERENCE);
      removeCookie(CookieNames.BOULDERING_GRADE_PREFERENCE);
      handleOnClose();
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <ModalHeader onClose={handleOnClose} title={'Delete account'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        {confirmDeleteAccount ? (
          <Box>
            <form onSubmit={handleSubmit(handleFinalConfirmation)}>
              <Typography sx={cardTitleFontStyles}>Last chance...</Typography>
              <Typography sx={cardBodyFontStyles(colours)}>
                If you're absolutely sure this is what you want.
              </Typography>
              <TextField
                fullWidth
                type="password"
                sx={{ mt: 2, mb: 2, backgroundColor: colours.primaryInverseColour }}
                error={Boolean(errors.confirmPassword?.message)}
                helperText={errors.confirmPassword?.message}
                id="confirmPassword"
                label="Password"
                variant="outlined"
                {...register('confirmPassword')}
              />
              <ErrorAlert showError={Boolean(error?.message)} />
              <Box>
                <Button
                  disabled={loading}
                  variant="contained"
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={handleOnClose}
                >
                  Cancel
                </Button>
                <SubmitButton
                  label={`Confirm Delete ${isClub ? 'Club' : 'Account'}`}
                  variant="outlined"
                  fullWidth
                  loading={loading}
                />
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Typography sx={cardTitleFontStyles}>
              {`Are you sure you want to delete your ${isClub ? 'club' : 'account'}?`}
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>This action cannot be undone.</Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              {` You will lose all the information on your ${isClub ? 'club' : 'account'}?`}
            </Typography>
            <Typography sx={{ ...cardBodyFontStyles(colours), mt: 1 }}>This includes:</Typography>
            {!isClub && (
              <>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>
                  All of your climbing progress
                </Typography>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>All of your goals</Typography>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>All of your posts</Typography>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>
                  All of your comments
                </Typography>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>All of your likes</Typography>
              </>
            )}
            {isClub && (
              <>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>
                  All of your followers
                </Typography>
                <Typography sx={cardSmallSubtitleFontStyles(colours)}>All of your plans</Typography>
              </>
            )}
            <Box>
              <Button variant="contained" fullWidth sx={{ mb: 2, mt: 2 }} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button variant="outlined" fullWidth onClick={() => setConfirmDeleteAccount(true)}>
                {`Delete ${isClub ? 'Club' : 'Account'}`}
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
