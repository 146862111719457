import { gql } from '@apollo/client';

export const GET_ACHIEVEMENT = gql`
  query GetAchievement($id: String!, $userId: String!) {
    getAchievement(id: $id, userId: $userId) {
      id
      date
      comment
      reported
      user {
        id
        email
        firstName
        lastName
        isClub
      }
      activity {
        id
        attempts
        comment
        completed
        grade
        intensity
        type
      }
      goal {
        id
        type
        active
        grade
        flash
        targetDate
        beginningDate
        completionDate
      }
      commentsLength
      likesLength
      likeFilled
    }
  }
`;
