import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material';

import { IProfile } from '../../../api/types/profile';
import { RoutePath } from '../../../constants';
import { ClimbingType } from '../../../pages/my-progress/types';
import { getClimbingTypeFromType } from '../../../utils/get-climbing-type-from-type';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { GoalsDetails } from '../goals-modal/goals-details';

interface IProfileGoalOverviewProps {
  profile?: IProfile;
}

export const ProfileGoalOverview: React.FC<IProfileGoalOverviewProps> = ({ profile }) => {
  const colours = useColours();
  const navigate = useNavigate();
  const { cookies } = useClimbingCommunityCookies();

  const [tab, setTab] = useState(ClimbingType.BOULDER);
  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: ClimbingType) => {
    setTab(value);
  };

  const GoalDetails = () => {
    const goal = profile?.goals.find((goal) => goal.type === tab);
    if (goal) {
      return <GoalsDetails goalDetails={goal} />;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
          profile?.user,
        )} has no active goal for ${getClimbingTypeFromType(tab)}`}</Typography>
        {profile?.user.id === cookies.signedInUser && (
          <Button variant="text" onClick={() => navigate(RoutePath.GOALS)}>
            Set goal
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Card sx={{ m: 2, mt: 0, backgroundColor: colours.elevatedComponentColour }}>
      <Box
        sx={{
          width: '100%',
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="Climbing type tabs"
          variant="fullWidth"
        >
          <Tab sx={{ p: 1 }} value={ClimbingType.BOULDER} label="Boulder" />
          <Tab sx={{ p: 1 }} value={ClimbingType.TOP_ROPE} label="Top Rope" />
          <Tab sx={{ p: 1 }} value={ClimbingType.LEAD} label="Lead" />
        </Tabs>
      </Box>
      {GoalDetails()}
    </Card>
  );
};
