import { useEffect, useState } from 'react';

import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useMutation } from '@apollo/client';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Box, Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material';

import { LIKE_ACHIEVEMENT } from '../../../../api/mutation/likeAchievement';
import { IAchievementDetails } from '../../../../api/types/achievement';
import { RoutePath } from '../../../../constants';
import { achievementPictureUrl } from '../../../../constants/achievement-picture-url';
import { IconSubmitButton } from '../../../components/icon-submit-button';
import { ProfilePicture } from '../../../components/profile-picture';
import { RouterLink } from '../../../components/router-link';
import { cardSelectFontStyles } from '../../../styles/card-select-font-styles';
import { cardSmallSelectFontStyles } from '../../../styles/card-small-select-font-styles';
import { cardSubtitleFontStyles } from '../../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { useIsMobile } from '../../../utils/use-is-mobile';
import { GoalsDetails } from '../../goals-modal/goals-details';
import { Likes } from '../../likes';
import { SocialShare } from '../../social-share';
import { Comments } from '../comments';
import { Comment } from '../comments/comment';
import { CreatorAchievementOptionsPopover } from '../creator-achievement-popover-options';
import { ViewerAchievementOptionsPopover } from '../viewer-achievement-popover-options';

interface IGoalProps {
  achievement: IAchievementDetails;
  profileView?: boolean;
  refetchAchievementsVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
}

export const Goal: React.FC<IGoalProps> = ({
  achievement,
  profileView,
  refetchAchievementsVariables,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const [commentsOpen, setCommentsOpen] = useState(false);
  const colours = useColours();

  const [mainImage, setMainImage] = useState<string | undefined>();
  const [achievementLiked, setAchievementLiked] = useState(false);
  const [fullScreenImageView, setFullScreenImageView] = useState<boolean>(false);

  useEffect(() => {
    if (achievement.id) {
      const storage = getStorage();
      const pathReference = ref(storage, achievementPictureUrl(achievement.id));

      if (pathReference) {
        getDownloadURL(pathReference)
          .then((url) => {
            setMainImage(url);
          })
          .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          });
      }
    }
  }, [achievement.id]);

  const [likeAchievement, { loading: likeLoading }] = useMutation(LIKE_ACHIEVEMENT);

  const likeButtonFilled = achievementLiked || achievement.likeFilled;

  const handleClickLike = (id: string) => {
    if (likeButtonFilled) {
      return;
    }

    likeAchievement({
      variables: {
        id,
        userId: cookies.signedInUser,
      },
    }).then(() => setAchievementLiked(true));
  };

  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '0.8rem',
        borderRadius: isMobile ? 0 : '0.8rem',
        boxShadow: '0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%)',
        backgroundColor: colours.elevatedComponentColour,
      }}
    >
      {Boolean(fullScreenImageView) && (
        <Dialog
          open={true}
          onClose={() => setFullScreenImageView(false)}
          sx={{ height: '100%', width: '100%' }}
        >
          <IconButton
            onClick={() => setFullScreenImageView(false)}
            sx={{
              backgroundColor: colours.pageBackgroundColour,
              position: 'fixed',
              right: 15,
              top: 15,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              height: '100%',
              width: '100%',
              minWidth: 300,
              minHeight: 500,
              backgroundImage: `url(${mainImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: 1,
            }}
          >
            <Box />
          </DialogContent>
        </Dialog>
      )}
      <Box p={1} pb={0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ height: 50, width: 50, mr: 2 }}>
              <ProfilePicture userId={achievement.user.id} navigable />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <RouterLink href={`${RoutePath.PROFILE}${achievement.user.id}`}>
                <Typography sx={cardTitleFontStyles}>
                  {getNameFromUser(achievement.user)}
                </Typography>
              </RouterLink>
              <Typography sx={cardSubtitleFontStyles(colours)}>Goal complete!</Typography>
              <Typography sx={cardSubtitleFontStyles(colours)}>
                {getDateSinceNowDisplayText(achievement.date)}
              </Typography>
            </Box>
          </Box>
          {Boolean(achievement.user.id === cookies.signedInUser) ? (
            <CreatorAchievementOptionsPopover
              mainImage={mainImage}
              profileView={profileView}
              setMainImage={(url) => setMainImage(url)}
              achievement={achievement}
              refetchAchievementsVariables={refetchAchievementsVariables}
            />
          ) : (
            <ViewerAchievementOptionsPopover
              achievement={achievement}
              refetchAchievementsVariables={refetchAchievementsVariables}
            />
          )}
        </Box>
        {Boolean(achievement?.comment) && (
          <Box m={2} sx={{ textAlign: 'left' }}>
            <Comment comment={achievement.comment} />
          </Box>
        )}
        <Box sx={{ ml: -1, mr: -1, backgroundColor: colours.pageBackgroundColour }}>
          {Boolean(mainImage) ? (
            <Carousel
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
              swipeable={true}
              showArrows={!isMobile}
              showIndicators={!isMobile}
              centerMode={true}
              centerSlidePercentage={90}
              showStatus={false}
              showThumbs={false}
              autoPlay={false}
            >
              <Box sx={{ p: 2, pt: 4, pb: 4, pointerEvents: 'none' }}>
                <GoalsDetails goalDetails={achievement.goal} showTitle />
              </Box>
              <Box
                onClick={() => setFullScreenImageView(true)}
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundImage: `url(${mainImage})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </Carousel>
          ) : (
            <Box sx={{ p: 2, pt: 4, pb: 4, pointerEvents: 'none' }}>
              <GoalsDetails goalDetails={achievement.goal} showTitle />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ p: 1, pl: 2, pr: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Likes achievement={achievement} achievementLiked={achievementLiked} />
        <Typography
          sx={cardSmallSelectFontStyles(colours)}
          onClick={() => setCommentsOpen(!commentsOpen)}
        >{`${achievement.commentsLength || 0} comments`}</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          textAlign: 'center',
          borderBottomLeftRadius: '0.8rem',
          borderBottomRightRadius: '0.8rem',
          p: 1,
        }}
      >
        <IconSubmitButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: '0.8em',
          }}
          inheritColour
          onClick={() => handleClickLike(achievement.id)}
          icon={
            likeButtonFilled ? (
              <ThumbUpAltIcon sx={{ height: 24, width: 24, mr: 1 }} />
            ) : (
              <ThumbUpOffAltIcon sx={{ height: 24, width: 24, mr: 1 }} />
            )
          }
          loading={likeLoading}
          label={likeButtonFilled ? 'Liked' : 'Like'}
          labelSx={cardSelectFontStyles(colours)}
        />
        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 0,
            borderRadius: '0.8em',
          }}
          onClick={() => setCommentsOpen(!commentsOpen)}
        >
          <ChatIcon sx={{ height: 24, width: 24, mr: 1 }} />
          <Typography sx={cardSelectFontStyles(colours)}>Comment</Typography>
        </IconButton>
        <SocialShare achievement={achievement} />
      </Box>
      {Boolean(commentsOpen) && (
        <Comments
          achievement={achievement}
          profileView={profileView}
          getAchievementVariables={refetchAchievementsVariables}
        />
      )}
    </Box>
  );
};
