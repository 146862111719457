import { useCookies } from 'react-cookie';

import { CookieNames } from '../../../constants';

export const useClimbingCommunityCookies = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.SIGNED_IN_USER,
    CookieNames.BOULDERING_GRADE_PREFERENCE,
    CookieNames.ROPE_GRADE_PREFERENCE,
    CookieNames.AVAILABLE_LOGINS,
    CookieNames.SIGNED_IN_CLUB,
  ]);

  return { cookies, setCookie, removeCookie };
};
