import React, { useCallback, useEffect, useState } from 'react';

import Compressor from 'compressorjs';
import Cropper, { Area } from 'react-easy-crop';

import { Box, Button, Input, InputLabel } from '@mui/material';

import { uploadProfileFirebaseImage } from '../../../utils/upload-profile-firebase-image';
import { ProfilePicture } from '../../components/profile-picture';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import getCroppedImg from './getCroppedImage';

interface IImageUploader {}

export const ImageUploader: React.FC<IImageUploader> = () => {
  const { cookies } = useClimbingCommunityCookies();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [newImageDimensions, setNewImageDimensions] = useState<{
    croppedArea: Area;
    croppedAreaPixels: Area;
  }>();
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [profilePicUrl, setProfilePicUrl] = useState<string | undefined>(undefined);

  const handleClose = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setNewImageDimensions(undefined);
    setPreview(undefined);
    setMainImageToUpload(undefined);
    setMainImageUrl(undefined);
    setThumbnailImageToUpload(undefined);
  };

  const handleChange = (e: any) => {
    if (e.target.files[0]) {
      updatePreview(e.target.files[0]);
    }
  };

  const [mainImageToUpload, setMainImageToUpload] = useState<Blob | undefined>(undefined);
  const [mainImageUrl, setMainImageUrl] = useState<string | undefined>(undefined);
  const [thumbnailImageToUpload, setThumbnailImageToUpload] = useState<Blob | undefined>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpload = (uploadFile: Blob, url: string) => {
    setMainImageUrl(url);
    new Compressor(uploadFile, {
      quality: 1,
      maxWidth: 400,
      maxHeight: 400,
      success: (compressedResult) => setMainImageToUpload(compressedResult),
    });
    new Compressor(uploadFile, {
      quality: 1,
      maxWidth: 75,
      maxHeight: 75,
      success: (compressedResult) => setThumbnailImageToUpload(compressedResult),
    });
  };

  useEffect(() => {
    if (thumbnailImageToUpload && mainImageToUpload) {
      uploadProfileFirebaseImage({
        signedInUser: cookies.signedInUser,
        mainImage: mainImageToUpload,
        thumbnailImage: thumbnailImageToUpload,
      });
      handleClose();
      setProfilePicUrl(mainImageUrl);
    }
  }, [cookies.signedInUser, mainImageToUpload, mainImageUrl, thumbnailImageToUpload]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setNewImageDimensions({ croppedArea, croppedAreaPixels });
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      if (!preview || !newImageDimensions) {
        return;
      }
      const croppedImage = await getCroppedImg(preview, newImageDimensions.croppedAreaPixels, 0);
      if (croppedImage) {
        handleUpload(croppedImage?.file, croppedImage.url);
      }
    } catch (e) {
      console.error(e);
    }
  }, [handleUpload, newImageDimensions, preview]);

  const updatePreview = (file: File) => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <InputLabel htmlFor="image-selection">
          <Box sx={{ height: 200, width: 200 }}>
            <ProfilePicture userId={cookies.signedInUser} profilePicUrl={profilePicUrl} />
          </Box>
        </InputLabel>
        <Input
          id="image-selection"
          type="file"
          onChange={handleChange}
          fullWidth
          sx={{ display: 'none' }}
        />
      </Box>
      {Boolean(preview) && (
        <Box
          sx={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            zIndex: 2,
          }}
        >
          <Cropper
            image={preview}
            crop={crop}
            zoom={zoom}
            cropShape="round"
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <Button
            onClick={showCroppedImage}
            sx={{ marginLeft: 3, marginTop: 3 }}
            variant="contained"
          >
            Save Profile Picture
          </Button>
          <Button onClick={handleClose} sx={{ marginLeft: 3, marginTop: 3 }} variant="contained">
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
