import { gql } from '@apollo/client';

export const UPDATE_PERSONAL_DETAILS = gql`
  mutation UpdatePersonalDetails($userId: String!, $firstName: String!, $lastName: String!) {
    updatePersonalDetails(userId: $userId, firstName: $firstName, lastName: $lastName) {
      id
      email
      firstName
      lastName
    }
  }
`;
