import React from 'react';

import { IAchievementDetails } from '../../../api/types/achievement';
import { Activity } from './activity';
import { Goal } from './goal';

interface IAchievementPostProps {
  refetchAchievementsVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  achievement: IAchievementDetails;
  profileView?: boolean;
}

export const AchievementPost: React.FC<IAchievementPostProps> = ({
  refetchAchievementsVariables,
  achievement,
  profileView,
}) => {
  if (achievement.activity) {
    return (
      <Activity
        profileView={profileView}
        achievement={achievement}
        refetchAchievementsVariables={refetchAchievementsVariables}
      />
    );
  }

  if (achievement.goal) {
    return (
      <Goal
        profileView={profileView}
        achievement={achievement}
        refetchAchievementsVariables={refetchAchievementsVariables}
      />
    );
  }

  return null;
};
