import React, { useState } from 'react';

import { encrypt } from 'n-krypta';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { InputAdornment, Stack, TextField } from '@mui/material';

import { USER_LOGIN } from '../../../api/mutation/userLogin';
import { CookieNames, RoutePath } from '../../../constants';
import { SignedInUserType } from '../../../constants/cookie-names';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { LoginFormInputs, LoginFormSchema } from '../../../validation/schemas';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { getDateInFuture } from '../../utils/get-date-in-future';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

/**
 * The form for the login journey.
 * @returns Login page form.
 */
interface ILoginContentProps {
  isClub?: boolean;
}

export const LoginContent: React.FC<ILoginContentProps> = ({ isClub }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(LoginFormSchema),
  });

  const [userLogin, { loading, error }] = useMutation(USER_LOGIN);
  const navigate = useNavigate();
  const { setCookie, removeCookie, cookies } = useClimbingCommunityCookies();

  // Handler for pressing the submit button
  const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
    const currentEncryptedPassword = encrypt(data.password, ENCRYPTION_SECRET);
    //API call to login
    userLogin({
      variables: {
        email: data.email?.trim(),
        password: currentEncryptedPassword,
        isClub,
      },
    }).then((result) => {
      setCookie(CookieNames.SIGNED_IN_USER, result.data.userLogin.id, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(
        CookieNames.BOULDERING_GRADE_PREFERENCE,
        result.data.userLogin.boulderingGradeType,
        {
          expires: getDateInFuture({ daysInFuture: 60 }),
        },
      );
      setCookie(CookieNames.ROPE_GRADE_PREFERENCE, result.data.userLogin.ropeGradeType, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(
        CookieNames.AVAILABLE_LOGINS,
        Array.from(
          new Set([
            ...(cookies.availableLogins ?? []),
            {
              id: result.data.userLogin.id,
              type: isClub ? SignedInUserType.CLUB : SignedInUserType.USER,
            },
          ]),
        ),
        {
          expires: getDateInFuture({ daysInFuture: 60 }),
        },
      );
      if (isClub) {
        setCookie(CookieNames.SIGNED_IN_CLUB, true, {
          expires: getDateInFuture({ daysInFuture: 60 }),
        });
      } else {
        removeCookie(CookieNames.SIGNED_IN_CLUB);
      }
      if (isClub) {
        navigate(RoutePath.MY_CLUB);
      } else {
        navigate(RoutePath.HOMEPAGE);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <TextField
            fullWidth
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            id="email"
            label="Email"
            variant="outlined"
            {...register('email')}
          />
          <TextField
            fullWidth
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            id="password"
            label="Password"
            variant="outlined"
            type={passwordShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon onClick={togglePasswordVisiblity} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }}
            {...register('password')}
          />
          <ErrorAlert
            showError={Boolean(error?.message)}
            message={
              error?.message.includes('Incorrect password!') ||
              error?.message.includes('No user could be found with that email address')
                ? error.message
                : undefined
            }
          />
          <SubmitButton label="Login" loading={loading} type="submit" variant="contained" />
        </Stack>
      </form>
    </>
  );
};
