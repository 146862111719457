import { Dialog, DialogContent } from '@mui/material';

import { IBuddySearch } from '../../../../api/types';
import { ModalHeader } from '../../../components/modal-header';
import { BuddySearchForm } from '../../buddy-search-form';

interface IEditMyListingProps {
  open: boolean;
  selectOtherListing: (item: IBuddySearch) => void;
  handleClose: () => void;
  item?: IBuddySearch;
}

export const EditMyListingModal: React.FC<IEditMyListingProps> = ({ open, handleClose, item }) => {
  return (
    <Dialog
      open={open && Boolean(item)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose} title={'Edit listing'} />
      <DialogContent dividers>
        <BuddySearchForm editItem={item} />
      </DialogContent>
    </Dialog>
  );
};
