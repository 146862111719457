import { useQuery } from '@apollo/client';
import { Alert, Box } from '@mui/material';

import { GET_LEAD_ACTIVITIES } from '../../../api/query/getLeadActivities';
import { IActivityDay, IActivityDetails, ProgressViewType } from '../../../pages/my-progress/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { transformProgressData } from '../../utils/transform-progress-data';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { IFilterState } from '../my-progress-filter-popover/types';
import { ProgressGraph } from '../progress-graph';
import { ProgressList } from '../progress-list';

interface ILeadProgressGraphProps {
  clickItem: (value: IActivityDay) => void;
  viewType: ProgressViewType;
  filterState: IFilterState;
  paginationValues: {
    limit: number;
    offset: number;
  };
}

export const LeadProgress: React.FC<ILeadProgressGraphProps> = ({
  viewType,
  filterState,
  clickItem,
  paginationValues,
}) => {
  const { cookies } = useClimbingCommunityCookies();

  const { loading, error, data } = useQuery(GET_LEAD_ACTIVITIES, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
  });

  const result: (IActivityDetails & { totalClimbs: number })[] = transformProgressData(
    data?.getLeadActivities,
    filterState,
    viewType === ProgressViewType.GRAPH,
  );

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading lead climbing progress...'}
      />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
      >
        <Alert sx={{ width: '100%' }} severity="error">
          Oops! Something went wrong!
        </Alert>
      </Box>
    );
  }

  if (viewType === ProgressViewType.LIST) {
    return (
      <ProgressList
        items={result}
        clickItem={(value: string) =>
          clickItem(data?.getLeadActivities.find((f: IActivityDay) => f.date === value))
        }
      />
    );
  }

  return (
    <ProgressGraph
      items={result}
      clickItem={(value: string) =>
        clickItem(data?.getLeadActivities.find((f: IActivityDay) => f.date === value))
      }
    />
  );
};
