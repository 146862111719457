import React from 'react';

import { ApolloError } from '@apollo/client';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';

import { ErrorAlert } from '../../components/error-alert';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { useColours } from '../../utils/use-colours';

interface IUserDetailsProps {
  data: any;
  loading: boolean;
  error: ApolloError | undefined;
}

export const UserDetails: React.FC<IUserDetailsProps> = ({ data, error, loading }) => {
  const colours = useColours();

  return (
    <Card sx={{ width: '100%', backgroundColour: colours.elevatedComponentColour }}>
      <CardContent>
        {loading && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 2,
              mt: 2,
            }}
          >
            <CircularProgress size={50} thickness={5} />
          </Box>
        )}
        <ErrorAlert showError={Boolean(error?.message)} noMargin />
        {Boolean(data?.getUser) && (
          <>
            <Typography sx={cardTitleFontStyles}>Email</Typography>
            <Typography sx={{ ...cardSubtitleFontStyles(colours), mb: 1 }}>
              {data?.getUser?.email}
            </Typography>
            <Typography sx={cardTitleFontStyles}>First name</Typography>
            <Typography sx={{ ...cardSubtitleFontStyles(colours), mb: 1 }}>
              {data?.getUser?.firstName}
            </Typography>
            <Typography sx={cardTitleFontStyles}>Last name</Typography>
            <Typography sx={{ ...cardSubtitleFontStyles(colours) }}>
              {data?.getUser?.lastName}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};
