import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Card, Icon, InputAdornment, Stack, TextField, Typography } from '@mui/material';

import { COMMENT_ACHIEVEMENT } from '../../../../api/mutation/commentAchievement';
import { GET_ACHIEVEMENT_COMMENTS } from '../../../../api/query/getAchievementComments';
import { GET_ACHIEVEMENTS } from '../../../../api/query/getAchievements';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../../api/query/getCommunityAchievements';
import { IAchievementDetails } from '../../../../api/types/achievement';
import { IComment } from '../../../../api/types/comment';
import { RoutePath } from '../../../../constants';
import { MessageFormSchema } from '../../../../validation/schemas/message-form';
import { MessageInputs } from '../../../../validation/schemas/message-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { IconSubmitButton } from '../../../components/icon-submit-button';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { ProfilePicture } from '../../../components/profile-picture';
import { ReportedComment } from '../../../components/reported-comment';
import { RouterLink } from '../../../components/router-link';
import { SubmitButton } from '../../../components/submit-button';
import { cardSmallSubtitleFontStyles } from '../../../styles/card-small-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { Comment } from './comment';
import { CommentOptionsPopover } from './comment-options-popover';

interface ICommentsProps {
  achievement: IAchievementDetails;
  profileView?: boolean;
  getAchievementVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
}

export const Comments: React.FC<ICommentsProps> = ({
  achievement,
  profileView,
  getAchievementVariables,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 5,
    offset: 0,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<MessageInputs>({
    mode: 'onChange',
    resolver: yupResolver(MessageFormSchema),
  });

  const getAchievementCommentsVariables = {
    id: achievement?.id,
    userId: cookies.signedInUser,
    limit: paginationValues.limit,
    offset: paginationValues.offset,
  };

  const getQueryDoc = (profileView?: boolean) => {
    if (profileView) {
      return GET_ACHIEVEMENTS;
    }
    return GET_COMMUNITY_ACHIEVEMENTS;
  };

  const getResultData = (data: any, profileView?: boolean) => {
    const mapper = (item: IAchievementDetails) => {
      if (item.id === achievement?.id) {
        return {
          ...item,
          commentsLength: (item?.commentsLength || 0) + 1,
        };
      }
      return item;
    };

    if (profileView) {
      return {
        getAchievements: {
          data: [...(data?.getAchievements?.data || []).map(mapper)],
          count: data?.getAchievements?.count,
        },
      };
    }

    return {
      getCommunityAchievements: {
        data: [...(data?.getCommunityAchievements.data || []).map(mapper)],
        count: data?.getCommunityAchievements?.count,
      },
    };
  };

  const { loading, error, data, fetchMore } = useQuery(GET_ACHIEVEMENT_COMMENTS, {
    variables: getAchievementCommentsVariables,
    fetchPolicy: 'network-only',
  });

  const [commentAchievement] = useMutation(COMMENT_ACHIEVEMENT, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_ACHIEVEMENT_COMMENTS,
          variables: getAchievementCommentsVariables,
        },
        (data) => ({
          getAchievementComments: {
            data: [
              { ...result?.data?.commentAchievement, reported: false },
              ...(data?.getAchievementComments?.data || []),
            ],
            count: data?.getAchievementComments?.count + 1,
          },
        }),
      );
      cache.updateQuery(
        {
          query: getQueryDoc(profileView),
          variables: getAchievementVariables,
        },
        (data) => getResultData(data, profileView),
      );
    },
  });

  const handleCommentAchievement = (formData: MessageInputs) => {
    commentAchievement({
      variables: {
        id: achievement?.id,
        comment: formData.message?.trim(),
        userId: cookies.signedInUser,
      },
    }).then(() => {
      setValue('message', '');
    });
  };

  const comments: IComment[] | undefined = data?.getAchievementComments?.data;

  return (
    <>
      <Box
        pl={2}
        pr={2}
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: !comments?.length ? 'center' : 'inherit',
          backgroundColor: colours.elevatedComponentColour,
          borderBottomLeftRadius: '0.8em',
          borderBottomRightRadius: '0.8em',
        }}
      >
        <form onSubmit={handleSubmit(handleCommentAchievement)}>
          <TextField
            onKeyPress={(ev) => {}}
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="ability"
            type="string"
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: colours.pageBackgroundColour,
              mb: 2,
              borderRadius: 1,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconSubmitButton
                    loading={false}
                    icon={<SendIcon sx={{ color: colours.primaryThemeColour }} />}
                  />
                </InputAdornment>
              ),
            }}
            {...register('message')}
          />
        </form>
        <ErrorAlert showError={Boolean(error)} />
        {Boolean(loading) && <LoadingSpinner sx={{ mb: 2 }} label={'Loading comments....'} />}
        {Boolean(!errors?.message && !loading && !comments?.length) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <Typography>{`There are no comments on this post yet!`}</Typography>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              <Icon>
                <SentimentDissatisfiedIcon />
              </Icon>
            </Typography>
          </Box>
        )}
        {comments?.map((comment: IComment) => (
          <Box
            key={comment?.id}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Card
              sx={{
                width: '100%',
                marginBottom: 2,
                backgroundColor: colours.pageBackgroundColour,
              }}
            >
              <Stack p={1} direction={'column'} alignItems="flex-start">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
                      <ProfilePicture thumbnail userId={comment.user.id} navigable />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <RouterLink href={`${RoutePath.PROFILE}${comment.user.id}`}>
                        <Typography sx={cardTitleFontStyles}>
                          {getNameFromUser(comment.user)}
                        </Typography>
                      </RouterLink>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ ...cardSmallSubtitleFontStyles(colours), pl: 2 }}>
                      {getDateSinceNowDisplayText(comment?.date)}
                    </Typography>
                    <CommentOptionsPopover
                      comment={comment}
                      getAchievementCommentsVariables={getAchievementCommentsVariables}
                      getAchievementVariables={getAchievementVariables}
                      profileView={profileView}
                      achievementId={achievement.id}
                    />
                  </Box>
                </Box>
                {comment.reported ? (
                  <ReportedComment comment={comment.comment} />
                ) : (
                  <Comment comment={comment.comment} />
                )}
              </Stack>
            </Card>
          </Box>
        ))}
        {Boolean(
          data?.getAchievementComments?.data.length > 0 &&
            data.getAchievementComments?.data.length < data.getAchievementComments?.count,
        ) && (
          <SubmitButton
            sx={{ mb: 2 }}
            variant="outlined"
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getAchievementComments?.data?.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getAchievementComments: {
                      data: [
                        ...previousResult.getAchievementComments.data,
                        ...fetchMoreResult.getAchievementComments.data,
                      ],
                      count: fetchMoreResult.getAchievementComments.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </>
  );
};
