import { useQuery } from '@apollo/client';
import { Alert } from '@mui/material';

import { GET_BUDDY_SEARCH_BY_ID } from '../../../../../api/query/getBuddySearchById';
import { IBuddySearch } from '../../../../../api/types';
import { LoadingSpinner } from '../../../../components/loading-spinner';
import { BuddySearchListItem } from '../../list-item';

interface IOtherListingsProps {
  selectOtherListing: (idx: IBuddySearch) => void;
  id?: string;
}

export const OtherListings: React.FC<IOtherListingsProps> = ({ selectOtherListing, id }) => {
  const { loading, error, data } = useQuery(GET_BUDDY_SEARCH_BY_ID, {
    variables: { id: id },
  });

  if (loading) {
    return <LoadingSpinner label={'Loading Other Listings...'} />;
  }

  if (error) {
    return (
      <Alert sx={{ width: '100%' }} severity="error">
        'Oops! Something went wrong!'
      </Alert>
    );
  }

  return data.getBuddySearchById.map((item: IBuddySearch, idx: number) => (
    <BuddySearchListItem
      key={item.gym}
      idx={idx}
      item={item}
      otherListingsItem
      onClickBuddySearchItem={() => selectOtherListing(item)}
    />
  ));
};
