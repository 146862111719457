import { IActivityDetails } from '../../../../pages/my-progress/types';

export const getBorderColourData = (items: IActivityDetails[]) => {
  return items.map((item) => {
    if (item.completed) {
      // if (item.attempts === 1) {
      return 'rgb(75, 192, 192)';
      // }
      // if (item.attempts > 1 && item.attempts < 5) {
      //   return 'rgb(255, 205, 86)';
      // }
      // if (item.attempts === 5) {
      //   return 'rgb(255, 99, 132)';
      // }
    }
    return 'rgb(201, 203, 207)';
  });
};
