import { useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import { BUDDY_SEARCH_CREATE } from '../../../api/mutation/buddySearchCreate';
import { BUDDY_SEARCH_UPDATE } from '../../../api/mutation/buddySearchUpdate';
import { IBuddySearch } from '../../../api/types';
import { RoutePath } from '../../../constants';
import { BuddySearchFormSchema } from '../../../validation/schemas/buddy-search-form';
import { BuddySearchInputs } from '../../../validation/schemas/buddy-search-form/types';
import { BasicCard } from '../../components/basic-card';
import { ClimbingGrades } from '../../components/climbing-grades';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { TileContent } from '../../components/tile-content';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

export const gymSelectionValues = [
  { value: 'redpoint', label: 'Redpoint' },
  { value: 'flashpoint', label: 'Flashpoint' },
  { value: 'tca-mothership', label: 'TCA(Mothership)' },
  { value: 'tca-church', label: 'TCA(Church)' },
  { value: 'bloc', label: 'Bloc' },
];

const checkboxValues: {
  label: string;
  amValue: 'monAm' | 'tueAm' | 'wedAm' | 'thuAm' | 'friAm' | 'satAm' | 'sunAm';
  pmValue: 'monPm' | 'tuePm' | 'wedPm' | 'thuPm' | 'friPm' | 'satPm' | 'sunPm';
}[] = [
  { label: 'Monday', amValue: 'monAm', pmValue: 'monPm' },
  { label: 'Tuesday', amValue: 'tueAm', pmValue: 'tuePm' },
  { label: 'Wednesday', amValue: 'wedAm', pmValue: 'wedPm' },
  { label: 'Thursday', amValue: 'thuAm', pmValue: 'thuPm' },
  { label: 'Friday', amValue: 'friAm', pmValue: 'friPm' },
  { label: 'Saturday', amValue: 'satAm', pmValue: 'satPm' },
  { label: 'Sunday', amValue: 'sunAm', pmValue: 'sunPm' },
];

interface IBuddySearchFormProps {
  editItem?: IBuddySearch;
}

export const BuddySearchForm: React.FC<IBuddySearchFormProps> = ({ editItem }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<BuddySearchInputs>({
    mode: 'onChange',
    defaultValues: editItem
      ? editItem
      : {
          gym: gymSelectionValues[0].value,
        },
    resolver: yupResolver(BuddySearchFormSchema),
  });

  const [gradeTableOpen, setGradeTableOpen] = useState(false);
  const [listingCreatedOpen, setListingCreatedOpen] = useState(false);

  const handleCloseListingCreatedModal = () => {
    setListingCreatedOpen(!listingCreatedOpen);
  };

  const handleClickAbilityHelper = () => {
    setGradeTableOpen(true);
  };

  const handleClose = () => {
    setGradeTableOpen(false);
  };

  const { cookies } = useClimbingCommunityCookies();
  const navigate = useNavigate();

  const [buddySearchCreate, { loading: loadingCreate, error: errorCreate }] =
    useMutation(BUDDY_SEARCH_CREATE);
  const [buddySearchUpdate, { loading: loadingUpdate, error: errorUpdate }] =
    useMutation(BUDDY_SEARCH_UPDATE);

  const onSubmit: SubmitHandler<BuddySearchInputs> = (formData: BuddySearchInputs) => {
    if (editItem) {
      buddySearchUpdate({
        variables: {
          id: editItem.id,
          input: formData,
        },
      }).then(() => {
        setListingCreatedOpen(true);
        reset();
      });
    } else {
      buddySearchCreate({ variables: { userId: cookies.signedInUser, input: formData } }).then(
        () => {
          setListingCreatedOpen(true);
          reset();
        },
      );
    }
  };

  const [bouldering, setBouldering] = useState(editItem?.bouldering ?? false);
  const [topRope, setTopRope] = useState(editItem?.topRope ?? false);
  const [lead, setLead] = useState(editItem?.lead ?? false);

  useEffect(() => {
    setValue('bouldering', bouldering);
    if (bouldering) {
      trigger('bouldering');
    }
  }, [bouldering, setValue, trigger]);

  useEffect(() => {
    setValue('topRope', topRope);
    if (topRope) {
      trigger('bouldering');
    }
  }, [topRope, setValue, trigger]);

  useEffect(() => {
    setValue('lead', lead);
    if (lead) {
      trigger('bouldering');
    }
  }, [lead, setValue, trigger]);

  // We don't want to give the ability to logout if they're not logged in.
  if (!cookies?.signedInUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <BasicCard
          title="Not signed in"
          bodyText="You must be signed in to create a listing"
          ctaText="Click here to sign in"
          alignItems="center"
          handleCtaClick={() => navigate(RoutePath.AUTHENTICATION, { relative: 'route' })}
        />
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={listingCreatedOpen} onClose={handleCloseListingCreatedModal}>
        <Box
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" component="h4">
            Buddy search created!
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            aria-label="close"
            onClick={handleCloseListingCreatedModal}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ padding: 2 }} dividers>
          <Typography mb={2}>Your listing has successfully been created!</Typography>
          <Typography fontSize={'small'}>
            You can check the 'Messages' page for any inquiries about this listing from other users.
          </Typography>
        </DialogContent>
      </Dialog>
      <TileContent
        preTitle={editItem ? undefined : `Can't find what you're looking for?`}
        title={editItem ? undefined : `Create your own listing!`}
      />
      <Controller
        name="gym"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Gym select"
            fullWidth
            error={Boolean(errors.gym?.message)}
            helperText={errors.gym?.message}
            sx={{ marginTop: '16px', marginBottom: '16px' }}
          >
            {gymSelectionValues.map((value) => (
              <MenuItem key={value.value} value={value.value}>
                {value.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <FormControl
        error={Boolean(
          errors.bouldering?.message || errors.topRope?.message || errors.lead?.message,
        )}
        sx={{ width: '100%' }}
      >
        <FormLabel component="legend">Climbing type</FormLabel>
        <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={
              <Switch
                name="bouldering"
                value={bouldering}
                onClick={() => setBouldering(!bouldering)}
              />
            }
            label="Bouldering"
          />
          <FormControlLabel
            control={<Switch name="topRope" value={topRope} onClick={() => setTopRope(!topRope)} />}
            label="Top rope"
          />
          <FormControlLabel
            control={<Switch name="lead" value={lead} onClick={() => setLead(!lead)} />}
            label="Lead"
          />
        </FormGroup>
        {Boolean(errors.bouldering?.message || errors.topRope?.message || errors.lead?.message) && (
          <FormHelperText>You must select at least one climbing type</FormHelperText>
        )}
      </FormControl>

      <Box mt={1} mb={1}>
        {checkboxValues.map((dayOfWeek) => {
          return (
            <Stack
              key={dayOfWeek.label}
              flexDirection="row"
              justifyContent={'space-between'}
              alignItems="center"
            >
              <Typography>{dayOfWeek.label}</Typography>
              <Box sx={{ display: 'flex', maginTop: '0px' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller
                        name={dayOfWeek.amValue}
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="am"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Controller
                        name={dayOfWeek.pmValue}
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label="pm"
                  />
                </FormGroup>
              </Box>
            </Stack>
          );
        })}
      </Box>

      <TextField
        autoFocus
        margin="dense"
        id="ability"
        label="Climbing ability (optional)"
        type="string"
        fullWidth
        variant="outlined"
        error={Boolean(errors.ability?.message)}
        helperText={errors.ability?.message}
        sx={{ marginBottom: '8px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickAbilityHelper}>
                <InfoIcon />
              </IconButton>
              <Dialog open={gradeTableOpen} onClose={handleClose}>
                <Box
                  sx={{
                    m: 0,
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6" component="h4">
                    Climbing Grades
                  </Typography>
                  <IconButton sx={{ padding: 0 }} aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <DialogContent sx={{ p: 0 }} dividers>
                  <ClimbingGrades />
                </DialogContent>
              </Dialog>
            </InputAdornment>
          ),
        }}
        {...register('ability')}
      />

      <TextField
        multiline
        minRows={2}
        margin="dense"
        id="additionalInformation"
        label="Additional information (optional)"
        type="string"
        fullWidth
        variant="outlined"
        error={Boolean(errors.additionalInformation?.message)}
        helperText={errors.additionalInformation?.message}
        sx={{ marginBottom: '16px' }}
        {...register('additionalInformation')}
      />
      <ErrorAlert showError={Boolean(errorUpdate || errorCreate)} />
      <SubmitButton
        label={editItem ? 'Update' : 'Create Listing!'}
        loading={loadingUpdate || loadingCreate}
        type="submit"
        variant="contained"
      />
    </form>
  );
};
