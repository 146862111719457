import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, List } from '@mui/material';

import { GET_BUDDY_SEARCH_BY_ID } from '../../../api/query/getBuddySearchById';
import { IBuddySearch } from '../../../api/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { BuddySearchListItem } from '../buddy-search-list/list-item';
import { EditMyListingModal } from './edit-my-listing-modal';

export const MyListings: React.FC = () => {
  const { cookies } = useClimbingCommunityCookies();

  const { loading, error, data } = useQuery(GET_BUDDY_SEARCH_BY_ID, {
    variables: { userId: cookies.signedInUser },
  });

  const [showBuddyItem, setShowBuddyItem] = useState<IBuddySearch>();
  const [showBuddyModal, setShowBuddyModal] = useState<boolean>(false);

  const handleOnClickBuddySearchItem = (idx: number) => {
    setShowBuddyItem(data?.getBuddySearchById[idx]);
    setShowBuddyModal(true);
  };

  const Content = () => {
    if (loading) {
      return <LoadingSpinner label={'Loading Your Listings...'} />;
    }
    if (error) {
      return (
        <Alert sx={{ width: '100%' }} severity="error">
          Oops! Something went wrong!
        </Alert>
      );
    }

    if (!data?.getBuddySearchById.length) {
      return (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="warning">
          You don't have any listings yet!
        </Alert>
      );
    }

    return data?.getBuddySearchById.map((item: IBuddySearch, idx: number) => (
      <BuddySearchListItem
        key={item.user.id}
        idx={idx}
        item={item}
        otherListingsItem
        onClickBuddySearchItem={handleOnClickBuddySearchItem}
      />
    ));
  };

  const closeModal = () => {
    setShowBuddyItem(undefined);
    setShowBuddyModal(false);
  };

  return (
    <>
      <EditMyListingModal
        open={showBuddyModal}
        handleClose={closeModal}
        selectOtherListing={(item: IBuddySearch) => setShowBuddyItem(item)}
        item={showBuddyItem !== undefined ? showBuddyItem : undefined}
      />
      <List>{Content()}</List>
    </>
  );
};
