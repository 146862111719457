import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import PersonIcon from '@mui/icons-material/Person';
import { Card, CardContent, CardHeader, TextField, Typography } from '@mui/material';

import { UPDATE_CLUB_DESCRIPTION } from '../../../api/mutation/updateClubDescription';
import { GET_USER } from '../../../api/query/getUser';
import { UpdateClubDescriptionFormSchema } from '../../../validation/schemas/update-club-description';
import { UpdateClubDescriptionFormInputs } from '../../../validation/schemas/update-club-description/types';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { ToastNotification } from '../../components/toast-notification';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

export const UpdateClubDescription: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateClubDescriptionFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(UpdateClubDescriptionFormSchema),
    defaultValues: {},
  });

  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [successToastDisplay, setSuccessToastDisplay] = useState(false);

  const [updateClubDescription, { loading, error }] = useMutation(UPDATE_CLUB_DESCRIPTION);

  const handleUpdateClubDescriptionSubmit = (formData: UpdateClubDescriptionFormInputs) => {
    updateClubDescription({
      variables: {
        userId: cookies.signedInUser,
        lastName: formData.lastName?.trim(),
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              lastName: result?.data?.updateClubDescription?.lastName,
            },
          }),
        );
      },
    }).then(() => {
      setSuccessToastDisplay(true);
      setValue('lastName', '');
    });
  };

  return (
    <Card sx={{ width: '100%' }}>
      <ToastNotification
        isOpen={successToastDisplay}
        message={'New description saved!'}
        onClose={() => setSuccessToastDisplay(false)}
      />
      <form onSubmit={handleSubmit(handleUpdateClubDescriptionSubmit)}>
        <CardHeader
          avatar={<PersonIcon sx={{ color: colours.subtextColour }} />}
          title={<Typography sx={cardSubtitleFontStyles(colours)}>Update description</Typography>}
        />
        <CardContent>
          <TextField
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            inputProps={{ maxLength: 255 }}
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.lastName?.message)}
            helperText={errors.lastName?.message}
            id="lastName"
            label="Club description"
            variant="outlined"
            {...register('lastName')}
          />
          <ErrorAlert showError={Boolean(error?.message)} />
          <SubmitButton label={'Update club description'} loading={loading} />
        </CardContent>
      </form>
    </Card>
  );
};
