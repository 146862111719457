import * as React from 'react';

import { ApolloError } from '@apollo/client';
import DraftsIcon from '@mui/icons-material/Drafts';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Alert, Box, Icon, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IMessageRoom } from '../../../../api/types/message-room';
import { COMPONENT_HEIGHTS } from '../../../../constants/component-dimentions';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { MessageSummary } from '../message-summary';

interface IMessagesProps {
  messages?: IMessageRoom[];
  handleSetSelectedMessageId: (id: string) => void;
  messageRoomsLoading?: boolean;
  messageRoomsError: ApolloError | undefined;
  messagesOpen: boolean;
  setMessagesOpen: (value: boolean) => void;
  closeRequestsList: () => void;
}
export const MessageRooms: React.FC<IMessagesProps> = ({
  messages,
  handleSetSelectedMessageId,
  messageRoomsError,
  messageRoomsLoading,
  closeRequestsList,
  messagesOpen,
  setMessagesOpen,
}) => {
  const { cookies } = useClimbingCommunityCookies();

  const handleSetMessagesOpen = () => {
    if (!messagesOpen) {
      closeRequestsList();
    }
    setMessagesOpen(!messagesOpen);
  };

  const unreadMessages = false;

  const RenderResultInfo = () => {
    if (messageRoomsLoading) {
      return <LoadingSpinner sx={{ mt: 2 }} label="Loading Messages..." />;
    }

    if (messageRoomsError) {
      return (
        <Alert sx={{ width: '100%' }} severity="error">
          {messageRoomsError.message}
        </Alert>
      );
    }

    if (!messages?.length) {
      return (
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>No messages!</Typography>
          <Typography>
            <Icon>
              <SentimentDissatisfiedIcon />
            </Icon>
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
      <ListItemButton onClick={handleSetMessagesOpen}>
        <ListItemIcon>
          <DraftsIcon color={unreadMessages ? 'primary' : 'inherit'} />
        </ListItemIcon>
        <ListItemText primary="Messages" />
        {messagesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={messagesOpen}
        timeout="auto"
        unmountOnExit
        sx={{
          maxHeight: `calc(${window.innerHeight}px - ${COMPONENT_HEIGHTS.HEADER} - 120px)`,
          overflowY: 'auto',
        }}
      >
        <RenderResultInfo />
        {messages?.map((data) => {
          const otherUser = data.user1.id === cookies.signedInUser ? data.user2 : data.user1;
          return (
            <MessageSummary
              key={data.id}
              id={data.id}
              userId={otherUser.id}
              onClick={handleSetSelectedMessageId}
              isNewMessage={true}
              name={getNameFromUser(otherUser)}
              message={data.lastMessage}
            />
          );
        })}
      </Collapse>
    </List>
  );
};
