import { useEffect, useState } from 'react';

export const useColourTheme = () => {
  const [colourTheme, setColourTheme] = useState('light');

  useEffect(() => {
    setColourTheme('light');
  }, []);

  return colourTheme;
};
