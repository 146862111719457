import { useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, List, MenuItem, TextField } from '@mui/material';

import { GET_BUDDY_SEARCH_BY_GYM } from '../../../api/query/getBuddySearchByGym';
import { IBuddySearch } from '../../../api/types';
import { BuddyListSearchInputs } from '../../../validation/schemas';
import { BuddyListSearchFormSchema } from '../../../validation/schemas/buddy-list-search-form';
import { BuddySearchInputs } from '../../../validation/schemas/buddy-search-form/types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { SubmitButton } from '../../components/submit-button';
import { gymSelectionValues } from '../buddy-search-form';
import { BuddyModal } from './buddy-modal';
import { BuddySearchListItem } from './list-item';

export const BuddySearchList: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BuddySearchInputs>({
    mode: 'onChange',
    defaultValues: {
      gym: gymSelectionValues[0].value,
    },
    resolver: yupResolver(BuddyListSearchFormSchema),
  });

  const [getSearch, { loading, error, data }] = useLazyQuery(GET_BUDDY_SEARCH_BY_GYM, {
    variables: { gym: gymSelectionValues[0].value },
  });

  const onSubmit: SubmitHandler<BuddyListSearchInputs> = (data) => {
    getSearch({ variables: { gym: data.gym } });
  };

  const [showBuddyItem, setShowBuddyItem] = useState<IBuddySearch>();
  const [showBuddyModal, setShowBuddyModal] = useState<boolean>(false);

  const handleOnClickBuddySearchItem = (idx: number) => {
    setShowBuddyItem(data?.getBuddySearchByGym[idx]);
    setShowBuddyModal(true);
  };

  const Content = () => {
    if (loading) {
      return <LoadingSpinner label={'Loading Buddy Search Results...'} />;
    }
    if (error) {
      return (
        <Alert sx={{ width: '100%' }} severity="error">
          Oops! Something went wrong!
        </Alert>
      );
    }

    if (data?.getBuddySearchByGym.length === 0) {
      return (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="warning">
          No listings could be found at this gym
        </Alert>
      );
    }

    return data?.getBuddySearchByGym.map((item: IBuddySearch, idx: number) => (
      <BuddySearchListItem
        key={item.user.id}
        idx={idx}
        item={item}
        onClickBuddySearchItem={handleOnClickBuddySearchItem}
      />
    ));
  };

  const closeModal = () => {
    setShowBuddyItem(undefined);
    setShowBuddyModal(false);
  };

  return (
    <>
      <BuddyModal
        open={showBuddyModal}
        handleClose={closeModal}
        selectOtherListing={(item: IBuddySearch) => setShowBuddyItem(item)}
        item={showBuddyItem !== undefined ? showBuddyItem : undefined}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="gym"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="Gym select"
              fullWidth
              error={Boolean(errors.gym?.message)}
              helperText={errors.gym?.message}
              sx={{ marginTop: '16px', marginBottom: '16px' }}
              InputProps={{
                endAdornment: <SubmitButton sx={{ width: '35px' }} label={<SearchIcon />} />,
              }}
            >
              {gymSelectionValues.map((value) => (
                <MenuItem key={value.value} value={value.value}>
                  {value.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </form>
      <List>{Content()}</List>
    </>
  );
};
