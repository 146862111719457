import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import { GET_PLAN_JOINS } from '../../../api/query/getPlanJoins';
import { ILike } from '../../../api/types/like';
import { IPlannerDetails } from '../../../api/types/planner';
import { RoutePath } from '../../../constants';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ModalHeader } from '../../components/modal-header';
import { ProfilePicture } from '../../components/profile-picture';
import { SubmitButton } from '../../components/submit-button';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { getDateSinceNowDisplayText } from '../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';

export interface IJoinsListModalProps {
  plan: IPlannerDetails;
  onCloseModal: () => void;
}

export const JoinsListModal: React.FC<IJoinsListModalProps> = ({ plan, onCloseModal }) => {
  const colours = useColours();
  const navigate = useNavigate();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore } = useQuery(GET_PLAN_JOINS, {
    variables: {
      id: plan.id,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  return (
    <Dialog open={true} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal} title={'Joins'} />
      <DialogContent sx={{ p: 0, minWidth: '300px' }}>
        {loading && <LoadingSpinner label={'Loading joiners...'} />}
        <ErrorAlert showError={Boolean(error?.message)} />
        {Boolean(!data?.getPlanJoins?.data?.length) && (
          <Box sx={{ p: 2 }}>
            <Alert sx={{ width: '100%' }} severity="info">
              {`Nobody has joined you in this plan!`}
            </Alert>
          </Box>
        )}
        {Boolean(!loading && data?.getPlanJoins?.data?.length > 0) ? (
          <List
            sx={{
              bgcolor: 'background.paper',
              paddingBottom: 0,
              maxHeight: `calc(${window.innerHeight}px - 170px - 56px)`,
              overflow: 'auto',
            }}
          >
            {data?.getPlanJoins?.data?.map((item: ILike, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  <ListItemButton
                    sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onClick={() => navigate(`${RoutePath.PROFILE}${item.user.id}`)}
                  >
                    <ListItem
                      alignItems="center"
                      sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: 25, height: 25, flexShrink: 0 }}>
                            <ProfilePicture thumbnail userId={item.user.id} />
                          </Box>
                          <Typography sx={{ ...cardSubtitleFontStyles(colours), pl: 2 }}>
                            {getNameFromUser(item.user)}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            ...cardSmallSubtitleFontStyles(colours),
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {getDateSinceNowDisplayText(item.date)}
                        </Typography>
                      </Box>
                    </ListItem>
                  </ListItemButton>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              );
            })}
            {Boolean(
              data?.getPlanJoins?.data?.length > 0 &&
                data?.getPlanJoins?.data?.length < data?.getPlanJoins?.count,
            ) && (
              <Box sx={{ p: 2 }}>
                <SubmitButton
                  variant="outlined"
                  label={'Load more'}
                  loading={loading}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: data?.getPlanJoins?.data?.length,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                          getPlanJoins: {
                            data: [
                              ...previousResult?.getPlanJoins?.data,
                              ...fetchMoreResult?.getPlanJoins?.data,
                            ],
                            count: fetchMoreResult?.getPlanJoins?.count,
                          },
                        };
                      },
                    })
                  }
                />
              </Box>
            )}
          </List>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
