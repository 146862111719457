import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import { GET_PLANS } from '../../api/query/getPlans';
import { IPlannerDetails } from '../../api/types/planner';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { SubmitButton } from '../../component-library/components/submit-button';
import { PlannerPost } from '../../component-library/page-layout/planner-post';
import { cardTitleFontStyles } from '../../component-library/styles/card-title-font-styles';
import { useColours } from '../../component-library/utils/use-colours';
import { useIsMobile } from '../../component-library/utils/use-is-mobile';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';

interface IUserPlannerPageProps {
  clubId: string;
}

export const UserPlannerPage: React.FC<IUserPlannerPageProps> = ({ clubId }) => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const colours = useColours();

  const [next24HoursOnly, setNext24HoursOnly] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_PLANS, {
    variables: {
      userId: clubId,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
      next24Hrs: next24HoursOnly,
    },
    initialFetchPolicy: 'network-only',
  });

  const isMobile = useIsMobile();

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading planner feed...'}
      />
    );
  }

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      marginBottom={2}
      sx={{
        width: COMPONENT_WIDTHS.BODY,
        maxWidth: '100%',
        m: 'auto',
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Box
        sx={{
          backgroundColor: colours.elevatedComponentColour,
          marginBottom: '0.8rem',
          borderBottomRadius: isMobile ? 0 : '0.8rem',
          boxShadow: '0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%)',
          pb: 2,
          pt: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center', ml: 2 }}>
          Club plans
        </Typography>
        <Box>
          <Button
            size="small"
            sx={{
              mr: 1,
              minWidth: 0,
              color: next24HoursOnly ? colours.primaryInverseColour : colours.primaryThemeColour,
            }}
            variant={next24HoursOnly ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setNext24HoursOnly(!next24HoursOnly)}
          >
            Next 24 hours
          </Button>
          <Button
            size="small"
            sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
            variant="contained"
            color="primary"
            onClick={() =>
              refetch({
                userId: clubId,
                limit: paginationValues.limit,
                offset: paginationValues.offset,
              })
            }
          >
            <ReplayIcon />
          </Button>
        </Box>
      </Box>
      {error && (
        <Box
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pb: 0,
          }}
        >
          <Alert sx={{ width: '100%' }} severity="error">
            Oops! Something went wrong!
          </Alert>
        </Box>
      )}
      {data?.getPlans?.data && !data?.getPlans?.data?.length && (
        <Box
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            pb: 0,
          }}
        >
          <Alert sx={{ width: '100%' }} severity="info">
            {'This club has no plans right now.'}
          </Alert>
        </Box>
      )}
      {data?.getPlans?.data?.map((plan: IPlannerDetails, idx: number) => (
        <React.Fragment key={plan.id}>
          {/* {Boolean(idx !== 0 && idx % 3 === 0) && <Adsense dataAdSlot="9072317848" />} */}
          <PlannerPost
            plan={plan}
            refetchPlannerVariables={{
              userId: clubId,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
              next24Hrs: next24HoursOnly,
            }}
          />
        </React.Fragment>
      ))}
      {Boolean(
        data?.getPlans?.data?.length > 0 && data?.getPlans?.data?.length < data?.getPlans?.count,
      ) && (
        <SubmitButton
          sx={{ mb: 2 }}
          label={'Load more'}
          loading={loading}
          onClick={() =>
            fetchMore({
              variables: {
                offset: data?.getPlans.data.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                return {
                  getPlans: {
                    data: [...previousResult.getPlans.data, ...fetchMoreResult.getPlans.data],
                    count: fetchMoreResult.getPlans.count,
                  },
                };
              },
            })
          }
        />
      )}
    </Stack>
  );
};
