import { useState } from 'react';

import { useMutation } from '@apollo/client';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';

import { REMOVE_PLAN } from '../../../../api/mutation/removePlan';
import { GET_COMMUNITY_PLANS } from '../../../../api/query/getCommunityPlans';
import { IPlannerDetails } from '../../../../api/types/planner';
import { ErrorAlert } from '../../../components/error-alert';
import { ModalHeader } from '../../../components/modal-header';
import { SubmitButton } from '../../../components/submit-button';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';

interface IActivityProps {
  plan: IPlannerDetails;
  refetchPlansVariables?: {
    userId: string;
    limit: number;
    offset: number;
    next24Hrs: boolean;
  };
}

export const CreatorPlanOptionsPopover: React.FC<IActivityProps> = ({
  plan,
  refetchPlansVariables,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const getQueryDoc = () => {
    return GET_COMMUNITY_PLANS;
  };

  const getResultData = (data: any) => {
    return {
      getCommunityPlans: {
        data: [
          ...(data?.getCommunityPlans?.data || []).filter(
            (item: IPlannerDetails) => item.id !== plan.id,
          ),
        ],
        count: data?.getCommunityPlans?.count - 1,
      },
    };
  };

  const [removePlan, { loading, error, reset }] = useMutation(REMOVE_PLAN, {
    update: (cache) => {
      cache.updateQuery(
        {
          query: getQueryDoc(),
          variables: refetchPlansVariables,
        },
        (data) => getResultData(data),
      );
    },
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOnClickDelete = () => {
    setModalOpen(true);
    handleClosePopover();
  };

  const popoverId = Boolean(anchorEl) ? `plan-options-popover-${plan.id}` : undefined;

  const onCloseModal = () => {
    setModalOpen(false);
    reset();
  };

  const handleConfirmDelete = () => {
    removePlan({ variables: { id: plan.id, userId: cookies.signedInUser } }).then(() =>
      onCloseModal(),
    );
  };

  return (
    <>
      <Box>
        <IconButton onClick={handleClickPopover} sx={{ height: 35 }}>
          <MoreHorizIcon
            height={24}
            width={24}
            sx={{ ...selectIconStyles(colours), color: colours.defaultTextColour }}
          />
        </IconButton>
      </Box>
      <Dialog open={modalOpen} onClose={onCloseModal}>
        <ModalHeader onClose={onCloseModal} title={'Delete plan'} />
        <DialogContent sx={{ padding: 0, minWidth: 275 }} dividers>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ ...cardTitleFontStyles, mb: 1 }}>
              Are you sure you want to delete this plan?
            </Typography>
            <ErrorAlert showError={Boolean(error?.message)} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={onCloseModal}>
                Cancel
              </Button>
              <SubmitButton
                label={'Delete'}
                loading={loading}
                fullWidth={false}
                onClick={handleConfirmDelete}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {Boolean(plan.user.id === cookies.signedInUser) && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickDelete}>Delete</ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
