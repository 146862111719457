import { gql } from '@apollo/client';

export const REPORT_USER = gql`
  mutation ReportUser(
    $reporterId: String!
    $reporteeId: String!
    $reason: String
    $block: Boolean!
  ) {
    reportUser(reporterId: $reporterId, reporteeId: $reporteeId, reason: $reason, block: $block) {
      id
    }
  }
`;
