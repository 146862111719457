import { gql } from '@apollo/client';

export const ADD_GOAL = gql`
  mutation AddGoal($input: GoalInput!) {
    addGoal(input: $input) {
      id
      type
      active
      grade
      flash
      targetDate
      beginningDate
      completionDate
    }
  }
`;
