import { useEffect, useRef, useState } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

import { cardCommentFontStyles } from '../../../styles/card-comment-font-styles';
import { cardSmallActionFontStyles } from '../../../styles/card-small-action-font-styles';
import { useColours } from '../../../utils/use-colours';

interface ICommentProps {
  comment: string;
}

export const PlanComment: React.FC<ICommentProps> = ({ comment }) => {
  const colours = useColours();
  const ref = useRef(null);
  const [style, setStyle] = useState<SxProps<Theme> | undefined>();

  useEffect(() => {
    if (ref?.current) {
      const height = (ref.current as any).clientHeight;
      if (height > 80) {
        setStyle({ maxHeight: '80px', overflow: 'hidden', mb: 1 });
      }
    }
  }, [comment]);

  return (
    <>
      <Box ref={ref} sx={style}>
        {comment?.split('\n').map((line, idx) => (
          <Typography key={idx} sx={cardCommentFontStyles}>
            {line}
          </Typography>
        ))}
      </Box>
      {Boolean(style) && (
        <Typography onClick={() => setStyle(undefined)} sx={cardSmallActionFontStyles(colours)}>
          Show more
        </Typography>
      )}
    </>
  );
};
