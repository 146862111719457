import { useEffect, useState } from 'react';

import { darkColours, lightColours } from '../../../constants/colours';
import { useColourTheme } from '../use-colour-theme';

export const useColours = () => {
  const [colours, setColours] = useState(lightColours);

  const colourTheme = useColourTheme();

  useEffect(() => {
    setColours(colourTheme === 'light' ? lightColours : darkColours);
  }, [colourTheme]);

  return colours;
};
