import { gql } from '@apollo/client';

export const GET_CLUBS_PLANS = gql`
  query GetClubsPlans($userId: String!, $limit: Int!, $offset: Int!, $next24Hrs: Boolean!) {
    getClubsPlans(userId: $userId, limit: $limit, offset: $offset, next24Hrs: $next24Hrs) {
      data {
        id
        location
        datePosted
        planDateTime
        user {
          id
          firstName
          lastName
          isClub
        }
        commentsLength
        joinsLength
        joinFilled
      }
      count
    }
  }
`;
