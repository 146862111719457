import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

import { achievementPictureUrl } from '../constants/achievement-picture-url';

export const uploadFirebaseAchievementImage = ({
  achievementId,
  file,
}: {
  achievementId: string;
  file: any;
}) => {
  const storage = getStorage();

  const metadata = {
    contentType: 'image/jpeg',
  };

  const storageRef = ref(storage, achievementPictureUrl(achievementId));
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          break;
        case 'storage/canceled':
          break;
        case 'storage/unknown':
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    },
  );
};
