import React from 'react';

import { Stack, Typography } from '@mui/material';

/**
 * This page should display if the user enters a url that does not exist in the site.
 */
export const NotFoundPage: React.FC = () => {
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" margin={10}>
      <Typography variant="h3" component={'h1'}>
        Page not found
      </Typography>
    </Stack>
  );
};
