import { deleteObject, getStorage, ref } from 'firebase/storage';

export const removeFirebaseImage = async ({ url }: { url: string }): Promise<void> => {
  const storage = getStorage();

  const storageRef = ref(storage, url);

  // Delete the file
  await deleteObject(storageRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
};
