import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Alert, Box, Stack, Typography } from '@mui/material';

import { GET_ACHIEVEMENTS } from '../../../api/query/getAchievements';
import { IAchievementDetails } from '../../../api/types/achievement';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { LoadingSpinner } from '../../components/loading-spinner';
import { SubmitButton } from '../../components/submit-button';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { AchievementPost } from '../achievement-post';

interface IProfileAchievementsProps {
  userId?: string;
}

export const ProfileAchievements: React.FC<IProfileAchievementsProps> = ({ userId }) => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 5,
    offset: 0,
  });

  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error, fetchMore } = useQuery(GET_ACHIEVEMENTS, {
    variables: {
      userId: userId,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading achievements...'}
      />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          p: 2,
          pb: 0,
        }}
      >
        <Alert sx={{ width: '100%' }} severity="error">
          Oops! Something went wrong!
        </Alert>
      </Box>
    );
  }

  if (!data?.getAchievements?.data?.length) {
    return (
      <Box
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          p: 2,
          pb: 0,
        }}
      >
        <Alert sx={{ width: '100%' }} severity="info">
          <Typography>{'This user has not posted any achievements'}</Typography>
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
        sx={{
          m: 'auto',
          pt: 2,
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
        }}
      >
        {data?.getAchievements?.data?.map((achievement: IAchievementDetails) => (
          <AchievementPost
            profileView={true}
            achievement={achievement}
            key={achievement.id}
            refetchAchievementsVariables={{
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            }}
          />
        ))}
        {Boolean(
          data?.getAchievements?.data?.length > 0 &&
            data?.getAchievements?.data?.length < data?.getAchievements?.count,
        ) && (
          <SubmitButton
            sx={{ mb: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getAchievements?.data?.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getAchievements: {
                      __typename: 'GetAchievements',
                      data: [
                        ...previousResult?.getAchievements?.data,
                        ...fetchMoreResult?.getAchievements?.data,
                      ],
                      count: fetchMoreResult?.getAchievements?.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Stack>
    </Box>
  );
};
