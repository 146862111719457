import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { MyProgressFilterPopover } from '../my-progress-filter-popover';
import { IFilterState } from '../my-progress-filter-popover/types';

interface IGraphPaginationProps {
  offset: number;
  limit: number;
  filterState: IFilterState;
  updatePaginationValues: ({ offset, limit }: { offset: number; limit: number }) => void;
  handleChangeFilterState: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const GraphPagination: React.FC<IGraphPaginationProps> = ({
  updatePaginationValues,
  offset,
  limit,
  filterState,
  handleChangeFilterState,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const setOffset = (newOffset: number) => {
    if (newOffset > -1) {
      updatePaginationValues({ offset: newOffset, limit });
    }
  };
  const setLimit = (newLimit: number) => {
    updatePaginationValues({ offset: 0, limit: newLimit });
  };

  const handleChangeLimit = (event: SelectChangeEvent<number>) => {
    setLimit(parseInt(event.target.value.toString(), 10));
  };

  const reset = () => {
    updatePaginationValues({ offset: 0, limit: 10 });
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
        pl: 2,
        pr: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <IconButton sx={{ minWidth: 0 }} onClick={() => setOffset(offset + 1)}>
        <ArrowBackIcon />
      </IconButton>
      <Box sx={{ display: 'flex' }}>
        <Select
          sx={{ mr: 1, minWidth: 0 }}
          size="small"
          variant="standard"
          value={limit}
          label="No. results"
          onChange={handleChangeLimit}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
        </Select>
        <IconButton
          sx={{ minWidth: 0 }}
          onClick={handleClickPopover}
          aria-describedby={Boolean(anchorEl) ? 'simple-popover' : undefined}
        >
          <FilterAltIcon />
        </IconButton>
        <MyProgressFilterPopover
          id={Boolean(anchorEl) ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          filterState={filterState}
          handleChangeFilterState={handleChangeFilterState}
        />
        <IconButton sx={{ ml: 1, minWidth: 0 }} onClick={() => reset()}>
          <RestartAltIcon />
        </IconButton>
      </Box>
      <IconButton sx={{ minWidth: 0 }} onClick={() => setOffset(offset - 1)}>
        <ArrowForwardIcon />
      </IconButton>
    </Box>
  );
};
