import { gql } from '@apollo/client';

export const UPDATE_CLUB_PRIVACY = gql`
  mutation UpdateClubPrivacy($userId: String!, $isClubPrivate: Boolean!) {
    updateClubPrivacy(userId: $userId, isClubPrivate: $isClubPrivate) {
      id
      email
      firstName
      lastName
      isClub
      isClubPrivate
    }
  }
`;
