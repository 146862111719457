import { CircularProgress, IconButton, Typography } from '@mui/material';

import { useColours } from '../../utils/use-colours';

interface IIconSubmitButtonProps {
  icon: JSX.Element | string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  sx?: any;
  label?: string;
  labelSx?: any;
  inheritColour?: boolean;
}
/**
 * Use this on every icon submit button.
 * It shows a spinning icon when loading is set to true.
 * This allows the user to see something is happening.
 */
export const IconSubmitButton: React.FC<IIconSubmitButtonProps> = ({
  loading,
  icon,
  disabled,
  onClick,
  sx,
  label,
  labelSx,
  inheritColour,
}) => {
  const colours = useColours();
  return (
    <IconButton
      disabled={loading || disabled}
      sx={{ ...sx, color: inheritColour ? undefined : colours.primaryThemeColour }}
      onClick={onClick}
      data-testid="icon-submit-button"
      type="submit"
    >
      {loading ? (
        <CircularProgress
          size={20}
          thickness={5}
          data-testid="icon-submit-button-loading-splinner"
        />
      ) : (
        icon
      )}
      {Boolean(label && !loading) && <Typography sx={labelSx}>{label}</Typography>}
    </IconButton>
  );
};
