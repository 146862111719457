import React from 'react';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { LogoutNavItem } from '../../components/logout-nav-item';
import { RouterLink } from '../../components/router-link';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import {
  AccountLinks,
  ClubLinks,
  ClubNotSignedInLinks,
  GeneralLinks,
  NotSignedInLinks,
} from './constants';

export interface SideNavigationProps {
  isNavMenuOpen: boolean;
  closeNavMenu: () => void;
}

/**
 * The side navigation helps the user navigate through the website.
 * There should be a link to each page in the site that every user can visit.
 * There should also be a section for the logged in users additional pages.
 */
export const SideNavigation: React.FC<SideNavigationProps> = ({ isNavMenuOpen, closeNavMenu }) => {
  const { cookies } = useClimbingCommunityCookies();

  const colours = useColours();

  return (
    <Drawer anchor={'left'} open={isNavMenuOpen} onClose={closeNavMenu}>
      <Box
        sx={{ height: '100%', width: 250, backgroundColor: colours.primaryThemeColour }}
        role="presentation"
        onClick={closeNavMenu}
        onKeyDown={closeNavMenu}
      >
        <List>
          <ListItem>
            <Typography sx={{ ...cardTitleFontStyles, color: colours.primaryInverseColour }}>
              Personal
            </Typography>
          </ListItem>
          {(cookies.signedInUser && !cookies.signedInClub
            ? AccountLinks(cookies.signedInUser)
            : NotSignedInLinks
          ).map(({ href, label, icon }) => {
            return (
              <RouterLink key={href} href={href}>
                <ListItem key={label} disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: colours.primaryInverseColour }}>{icon}</ListItemIcon>
                    <ListItemText primary={label} sx={{ color: colours.primaryInverseColour }} />
                  </ListItemButton>
                </ListItem>
              </RouterLink>
            );
          })}
          {cookies.signedInUser && !cookies.signedInClub && <LogoutNavItem />}
        </List>
        <Divider />
        <List>
          <ListItem>
            <Typography sx={{ ...cardTitleFontStyles, color: colours.primaryInverseColour }}>
              Club
            </Typography>
          </ListItem>
          {(cookies.signedInClub && cookies.signedInUser
            ? ClubLinks(cookies.signedInUser)
            : ClubNotSignedInLinks
          ).map(({ href, label, icon }) => {
            return (
              <RouterLink key={href} href={href}>
                <ListItem key={label} disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: colours.primaryInverseColour }}>{icon}</ListItemIcon>
                    <ListItemText primary={label} sx={{ color: colours.primaryInverseColour }} />
                  </ListItemButton>
                </ListItem>
              </RouterLink>
            );
          })}
          {cookies.signedInUser && cookies.signedInClub && <LogoutNavItem isClub />}
        </List>
        <Divider />
        <List>
          {GeneralLinks.map(({ href, label, icon }) => {
            return (
              <RouterLink key={href} href={href}>
                <ListItem key={label} disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ color: colours.primaryInverseColour }}>{icon}</ListItemIcon>
                    <ListItemText primary={label} sx={{ color: colours.primaryInverseColour }} />
                  </ListItemButton>
                </ListItem>
              </RouterLink>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};
