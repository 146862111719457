import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button } from '@mui/material';

import { GET_CLUBS_PLANS } from '../../../api/query/getClubsPlans';
import { IPlannerDetails } from '../../../api/types/planner';
import { LoadingSpinner } from '../../../component-library/components/loading-spinner';
import { SubmitButton } from '../../../component-library/components/submit-button';
import { LogPlanModal } from '../../../component-library/page-layout/log-plan-modal';
import { PlannerPost } from '../../../component-library/page-layout/planner-post';
import { useColours } from '../../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../../component-library/utils/use-cookies';

export const ClubsPlanner: React.FC = () => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [addNewPlannerItemOpen, setAddNewPlannerItemOpen] = useState(false);
  const [next24HoursOnly, setNext24HoursOnly] = useState(false);

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_CLUBS_PLANS, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
      next24Hrs: next24HoursOnly,
    },
    initialFetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading your planner feed...'}
      />
    );
  }

  return (
    <>
      <LogPlanModal
        isOpen={addNewPlannerItemOpen}
        onSuccess={() => {
          setAddNewPlannerItemOpen(false);
          refetch({
            userId: cookies.signedInUser,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          });
        }}
        onClose={() => setAddNewPlannerItemOpen(false)}
        refetchPlannerVariables={{
          userId: cookies.signedInUser,
          limit: paginationValues.limit,
          offset: paginationValues.offset,
        }}
      />
      <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          size="small"
          sx={{ ml: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          onClick={() => setAddNewPlannerItemOpen(true)}
          endIcon={<AddIcon />}
        >
          Add plan
        </Button>
        <Box>
          <Button
            size="small"
            sx={{
              mr: 1,
              minWidth: 0,
              color: next24HoursOnly ? colours.primaryInverseColour : colours.primaryThemeColour,
            }}
            variant={next24HoursOnly ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setNext24HoursOnly(!next24HoursOnly)}
          >
            Next 24 hours
          </Button>
          <Button
            size="small"
            sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
            variant="contained"
            color="primary"
            onClick={() =>
              refetch({
                userId: cookies.signedInUser,
                limit: paginationValues.limit,
                offset: paginationValues.offset,
              })
            }
          >
            <ReplayIcon />
          </Button>
        </Box>
      </Box>
      {error && (
        <Box
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
        >
          <Alert sx={{ width: '100%' }} severity="error">
            Oops! Something went wrong!
          </Alert>
        </Box>
      )}
      {data?.getClubsPlans?.data && !data?.getClubsPlans?.data?.length && (
        <Box
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
        >
          <Alert sx={{ width: '100%' }} severity="info">
            {'Nobody in your community has posted any plans!'}
            <br />
            {'Try expanding your community.'}
          </Alert>
        </Box>
      )}
      {data?.getClubsPlans?.data?.map((plan: IPlannerDetails, idx: number) => (
        <React.Fragment key={plan.id}>
          {/* {Boolean(idx !== 0 && idx % 3 === 0) && <Adsense dataAdSlot="9072317848" />} */}
          <PlannerPost
            plan={plan}
            refetchPlannerVariables={{
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
              next24Hrs: next24HoursOnly,
            }}
          />
        </React.Fragment>
      ))}
      {Boolean(
        data?.getClubsPlans?.data?.length > 0 &&
          data?.getClubsPlans?.data?.length < data?.getClubsPlans?.count,
      ) && (
        <SubmitButton
          sx={{ mb: 2 }}
          label={'Load more'}
          loading={loading}
          onClick={() =>
            fetchMore({
              variables: {
                offset: data?.getClubsPlans.data.length,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                return {
                  getClubsPlans: {
                    data: [
                      ...previousResult.getClubsPlans.data,
                      ...fetchMoreResult.getClubsPlans.data,
                    ],
                    count: fetchMoreResult.getClubsPlans.count,
                  },
                };
              },
            })
          }
        />
      )}
    </>
  );
};
