import React, { useState } from 'react';

import { encrypt } from 'n-krypta';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormControlLabel, InputAdornment, Stack, Switch, TextField } from '@mui/material';

import { CREATE_CLUB } from '../../../api/mutation/createClub';
import { CookieNames, RoutePath } from '../../../constants';
import { SignedInUserType } from '../../../constants/cookie-names';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { CreateClubFormInputs, CreateClubFormSchema } from '../../../validation/schemas';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { getDateInFuture } from '../../utils/get-date-in-future';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IClubSignUpContentProps {
  signUpComplete?: () => void;
}

export const ClubSignUpContent: React.FC<IClubSignUpContentProps> = ({ signUpComplete }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateClubFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(CreateClubFormSchema),
  });

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [privateClub, setPrivateClub] = useState(true);

  const { setCookie, cookies } = useClimbingCommunityCookies();

  const [createClub, { loading, error }] = useMutation(CREATE_CLUB);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const onSubmit: SubmitHandler<CreateClubFormInputs> = (data) => {
    const encryptedPassword = encrypt(data.password?.trim(), ENCRYPTION_SECRET);
    createClub({
      variables: {
        email: data.email?.trim(),
        password: encryptedPassword,
        firstName: data.firstName?.trim(),
        isClubPrivate: privateClub,
      },
    }).then((result) => {
      setCookie(CookieNames.SIGNED_IN_USER, result.data.clubSignup.id, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(CookieNames.SIGNED_IN_CLUB, true, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(
        CookieNames.AVAILABLE_LOGINS,
        Array.from(
          new Set([
            ...(cookies.availableLogins ?? []),
            { id: result.data.clubSignup.id, type: SignedInUserType.CLUB },
          ]),
        ),
        {
          expires: getDateInFuture({ daysInFuture: 60 }),
        },
      );
      navigate(RoutePath.MY_CLUB);
      signUpComplete?.();
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
        <TextField
          fullWidth
          error={Boolean(errors.firstName?.message)}
          helperText={errors.firstName?.message}
          id="firstName"
          label="Club name"
          variant="outlined"
          {...register('firstName')}
        />
        <TextField
          fullWidth
          error={Boolean(errors.email?.message)}
          helperText={errors.email?.message}
          id="email"
          label="Email"
          variant="outlined"
          {...register('email')}
        />
        <TextField
          fullWidth
          error={Boolean(errors.password?.message)}
          helperText={errors.password?.message}
          id="password"
          label="Password"
          variant="outlined"
          type={passwordShown ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon onClick={togglePasswordVisiblity} sx={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
          {...register('password')}
        />

        <TextField
          fullWidth
          error={Boolean(errors.confirmPassword?.message)}
          helperText={errors.confirmPassword?.message}
          id="confirmPassword"
          label="Confirm password"
          variant="outlined"
          type={passwordShown ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon onClick={togglePasswordVisiblity} sx={{ cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
          {...register('confirmPassword')}
        />

        <FormControlLabel
          control={
            <Switch
              value={privateClub}
              onChange={() => {
                setPrivateClub(!privateClub);
              }}
            />
          }
          label="Private Club"
        />

        <ErrorAlert
          showError={Boolean(error)}
          message={
            error?.message === 'A club with that email already exists.'
              ? 'A club with that email already exists'
              : undefined
          }
        />
        <SubmitButton label="Create Club" loading={loading} type="submit" variant="contained" />
      </Stack>
    </form>
  );
};
