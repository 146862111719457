import { ClimbingType } from '../pages/my-progress/types';

export const getClimbingTitleFromType = (type: ClimbingType) => {
  if (type === ClimbingType.BOULDER) {
    return 'Boulder climb';
  }
  if (type === ClimbingType.LEAD) {
    return 'Lead climb';
  }
  if (type === ClimbingType.TOP_ROPE) {
    return 'Top rope climb';
  }
};
