import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';

import { PageLayout } from '../component-library/page-layout/page-layout';
import { useColours } from '../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../component-library/utils/use-cookies';
import { RoutePath } from '../constants';
import { AchievementPage } from './achievement';
import { AuthenticationPage } from './authentication';
import { BuddySearchPage } from './buddy-search';
import { ClubAuthenticationPage } from './club-authentication';
import { CommunityPage } from './community';
import { GoalsPage } from './goals';
import { HelpPage } from './help';
import { ManageClubCommunityPage } from './manage-club-community';
import { ManageClubsPage } from './manage-clubs';
import { ManageCommunityPage } from './manage-community';
import { MessagesPage } from './messages';
import { MyAccountPage } from './my-account';
import { MyClubPage } from './my-club';
import { MyPlannerPage } from './my-planner';
import { MyProgressPage } from './my-progress';
import { NotFoundPage } from './not-found';
import { PlanPage } from './plan';
import { PlannerPage } from './planner';
import { PrivacyPolicyPage } from './privacy-policy';
import { ProfilePage } from './profile';

/**
 * The app should be wrapped by all things needed on every page in the application.
 * Routing is required on every page so should be placed here.
 */
export const App: React.FC = () => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const theme = createTheme({
    palette: {
      primary: {
        main: colours.primaryThemeColour,
        contrastText: '#fff',
      },
      secondary: {
        main: colours.elevatedComponentColour,
        contrastText: colours.primaryThemeColour,
      },
    },
  });

  const signedInOnlyPage = (element: JSX.Element): JSX.Element => {
    if (cookies.signedInUser) {
      return element;
    }

    return <AuthenticationPage />;
  };

  const signedInUserOnlyPage = (element: JSX.Element): JSX.Element => {
    if (cookies.signedInUser && !cookies.signedInClub) {
      return element;
    }

    return <AuthenticationPage />;
  };

  const clubOnlyPage = (element: JSX.Element): JSX.Element => {
    if (cookies.signedInUser && cookies.signedInClub) {
      return element;
    }

    return <AuthenticationPage />;
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: colours.pageBackgroundColour },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path={RoutePath.HOMEPAGE} element={<PageLayout />}>
            <Route index element={signedInUserOnlyPage(<CommunityPage />)} />
            <Route path={RoutePath.AUTHENTICATION} element={<AuthenticationPage />} />
            <Route path={RoutePath.CLUB_AUTHENTICATION} element={<ClubAuthenticationPage />} />
            <Route path={RoutePath.PLANNER} element={signedInUserOnlyPage(<PlannerPage />)} />
            <Route path={RoutePath.MY_PLANNER} element={clubOnlyPage(<MyPlannerPage />)} />
            <Route path={`${RoutePath.PLAN}:id`} element={signedInUserOnlyPage(<PlanPage />)} />
            <Route
              path={RoutePath.BUDDY_SEARCH}
              element={signedInUserOnlyPage(<BuddySearchPage />)}
            />
            <Route path={RoutePath.MY_ACCOUNT} element={signedInUserOnlyPage(<MyAccountPage />)} />
            <Route path={RoutePath.MY_CLUB} element={clubOnlyPage(<MyClubPage />)} />
            <Route path={RoutePath.MESSAGES} element={signedInUserOnlyPage(<MessagesPage />)} />
            <Route path={RoutePath.PROGRESS} element={signedInUserOnlyPage(<MyProgressPage />)} />
            <Route
              path={`${RoutePath.ACHIEVEMENT}:id`}
              element={signedInUserOnlyPage(<AchievementPage />)}
            />
            <Route path={`${RoutePath.PROFILE}:id`} element={signedInOnlyPage(<ProfilePage />)} />
            <Route path={RoutePath.COMMUNITY} element={signedInOnlyPage(<CommunityPage />)} />
            <Route
              path={RoutePath.MANAGE_COMMUNITY}
              element={signedInUserOnlyPage(<ManageCommunityPage />)}
            />
            <Route
              path={RoutePath.MANAGE_CLUB_COMMUNITY}
              element={signedInOnlyPage(<ManageClubCommunityPage />)}
            />
            <Route
              path={RoutePath.MANAGE_CLUBS}
              element={signedInUserOnlyPage(<ManageClubsPage />)}
            />
            <Route path={RoutePath.HELP} element={<HelpPage />} />
            <Route path={RoutePath.GOALS} element={signedInUserOnlyPage(<GoalsPage />)} />
            <Route path={RoutePath.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
            <Route path={RoutePath.UNKNOWN} element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
