import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!, $isClub: Boolean) {
    userLogin(email: $email, password: $password, isClub: $isClub) {
      id
      firstName
      lastName
      email
      hideGoals
      hideSummary
      autoPostGoalDisabled
      boulderingGradeType
      ropeGradeType
      isClub
    }
  }
`;
