import { gql } from '@apollo/client';

export const COMMENT_ACHIEVEMENT = gql`
  mutation CommentAchievement($id: String!, $userId: String!, $comment: String!) {
    commentAchievement(id: $id, userId: $userId, comment: $comment) {
      id
      comment
      date
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
