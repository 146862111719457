import { gql } from '@apollo/client';

export const GET_PLAN_COMMENTS = gql`
  query GetPlanComments($id: String!, $userId: String!, $limit: Int!, $offset: Int!) {
    getPlanComments(id: $id, userId: $userId, limit: $limit, offset: $offset) {
      id
      comment
      date
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
