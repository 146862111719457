import React from 'react';

import { Box, Card, Stack, Typography } from '@mui/material';

import { RouterLink } from '../../component-library/components/router-link';
import { cardBodyFontStyles } from '../../component-library/styles/card-font-styles';
import { cardLargeTitleFontStyles } from '../../component-library/styles/card-large-title-font-styles';
import { cardTitleFontStyles } from '../../component-library/styles/card-title-font-styles';
import { useColours } from '../../component-library/utils/use-colours';
import { RoutePath } from '../../constants';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';

export enum HelpTabs {
  PROGRESS = 'PROGRESS',
  GOALS = 'GOALS',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  COMMUNITY = 'COMMUNITY',
  MANAGE_COMMUNITY = 'MANAGE_COMMUNITY',
  // BUDDY_SEARCH = 'BUDDY_SEARCH',
  MESSAGES = 'MESSAGES',
}

export const HelpPage: React.FC = () => {
  const colours = useColours();

  // const [selectedTab, setSelectedTab] = useState<HelpTabs>(HelpTabs.PROGRESS);
  // const [selectedSubTab, setSelectedSubTab] = useState<string>('1');
  // const handleChangeSelectedTab = (event: SelectChangeEvent<string>) => {
  //   setSelectedTab(event.target.value as HelpTabs);
  //   setSelectedSubTab('1');
  // };
  // const handleChangeSelectedSubTab = (event: SelectChangeEvent<string>) => {
  //   setSelectedSubTab(event.target.value);
  // };
  // const SubTabOptions = (selectedTab: HelpTabs): SubTab[] => {
  //   switch (selectedTab) {
  //     case HelpTabs.PROGRESS:
  //       return MyProgressSubTabs;
  //     case HelpTabs.GOALS:
  //       return MyGoalsSubTabs;
  //     case HelpTabs.ACHIEVEMENTS:
  //       return MyAchievementsSubTabs;
  //     case HelpTabs.COMMUNITY:
  //       return CommunitySubTabs;
  //     case HelpTabs.MANAGE_COMMUNITY:
  //       return ManageCommunitySubTabs;
  //     // case HelpTabs.BUDDY_SEARCH:
  //     //   return MyProgressSubTabs;
  //     case HelpTabs.MESSAGES:
  //       return MyProgressSubTabs;
  //   }
  // };
  // return (
  //   <Stack direction="column" justifyContent="center" alignItems="center" marginBottom={2}>
  //     <Box style={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
  //       <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
  //         <Select
  //           sx={{
  //             ...cardSmallSelectFontStyles(colours),
  //             backgroundColor: colours.primaryInverseColour,
  //           }}
  //           size="small"
  //           variant="outlined"
  //           value={selectedTab}
  //           onChange={handleChangeSelectedTab}
  //         >
  //           <MenuItem value={HelpTabs.PROGRESS}>1. Progress</MenuItem>
  //           <MenuItem value={HelpTabs.GOALS}>2. Goals</MenuItem>
  //           <MenuItem value={HelpTabs.ACHIEVEMENTS}>3. Achievements</MenuItem>
  //           <MenuItem value={HelpTabs.COMMUNITY}>4. Community</MenuItem>
  //           <MenuItem value={HelpTabs.MANAGE_COMMUNITY}>5. Manage Community</MenuItem>
  //           {/* <MenuItem value={HelpTabs.BUDDY_SEARCH}>6. Buddy Search</MenuItem> */}
  //           <MenuItem value={HelpTabs.MESSAGES}>7. Messages</MenuItem>
  //         </Select>
  //         <Select
  //           sx={{
  //             ...cardSmallSelectFontStyles(colours),
  //             backgroundColor: colours.primaryInverseColour,
  //           }}
  //           size="small"
  //           variant="outlined"
  //           value={selectedSubTab}
  //           onChange={handleChangeSelectedSubTab}
  //         >
  //           {selectedTab &&
  //             SubTabOptions(selectedTab).map((item) => (
  //               <MenuItem value={item.value}>{`${item.value} - ${item.label}`}</MenuItem>
  //             ))}
  //         </Select>
  //       </Box>
  //       <HelpSection tab={selectedTab} selectedSubTab={selectedSubTab} />
  //     </Box>
  //   </Stack>
  // );
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" marginBottom={2}>
      <Box style={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <Card
          sx={{
            m: 2,
            mt: 2,
            // height: `calc(${window.innerHeight}px - ${COMPONENT_HEIGHTS.HEADER} - 72px - 16px)`,
            // maxHeight: 575,
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
          }}
        >
          <Box sx={{ flexGrow: 0, p: 2 }}>
            <Typography sx={cardLargeTitleFontStyles}>Where do I start?</Typography>
            <Typography mt={2} sx={cardTitleFontStyles}>
              Adding friends
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              You should start by adding people you know to your community. To do this select the
              "Add Member" button in{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.MANAGE_COMMUNITY}
              >
                Manage Community
              </RouterLink>
              .
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Once they have accepted your request, you can see each-others content in the{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.COMMUNITY}
              >
                Community
              </RouterLink>{' '}
              feed.
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              You can easily add friends of friends via the "Suggested Friends" tab in{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.MANAGE_COMMUNITY}
              >
                Manage Community
              </RouterLink>
              {', '}
              so it's worth checking in there once in a while.
            </Typography>
            <Typography mt={2} sx={cardTitleFontStyles}>
              Joining clubs
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              You can also search for some clubs to join. To do this select the "Find clubs" tab in{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.MANAGE_CLUBS}
              >
                Manage Clubs
              </RouterLink>
              .
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Some clubs are private, meaning they will need to accept your request. Clubs that
              aren't private can be joined instantly.
            </Typography>

            <Typography mt={2} sx={cardTitleFontStyles}>
              Setting a goal
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Before you start climbing, you should set your goals. These can be set and altered at
              any time in{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.GOALS}
              >
                Goals
              </RouterLink>{' '}
              or{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.PROGRESS}
              >
                My Progress
              </RouterLink>
              .
            </Typography>

            <Typography mt={2} sx={cardTitleFontStyles}>
              Planning a session
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Want someone to climb with you? Before you head out to your favorite climbing
              spot/gym, let your community know you're heading there by creating a plan in the{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.PLANNER}
              >
                Planner
              </RouterLink>
              . Your community can see your plans in their planners and visa versa, so they can join
              you, or you could join them!
            </Typography>

            <Typography mt={2} sx={cardTitleFontStyles}>
              Logging climbs
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Whenever you do a climb that you feel is noteworthy, log it via{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.PROGRESS}
              >
                Progress
              </RouterLink>{' '}
              or{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.COMMUNITY}
              >
                Community
              </RouterLink>
              .
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Logging via{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.COMMUNITY}
              >
                Community
              </RouterLink>{' '}
              will post it straight to your community feed, so that your friends can see it.
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Logging via{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.PROGRESS}
              >
                Progress
              </RouterLink>{' '}
              will give you the option of sharing the climb with your community or keeping it
              private.
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              Either way, your climb will be recorded in{' '}
              <RouterLink
                sx={{ display: 'inline', color: colours.primaryThemeColour }}
                href={RoutePath.PROGRESS}
              >
                Progress
              </RouterLink>
              .
            </Typography>
            <Typography sx={cardBodyFontStyles(colours)}>
              You can log as many or as little climbs as you like, although it is recommended to at
              least log your best climb of the session so that you can check your progress over
              time.
            </Typography>
          </Box>
        </Card>
      </Box>
    </Stack>
  );
};
