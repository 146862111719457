import { gql } from '@apollo/client';

export const GET_ACHIEVEMENT_LIKES = gql`
  query GetAchievementLikes($id: String!, $limit: Int!, $offset: Int!) {
    getAchievementLikes(id: $id, limit: $limit, offset: $offset) {
      data {
        id
        date
        user {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`;
