import { useState } from 'react';

import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, IconButton, Popover, Typography } from '@mui/material';

import { IAchievementDetails } from '../../../api/types/achievement';
import { RoutePath } from '../../../constants';
import { BASE_PATH } from '../../../constants/routes';
import { cardSelectFontStyles } from '../../styles/card-select-font-styles';
import { useColours } from '../../utils/use-colours';

interface ISocialShareProps {
  achievement: IAchievementDetails;
}

export const SocialShare: React.FC<ISocialShareProps> = ({ achievement }) => {
  const colours = useColours();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverId = Boolean(anchorEl) ? `social-share-popover-${achievement.id}` : undefined;
  const shareUrl = `${BASE_PATH}${RoutePath.ACHIEVEMENT}${achievement.id}`;

  return (
    <>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row' }}>
          <WhatsappShareButton
            onClick={handleClosePopover}
            url={shareUrl}
            style={{ display: 'flex', marginLeft: '6px', marginRight: '6px' }}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <TwitterShareButton
            onClick={handleClosePopover}
            url={shareUrl}
            style={{ display: 'flex', marginLeft: '6px', marginRight: '6px' }}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <FacebookMessengerShareButton
            onClick={handleClosePopover}
            url={shareUrl}
            style={{ display: 'flex', marginLeft: '6px', marginRight: '6px' }}
            appId=""
          >
            <FacebookMessengerIcon size={32} round={true} />
          </FacebookMessengerShareButton>
          <FacebookShareButton
            onClick={handleClosePopover}
            url={shareUrl}
            style={{ display: 'flex', marginLeft: '6px', marginRight: '6px' }}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton
            onClick={handleClosePopover}
            url={shareUrl}
            style={{ display: 'flex', marginLeft: '6px', marginRight: '6px' }}
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
        </Box>
      </Popover>
      <IconButton
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexShrink: 0,
          borderRadius: '0.8em',
        }}
        onClick={handleClickPopover}
      >
        <IosShareIcon sx={{ height: 24, width: 24, mr: 1, color: colours.subtextColour }} />
        <Typography sx={cardSelectFontStyles(colours)}>Share</Typography>
      </IconButton>
    </>
  );
};
