import React, { useState } from 'react';

import Compressor from 'compressorjs';
import { SubmitHandler } from 'react-hook-form';

import { useMutation } from '@apollo/client';

import { ADD_ACTIVITY } from '../../../api/mutation/addActivity';
import { GET_BOULDERING_ACTIVITIES } from '../../../api/query/getBoulderingActivities';
import { GET_LEAD_ACTIVITIES } from '../../../api/query/getLeadActivities';
import { GET_TOP_ROPE_ACTIVITIES } from '../../../api/query/getTopRopeActivities';
import { ClimbingType } from '../../../pages/my-progress/types';
import { isGoalComplete } from '../../../utils/is-goal-complete';
import { uploadFirebaseAchievementImage } from '../../../utils/upload-firebase-achievement-image';
import { LogActivityFormInputs } from '../../../validation/schemas/log-activity-form/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { gradeToNumber } from '../progress-graph/utils/grade-to-number';
import { LogActivityFormComponent } from './form';

interface ILogActivityFormProps {
  tab: ClimbingType;
  goal?: IGoalDetails;
  refetchActivitiesVariables: {
    userId: string;
    type: ClimbingType;
    limit: number;
    offset: number;
  };
  setCropperOpen?: (isOpen: boolean) => void;
  onSuccess: (goalComplete: boolean, achievementSaved: boolean) => void;
}

export const LogActivityForm: React.FC<ILogActivityFormProps> = ({
  tab,
  goal,
  refetchActivitiesVariables,
  setCropperOpen = () => null,
  onSuccess,
}) => {
  const { cookies } = useClimbingCommunityCookies();

  const [imageForUpload, setImageForUpload] = useState<Blob | undefined>();

  // const refetchQueryDoc = (result: any) => {
  //   switch (result?.data?.addActivity.type) {
  //     case ClimbingType.BOULDER:
  //       return GET_BOULDERING_ACTIVITIES;
  //     case ClimbingType.LEAD:
  //       return GET_LEAD_ACTIVITIES;
  //     default:
  //       return GET_TOP_ROPE_ACTIVITIES;
  //   }
  // };

  // const refetchQueryClimbingData = (data: any, result: any) => {
  //   switch (result?.data?.addActivity.type) {
  //     case ClimbingType.BOULDER:
  //       const newDay = data?.getBoulderingActivities?.find(
  //         (f: any) => f.date === result?.data?.addActivity?.activityDay?.date,
  //       );

  //       if (!newDay) {
  //         return {
  //           getBoulderingActivities: [
  //             ...(data?.getBoulderingActivities || []),
  //             {
  //               ...result?.data?.addActivity?.activityDay,
  //               activities: [
  //                 {
  //                   id: result?.data?.addActivity?.id,
  //                   attempts: result?.data?.addActivity?.attempts,
  //                   comment: result?.data?.addActivity?.comment,
  //                   completed: result?.data?.addActivity?.completed,
  //                   grade: result?.data?.addActivity?.grade,
  //                   intensity: result?.data?.addActivity?.intensity,
  //                   type: result?.data?.addActivity?.type,
  //                   achievement: result?.data?.addActivity?.achievement,
  //                 },
  //               ],
  //             },
  //           ],
  //         };
  //       }

  //       return {
  //         getBoulderingActivities: [
  //           ...(data?.getBoulderingActivities || []).map((day: any) => ({
  //             ...day,
  //             activities: [...day.activities, result.data.addActivity],
  //           })),
  //         ],
  //       };

  //     case ClimbingType.LEAD:
  //       const newLeadDay = data?.getLeadActivities?.find(
  //         (f: any) => f.date === result?.data?.addActivity?.activityDay?.date,
  //       );

  //       if (!newLeadDay) {
  //         return {
  //           getLeadActivities: [
  //             ...(data?.getLeadActivities || []),
  //             {
  //               ...result?.data?.addActivity?.activityDay,
  //               activities: [
  //                 {
  //                   id: result?.data?.addActivity?.id,
  //                   attempts: result?.data?.addActivity?.attempts,
  //                   comment: result?.data?.addActivity?.comment,
  //                   completed: result?.data?.addActivity?.completed,
  //                   grade: result?.data?.addActivity?.grade,
  //                   intensity: result?.data?.addActivity?.intensity,
  //                   type: result?.data?.addActivity?.type,
  //                   achievement: result?.data?.addActivity?.achievement,
  //                 },
  //               ],
  //             },
  //           ],
  //         };
  //       }

  //       return {
  //         getLeadActivities: [
  //           ...(data?.getLeadActivities || []).map((day: any) => ({
  //             ...day,
  //             activities: [...day?.activities, result?.data?.addActivity],
  //           })),
  //         ],
  //       };

  //     case ClimbingType.TOP_ROPE:
  //       const newTopRopeDay = data?.getTopRopeActivities?.find(
  //         (f: any) => f.date === result?.data?.addActivity?.activityDay?.date,
  //       );

  //       if (!newTopRopeDay) {
  //         return {
  //           getTopRopeActivities: [
  //             ...(data?.getTopRopeActivities || []),
  //             {
  //               ...result?.data?.addActivity?.activityDay,
  //               activities: [
  //                 {
  //                   id: result?.data?.addActivity?.id,
  //                   attempts: result?.data?.addActivity?.attempts,
  //                   comment: result?.data?.addActivity?.comment,
  //                   completed: result?.data?.addActivity?.completed,
  //                   grade: result?.data?.addActivity?.grade,
  //                   intensity: result?.data?.addActivity?.intensity,
  //                   type: result?.data?.addActivity?.type,
  //                   achievement: result?.data?.addActivity?.achievement,
  //                 },
  //               ],
  //             },
  //           ],
  //         };
  //       }

  //       return {
  //         getTopRopeActivities: [
  //           ...(data?.getTopRopeActivities || []).map((day: any) => ({
  //             ...day,
  //             activities: [...day?.activities, result?.data?.addActivity],
  //           })),
  //         ],
  //       };
  //   }
  // };

  // const refetchQueryGoalData = (data: any, result: any) => {
  //   switch (result?.data?.addActivity.type) {
  //     case ClimbingType.BOULDER: {
  //       const boulderGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.BOULDER);
  //       const goalComplete = isGoalComplete({
  //         goal: boulderGoal,
  //         addActivity: result?.data?.addActivity,
  //       });
  //       if (goalComplete) {
  //         return {
  //           getGoal: [...(data.getGoal || []).filter((f: any) => f.type !== ClimbingType.BOULDER)],
  //         };
  //       }
  //       break;
  //     }
  //     case ClimbingType.LEAD: {
  //       const leadGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.LEAD);
  //       const goalComplete = isGoalComplete({
  //         goal: leadGoal,
  //         addActivity: result?.data?.addActivity,
  //       });
  //       if (goalComplete) {
  //         return {
  //           getGoal: [...(data?.getGoal || []).filter((f: any) => f.type !== ClimbingType.LEAD)],
  //         };
  //       }
  //       break;
  //     }
  //     case ClimbingType.TOP_ROPE: {
  //       const topRopeGoal = data?.getGoal?.find((f: any) => f.type === ClimbingType.TOP_ROPE);
  //       const goalComplete = isGoalComplete({
  //         goal: topRopeGoal,
  //         addActivity: result?.data?.addActivity,
  //       });
  //       if (goalComplete) {
  //         return {
  //           getGoal: [
  //             ...(data?.getGoal || []).filter((f: any) => f.type !== ClimbingType.TOP_ROPE),
  //           ],
  //         };
  //       }
  //       break;
  //     }
  //   }
  // };

  const [addActivity, { loading, error }] = useMutation(ADD_ACTIVITY, {
    refetchQueries: [GET_BOULDERING_ACTIVITIES, GET_LEAD_ACTIVITIES, GET_TOP_ROPE_ACTIVITIES],
    // update: (cache, result) => {
    //   cache.updateQuery(
    //     {
    //       query: refetchQueryDoc(result),
    //       variables: refetchActivitiesVariables,
    //     },
    //     (data) => refetchQueryClimbingData(data, result),
    //   );
    //   cache.updateQuery(
    //     {
    //       query: GET_GOAL,
    //       variables: { userId: cookies.signedInUser },
    //     },
    //     (data) => refetchQueryGoalData(data, result),
    //   );
    // },
  });

  const [completed, setComplete] = useState<boolean>(false);
  const [achievement, setAchievement] = useState<boolean>(false);

  const onSubmit: SubmitHandler<LogActivityFormInputs> = async (data) => {
    await addActivity({
      variables: {
        input: {
          userId: cookies.signedInUser,
          attempts: data.attempts,
          comment: data.comment?.trim(),
          completed: completed,
          achievement: achievement,
          date: data.date,
          grade: gradeToNumber({
            boulderingGradeType: cookies.boulderingGradePreference,
            ropeGradeType: cookies.ropeGradePreference,
            grade: data.grade,
            type: data.type,
          })?.toString(),
          intensity: data.intensity,
          type: data.type,
        },
      },
    }).then(async (result) => {
      const goalComplete = isGoalComplete({
        goal,
        addActivity: result.data.addActivity,
      });

      onSuccess(goalComplete, Boolean(result?.data?.addActivity?.achievement?.id));

      if (result?.data?.addActivity?.achievement?.id && achievement && imageForUpload) {
        new Compressor(imageForUpload, {
          quality: 1,
          maxWidth: 600,
          maxHeight: 900,
          success: async (compressedResult) => {
            await uploadFirebaseAchievementImage({
              achievementId: result.data.addActivity.achievement.id,
              file: compressedResult,
            });
          },
        });
      }
    });
  };

  return (
    <LogActivityFormComponent
      onSubmit={onSubmit}
      completed={completed}
      achievement={achievement}
      setImageForUpload={setImageForUpload}
      setComplete={setComplete}
      setAchievement={setAchievement}
      error={error}
      loading={loading}
      setCropperOpen={setCropperOpen}
      tab={tab}
    />
  );
};
