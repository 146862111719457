import { useState } from 'react';

import Compressor from 'compressorjs';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';

import { REMOVE_ACHIEVEMENT } from '../../../../api/mutation/removeAchievement';
import { UPDATE_ACHIEVEMENT_COMMENT } from '../../../../api/mutation/updateAchievementComment';
import { GET_ACHIEVEMENTS } from '../../../../api/query/getAchievements';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../../api/query/getCommunityAchievements';
import { IAchievementDetails } from '../../../../api/types/achievement';
import { achievementPictureUrl } from '../../../../constants/achievement-picture-url';
import { removeFirebaseImage } from '../../../../utils/remove-firebase-image';
import { uploadFirebaseAchievementImage } from '../../../../utils/upload-firebase-achievement-image';
import { UpdateAchievementFormSchema } from '../../../../validation/schemas/update-achievement-form';
import { UpdateAchievementFormInputs } from '../../../../validation/schemas/update-achievement-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { ModalHeader } from '../../../components/modal-header';
import { SubmitButton } from '../../../components/submit-button';
import { cardSubtitleFontStyles } from '../../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { ActivityImageUploader } from '../../activity-image-uploader';
import { ReportPostIcon } from '../report-post-icon';

interface IActivityProps {
  achievement: IAchievementDetails;
  refetchAchievementsVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
  profileView?: boolean;
  mainImage?: string;
  setMainImage: (url?: string) => void;
}

export const CreatorAchievementOptionsPopover: React.FC<IActivityProps> = ({
  achievement,
  refetchAchievementsVariables,
  profileView,
  mainImage,
  setMainImage,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const { register, handleSubmit } = useForm<UpdateAchievementFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(UpdateAchievementFormSchema),
  });

  const getQueryDoc = (profileView?: boolean) => {
    if (profileView) {
      return GET_ACHIEVEMENTS;
    }
    return GET_COMMUNITY_ACHIEVEMENTS;
  };

  const getResultData = (data: any, profileView?: boolean) => {
    if (profileView) {
      return {
        getAchievements: {
          data: [
            ...(data?.getAchievements?.data || []).filter(
              (item: IAchievementDetails) => item.id !== achievement.id,
            ),
          ],
          count: data?.getAchievements?.count - 1,
        },
      };
    }
    return {
      getCommunityAchievements: {
        data: [
          ...(data?.getCommunityAchievements?.data || []).filter(
            (item: IAchievementDetails) => item.id !== achievement.id,
          ),
        ],
        count: data?.getCommunityAchievements?.count - 1,
      },
    };
  };

  const getResultEditData = (data: any, result: any, profileView?: boolean) => {
    if (profileView) {
      return {
        getAchievements: {
          data: [
            ...(data?.getAchievements?.data || []).map((item: IAchievementDetails) => {
              if (item.id === result?.data?.updateAchievementComment?.id) {
                return {
                  ...item,
                  comment: result?.data?.updateAchievementComment?.comment,
                };
              } else {
                return item;
              }
            }),
          ],
          count: data?.getAchievements?.count,
        },
      };
    }
    return {
      getCommunityAchievements: {
        data: [
          ...(data?.getCommunityAchievements?.data || []).map((item: IAchievementDetails) => {
            if (item.id === achievement.id) {
              return {
                ...item,
                comment: result?.data?.updateAchievementComment?.comment,
              };
            } else {
              return item;
            }
          }),
        ],
        count: data?.getCommunityAchievements?.count,
      },
    };
  };

  const [removeAchievement, { loading, error, reset }] = useMutation(REMOVE_ACHIEVEMENT, {
    update: (cache) => {
      cache.updateQuery(
        {
          query: getQueryDoc(profileView),
          variables: refetchAchievementsVariables,
        },
        (data) => getResultData(data, profileView),
      );
    },
  });

  const [updateAchievementComment, { loading: loadingEdit, error: errorEdit }] = useMutation(
    UPDATE_ACHIEVEMENT_COMMENT,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: getQueryDoc(profileView),
            variables: refetchAchievementsVariables,
          },
          (data) => getResultEditData(data, result, profileView),
        );
      },
    },
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOnClickDelete = () => {
    setModalOpen(true);
    handleClosePopover();
  };

  const handleOnClickEdit = () => {
    setEditModalOpen(true);
    handleClosePopover();
  };

  const popoverId = Boolean(anchorEl) ? `achievement-options-popover-${achievement.id}` : undefined;

  const onCloseModal = () => {
    setModalOpen(false);
    reset();
  };

  const onCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const onSubmit: SubmitHandler<UpdateAchievementFormInputs> = (data) => {
    updateAchievementComment({
      variables: {
        id: achievement.id,
        userId: cookies.signedInUser,
        comment: data.comment?.trim(),
      },
    }).then(() => {
      onCloseEditModal();
    });
  };

  const handleConfirmDelete = () => {
    const image = achievementPictureUrl(achievement.id);
    if (image) {
      removeFirebaseImage({ url: image }).then(() => {
        removeAchievement({
          variables: { id: achievement.id, userId: cookies.signedInUser },
        }).then(() => onCloseModal());
      });
    } else {
      removeAchievement({
        variables: { id: achievement.id, userId: cookies.signedInUser },
      }).then(() => onCloseModal());
    }
  };

  const handleUploadImage = async (file: Blob, url: string) => {
    new Compressor(file, {
      quality: 1,
      maxWidth: 600,
      maxHeight: 900,
      success: async (compressedResult) => {
        await uploadFirebaseAchievementImage({
          achievementId: achievement.id,
          file: compressedResult,
        });
        setMainImage(url);
        handleClosePopover();
        onCloseModal();
      },
    });
  };

  const handleOnClickRemoveImage = () => {
    const image = achievementPictureUrl(achievement.id);
    if (image) {
      removeFirebaseImage({ url: image }).then(() => {
        setMainImage();
        handleClosePopover();
      });
    }
  };

  return (
    <>
      <Box>
        {achievement.reported && <ReportPostIcon achievementId={achievement.id} />}
        <IconButton onClick={handleClickPopover} sx={{ height: 35 }}>
          <MoreHorizIcon
            height={24}
            width={24}
            sx={{ ...selectIconStyles(colours), color: colours.defaultTextColour }}
          />
        </IconButton>
      </Box>
      <Dialog open={modalOpen} onClose={onCloseModal}>
        <ModalHeader onClose={onCloseModal} title={'Delete achievement'} />
        <DialogContent sx={{ padding: 0, minWidth: 275 }} dividers>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ ...cardTitleFontStyles, mb: 1 }}>
              Are you sure you want to delete this achievement?
            </Typography>
            <Typography sx={{ ...cardSubtitleFontStyles(colours), mb: 2 }}>
              You will lose all comments and likes, as well as the original activity.
            </Typography>
            <ErrorAlert showError={Boolean(error?.message)} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="inherit" onClick={onCloseModal}>
                Cancel
              </Button>
              <SubmitButton
                label={'Delete'}
                loading={loading}
                fullWidth={false}
                onClick={handleConfirmDelete}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={editModalOpen} onClose={onCloseEditModal}>
        <ModalHeader onClose={onCloseEditModal} title={'Update description'} />
        <DialogContent sx={{ padding: 0, minWidth: 300 }} dividers>
          <Box sx={{ p: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <TextField
                fullWidth
                id="comment"
                label="Description"
                variant="outlined"
                sx={{ mb: 2 }}
                multiline
                maxRows={4}
                {...register('comment')}
              />
              <ErrorAlert showError={Boolean(errorEdit?.message)} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" color="inherit" onClick={onCloseEditModal}>
                  Cancel
                </Button>
                <SubmitButton
                  label="Update"
                  loading={loadingEdit}
                  type="submit"
                  fullWidth={false}
                  variant="contained"
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {Boolean(achievement.user.id === cookies.signedInUser) && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickDelete}>Delete</ListItemButton>
            </ListItem>
          )}
          {Boolean(achievement.user.id === cookies.signedInUser) && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickEdit}>Edit</ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ActivityImageUploader
              label={mainImage ? 'Replace photo' : 'Add photo'}
              listItemButton
              saveCroppedImage={handleUploadImage}
            />
          </ListItem>
          {Boolean(mainImage) && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickRemoveImage}>Remove image</ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
