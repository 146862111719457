import React, { useState } from 'react';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box, Button, Card, Stack, Tab, Tabs } from '@mui/material';

import { AddCommunityMemberModal } from '../../component-library/page-layout/add-community-memeber-modal';
import { ManageCommunitycontent } from '../../component-library/page-layout/manage-community-content';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { MyCommunityTab } from './types';

export const ManageCommunityPage: React.FC = () => {
  const [tab, setTab] = useState<MyCommunityTab>(MyCommunityTab.community);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: MyCommunityTab) =>
    setTab(value);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box p={2} style={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <AddCommunityMemberModal onClose={() => setIsOpen(false)} isOpen={isOpen} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            fullWidth
            size="small"
            sx={{ mb: 2, minWidth: 0 }}
            variant="contained"
            endIcon={<GroupAddIcon />}
            onClick={() => setIsOpen(true)}
          >
            Add member
          </Button>
        </Box>
        <Card sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="Climbing type tabs"
            variant="fullWidth"
          >
            <Tab sx={{ p: 1 }} value={MyCommunityTab.community} label="Community" />
            <Tab sx={{ p: 1 }} value={MyCommunityTab.suggested} label="Suggested" />
            <Tab sx={{ p: 1 }} value={MyCommunityTab.requests} label="Requests" />
          </Tabs>
          <ManageCommunitycontent tab={tab} />
        </Card>
      </Box>
    </Stack>
  );
};
