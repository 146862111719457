import { gql } from '@apollo/client';

export const ADD_ACTIVITY = gql`
  mutation AddActivity($input: ActivityInput!) {
    addActivity(input: $input) {
      id
      attempts
      comment
      completed
      grade
      intensity
      type
      activityDay {
        id
        date
      }
      achievement {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
