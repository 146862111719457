import { gql } from '@apollo/client';

export const UPDATE_CLUB_DESCRIPTION = gql`
  mutation UpdateClubDescription($userId: String!, $lastName: String!) {
    updateClubDescription(userId: $userId, lastName: $lastName) {
      id
      email
      firstName
      lastName
      isClub
    }
  }
`;
