import { gql } from '@apollo/client';

export const BUDDY_SEARCH_UPDATE = gql`
  mutation BuddySearchUpdate($id: String!, $input: BuddySearchInput!) {
    buddySearchUpdate(id: $id, input: $input) {
      gym
      ability
      additionalInformation
      bouldering
      topRope
      lead
      monAm
      monPm
      tueAm
      tuePm
      wedAm
      wedPm
      thuAm
      thuPm
      friAm
      friPm
      satAm
      satPm
      sunAm
      sunPm
    }
  }
`;
