import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfile($userId: String!, $myUserId: String!) {
    getProfile(userId: $userId, myUserId: $myUserId) {
      blocked {
        id
      }
      highestBoulderClimb {
        id
        grade
        type
      }
      highestTopRopeClimb {
        id
        grade
        type
      }
      highestLeadClimb {
        id
        grade
        type
      }
      totalBoulderClimbNumber
      totalLeadClimbNumber
      totalTopRopeClimbNumber
      user {
        id
        email
        firstName
        lastName
        hideGoals
        hideSummary
        autoPostGoalDisabled
        isClub
        isClubPrivate
      }
      friend {
        id
      }
      friendRequest {
        id
      }
      sentFriendRequest {
        id
      }
      goals {
        id
        type
        active
        grade
        flash
        targetDate
        beginningDate
        completionDate
      }
    }
  }
`;
