import { gql } from '@apollo/client';

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings(
    $userId: String!
    $hideGoals: Boolean
    $hideSummary: Boolean
    $autoPostGoalDisabled: Boolean
    $boulderingGradeType: String
    $ropeGradeType: String
  ) {
    updateUserSettings(
      userId: $userId
      hideGoals: $hideGoals
      hideSummary: $hideSummary
      autoPostGoalDisabled: $autoPostGoalDisabled
      boulderingGradeType: $boulderingGradeType
      ropeGradeType: $ropeGradeType
    ) {
      id
    }
  }
`;
