import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Box, InputAdornment, TextField, Typography } from '@mui/material';

import { SEND_MESSAGE } from '../../../../api/mutation/sendMessage';
import { GET_MESSAGE_ROOMS } from '../../../../api/query/getMessageRooms';
import { GET_MESSAGES } from '../../../../api/query/getMessages';
import { IMessage } from '../../../../api/types/message';
import { IMessageRoom } from '../../../../api/types/message-room';
import { POLLING_INTERVAL } from '../../../../constants/polling-interval';
import { MessageFormSchema } from '../../../../validation/schemas/message-form';
import { MessageInputs } from '../../../../validation/schemas/message-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { IconSubmitButton } from '../../../components/icon-submit-button';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { Message } from '../message';

interface IMessageContentInnerProps {
  selectedMessage: IMessageRoom;
  closeMessage: () => void;
}

export const MessageContentInner: React.FC<IMessageContentInnerProps> = ({
  selectedMessage,
  closeMessage,
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<MessageInputs>({
    mode: 'onChange',
    resolver: yupResolver(MessageFormSchema),
  });

  const colour = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const getName = () => {
    return selectedMessage?.user1.id === cookies.signedInUser
      ? `${selectedMessage?.user2.firstName} ${selectedMessage?.user2.lastName}`
      : `${selectedMessage?.user1.firstName} ${selectedMessage?.user1.lastName}`;
  };

  const { data, loading, error, startPolling } = useQuery(GET_MESSAGES, {
    variables: { id: selectedMessage?.id },
  });

  useEffect(() => {
    startPolling(POLLING_INTERVAL);
  }, [startPolling]);

  const [sendMessage, { loading: sendMessageLoading, error: sendMessageError }] =
    useMutation(SEND_MESSAGE);

  const handleSendMessage = (formData: MessageInputs) => {
    sendMessage({
      variables: {
        id: selectedMessage?.id,
        senderId: cookies.signedInUser,
        message: formData.message?.trim(),
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_MESSAGES,
            variables: { id: selectedMessage?.id },
          },
          (data) => ({
            getMessages: [...(data.getMessages || []), result.data.sendMessage],
          }),
        );
        cache.updateQuery(
          {
            query: GET_MESSAGE_ROOMS,
            variables: { userId: cookies.signedInUser },
          },
          (data) => {
            const senderId = result?.data?.sendMessage?.sender?.id;
            const receiverId = result?.data?.sendMessage?.receiver?.id;
            const updatedMessageRoom = data?.getMessageRooms?.find(
              (f: any) =>
                (f.user1 === receiverId && f.user2 === senderId) ||
                (f.user1 === senderId && f.user2 === receiverId),
            );
            return {
              getMessageRooms: [
                { ...updatedMessageRoom, lastMessage: result.data.sendMessage.message },
                ...(data.getMessageRooms || []).filter(
                  (f: any) =>
                    !(f.user1 === receiverId && f.user2 === senderId) ||
                    !(f.user1 === senderId && f.user2 === receiverId),
                ),
              ],
            };
          },
        );
      },
    }).then(() => {
      setValue('message', '');
    });
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        p={2}
        sx={{
          backgroundColor: colour.midPrimaryBackground,
          color: colour.primaryInverseColour,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowBackIcon onClick={closeMessage} />
        <Box pl={2}>
          <Typography variant="h6" noWrap component="div">
            {getName()}
          </Typography>
          <Typography variant="subtitle2" noWrap component="div">
            {selectedMessage?.gym}
          </Typography>
        </Box>
      </Box>
      <Box
        p={2}
        pb={0}
        sx={{
          flex: 1,
          overflow: 'auto',
          bgcolor: colour.pageBackgroundColour,
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <ErrorAlert showError={Boolean(error)} />
        {loading && <LoadingSpinner label="Loading messages..." />}
        {(data?.getMessages
          ? [...data.getMessages].sort((a, b) => parseInt(b.id) - parseInt(a.id))
          : []
        ).map((message: IMessage) => (
          <Message
            key={message.id}
            name={`${message.sender.firstName} ${message.sender.lastName}`}
            message={message.message}
            myMessage={Boolean(message.sender.id === cookies.signedInUser)}
          />
        ))}
      </Box>
      <Box p={1} sx={{ bgcolor: colour.pageBackgroundColour }}>
        {sendMessageError && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="error">
            {sendMessageError.message}
          </Alert>
        )}
        <form onSubmit={handleSubmit(handleSendMessage)}>
          <TextField
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSubmit(handleSendMessage);
              }
            }}
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="ability"
            type="string"
            fullWidth
            error={Boolean(errors.message?.message)}
            helperText={errors?.message?.message}
            variant="outlined"
            sx={{ backgroundColor: '#fff', m: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconSubmitButton loading={sendMessageLoading} icon={<SendIcon />} />
                </InputAdornment>
              ),
            }}
            {...register('message')}
          />
        </form>
      </Box>
    </Box>
  );
};
