import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(fontGrade: string, vGrade: string, ukTechGrade: string) {
  return { fontGrade, vGrade, ukTechGrade };
}

const rows = [
  // Beginner
  createData('3', 'VB', '4a'),
  createData('3+', 'V0-', '4b'),
  createData('4', 'V0', '4c'),

  // Experienced
  createData('4+', 'V0+', '5a'),
  createData('5', 'V1', '5a / 5b'),
  createData('5+', 'V2', '5b'),

  // Advanced
  createData('6A', 'V2 / V3', '5c'),
  createData('6A+', 'V3', '6a'),
  createData('6B', 'V3 / V4', '6a / 6b'),
  createData('6B+', 'V4', '6b'),
  createData('6C', 'V5', '6b'),
  createData('6C+', 'V5 / V6', '6b / 6c'),

  // Expert
  createData('7A', 'V6', '6c'),
  createData('7A+', 'V7', '6c'),
  createData('7A+', 'V7', '6c'),
  createData('7B', 'V8', '6c / 7a'),
  createData('7B+', 'V7 / V8', '6c / 7a'),
  createData('7C', 'V9', '6c / 7a'),
  createData('7C+', 'V10', '7a'),

  // Elite
  createData('8A', 'V11', '7a'),
  createData('8A+', 'V12', '7a'),
  createData('8B', 'V13', '7a'),
  createData('8B+', 'V14', '7a'),
  createData('8C', 'V15', '7a'),
];

export const ClimbingGrades: React.FC = () => {
  return (
    <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
      <Table aria-label="font grade table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Font Grade</StyledTableCell>
            <StyledTableCell align="center">V Grade</StyledTableCell>
            <StyledTableCell align="center">UK Tech Grade</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="center">{row.fontGrade}</StyledTableCell>
              <StyledTableCell align="center">{row.vGrade}</StyledTableCell>
              <StyledTableCell align="center">{row.ukTechGrade}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
