import { gql } from '@apollo/client';

export const SEARCH_CLUBS = gql`
  query SearchClubs($userId: String!, $firstname: String!) {
    searchClubs(userId: $userId, firstname: $firstname) {
      id
      firstName
      lastName
      isClub
      isClubPrivate
    }
  }
`;
