import { gql } from '@apollo/client';

export const GET_BUDDY_SEARCH_BY_GYM = gql`
  query GetBuddySearchByGym($gym: String!) {
    getBuddySearchByGym(gym: $gym) {
      id
      gym
      ability
      additionalInformation
      bouldering
      topRope
      lead
      monAm
      monPm
      tueAm
      tuePm
      wedAm
      wedPm
      thuAm
      thuPm
      friAm
      friPm
      satAm
      satPm
      sunAm
      sunPm
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
