import React, { useMemo, useState } from 'react';

import { Box, Stack, Tab, Tabs } from '@mui/material';

import { useColours } from '../../component-library/utils/use-colours';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ClubsCommunityFeed } from './clubs-community-feed';
import { MyCommunityFeed } from './my-community-feed';
import { CommunityType } from './types';

export const CommunityPage: React.FC = () => {
  const colours = useColours();
  const [tab, setTab] = useState<CommunityType>(CommunityType.COMMUNITY);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: CommunityType) =>
    setTab(value);

  const PageContentByTab: JSX.Element = useMemo(() => {
    switch (tab) {
      case CommunityType.COMMUNITY:
        return <MyCommunityFeed />;
      case CommunityType.CLUBS:
        return <ClubsCommunityFeed />;
    }
  }, [tab]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
        sx={{
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
          m: 'auto',
        }}
      >
        <Tabs
          value={tab}
          sx={{ width: '100%', mb: 2 }}
          onChange={handleTabChange}
          aria-label="Planner tabs"
          variant="fullWidth"
        >
          <Tab value={CommunityType.COMMUNITY} label="Community" />
          <Tab value={CommunityType.CLUBS} label="Clubs" />
        </Tabs>
        {PageContentByTab}
      </Stack>
    </Box>
  );
};
