import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Box, Card, Icon, InputAdornment, Stack, TextField, Typography } from '@mui/material';

import { COMMENT_PLAN } from '../../../../api/mutation/commentPlan';
import { GET_COMMUNITY_PLANS } from '../../../../api/query/getCommunityPlans';
import { GET_PLAN_COMMENTS } from '../../../../api/query/getPlanComments';
import { IComment } from '../../../../api/types/comment';
import { IPlannerDetails } from '../../../../api/types/planner';
import { RoutePath } from '../../../../constants';
import { MessageFormSchema } from '../../../../validation/schemas/message-form';
import { MessageInputs } from '../../../../validation/schemas/message-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { IconSubmitButton } from '../../../components/icon-submit-button';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { ProfilePicture } from '../../../components/profile-picture';
import { ReportedComment } from '../../../components/reported-comment';
import { RouterLink } from '../../../components/router-link';
import { SubmitButton } from '../../../components/submit-button';
import { cardSmallSubtitleFontStyles } from '../../../styles/card-small-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { PlanComment } from './plans-comment';
import { PlanCommentOptionsPopover } from './plans-comment-options-popover';

interface IPlanCommentsProps {
  plan: IPlannerDetails;
  getPlannerVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
}

export const PlanComments: React.FC<IPlanCommentsProps> = ({ plan, getPlannerVariables }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 5,
    offset: 0,
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<MessageInputs>({
    mode: 'onChange',
    resolver: yupResolver(MessageFormSchema),
  });

  const getPlanCommentsVariables = {
    id: plan?.id,
    userId: cookies.signedInUser,
    limit: paginationValues.limit,
    offset: paginationValues.offset,
  };

  const getResultData = (data: any) => {
    const mapper = (item: IPlannerDetails) => {
      if (item.id === plan?.id) {
        return {
          ...item,
          commentsLength: (item?.commentsLength || 0) + 1,
        };
      }
      return item;
    };

    return {
      getCommunityPlans: {
        data: [...(data?.getCommunityPlans?.data || []).map(mapper)],
        count: data?.getCommunityPlans?.count + 1,
      },
    };
  };

  const { loading, error, data, fetchMore } = useQuery(GET_PLAN_COMMENTS, {
    variables: getPlanCommentsVariables,
    fetchPolicy: 'network-only',
  });

  const [commentPlan] = useMutation(COMMENT_PLAN, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_PLAN_COMMENTS,
          variables: getPlanCommentsVariables,
        },
        (data) => ({
          getPlanComments: [
            { ...result?.data?.commentPlan, reported: false },
            ...(data?.getPlanComments || []),
          ],
        }),
      );
      cache.updateQuery(
        {
          query: GET_COMMUNITY_PLANS,
          variables: getPlannerVariables,
        },
        (data) => getResultData(data),
      );
    },
  });

  const handleCommentPlan = (formData: MessageInputs) => {
    commentPlan({
      variables: {
        id: plan?.id,
        comment: formData.message?.trim(),
        userId: cookies.signedInUser,
      },
    }).then(() => {
      setValue('message', '');
    });
  };

  const comments: IComment[] | undefined = data?.getPlanComments;

  return (
    <>
      <Box
        pl={2}
        pr={2}
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: !comments?.length ? 'center' : 'inherit',
          backgroundColor: colours.elevatedComponentColour,
          borderBottomLeftRadius: '0.8em',
          borderBottomRightRadius: '0.8em',
        }}
      >
        <form onSubmit={handleSubmit(handleCommentPlan)}>
          <TextField
            onKeyPress={(ev) => {}}
            autoFocus
            multiline
            maxRows={4}
            margin="dense"
            id="ability"
            type="string"
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: colours.pageBackgroundColour,
              mb: 2,
              borderRadius: 1,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconSubmitButton
                    loading={false}
                    icon={<SendIcon sx={{ color: colours.primaryThemeColour }} />}
                  />
                </InputAdornment>
              ),
            }}
            {...register('message')}
          />
        </form>
        <ErrorAlert showError={Boolean(error)} />
        {Boolean(loading) && <LoadingSpinner sx={{ mb: 2 }} label={'Loading comments....'} />}
        {Boolean(!errors?.message && !loading && !comments?.length) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <Typography>{`There are no comments on this plan yet!`}</Typography>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
              <Icon>
                <SentimentDissatisfiedIcon />
              </Icon>
            </Typography>
          </Box>
        )}
        {comments?.map((comment: IComment) => (
          <Box
            key={comment?.id}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Card
              sx={{
                width: '100%',
                marginBottom: 2,
                backgroundColor: colours.pageBackgroundColour,
              }}
            >
              <Stack p={1} direction={'column'} alignItems="flex-start">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: 25, width: 25, mr: 1, flexShrink: 0 }}>
                      <ProfilePicture thumbnail userId={comment.user.id} navigable />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <RouterLink href={`${RoutePath.PROFILE}${comment.user.id}`}>
                        <Typography sx={cardTitleFontStyles}>
                          {getNameFromUser(comment.user)}
                        </Typography>
                      </RouterLink>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ ...cardSmallSubtitleFontStyles(colours), pl: 2 }}>
                      {getDateSinceNowDisplayText(comment?.date)}
                    </Typography>
                    {comment?.user?.id === cookies.signedInUser && (
                      <PlanCommentOptionsPopover
                        comment={comment}
                        getPlanCommentsVariables={getPlanCommentsVariables}
                        getPlanVariables={getPlannerVariables}
                        planId={plan.id}
                      />
                    )}
                  </Box>
                </Box>
                {comment.reported ? (
                  <ReportedComment comment={comment.comment} />
                ) : (
                  <PlanComment comment={comment.comment} />
                )}
              </Stack>
            </Card>
          </Box>
        ))}
        {Boolean(
          data?.getPlanComments?.length > 0 &&
            data?.getPlanComments?.length < (plan?.commentsLength || 0),
        ) && (
          <SubmitButton
            sx={{ mb: 2 }}
            variant="outlined"
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getPlanComments.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getPlanComments: [
                      ...previousResult.getPlanComments,
                      ...fetchMoreResult.getPlanComments,
                    ],
                  };
                },
              })
            }
          />
        )}
      </Box>
    </>
  );
};
