import React, { useState } from 'react';

import { Box, Dialog } from '@mui/material';

import { IAchievementDetails } from '../../../api/types/achievement';
import { ClimbingType, IActivityDay, IActivityDetails } from '../../../pages/my-progress/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { ActivityDetailsModalContent } from './details';
import { ActivityDetailsModalList } from './list';

interface IActivityDetailsModalProps {
  refetchActivitiesVariables: {
    userId: string;
    type: ClimbingType;
    limit: number;
    offset: number;
  };
  activityDay?: IActivityDay;
  goal?: IGoalDetails;
  onClose: () => void;
  updateAchievementIdInActivityDay: ({
    achievementId,
    activityId,
  }: {
    achievementId: string;
    activityId: string;
  }) => void;
  removeActivityFromDay: (id: string) => void;
}

export const ActivityDetailsModal: React.FC<IActivityDetailsModalProps> = ({
  activityDay,
  refetchActivitiesVariables,
  goal,
  onClose,
  updateAchievementIdInActivityDay,
  removeActivityFromDay,
}) => {
  const [selectedActivity, setSelectedActivity] = useState<IActivityDetails | undefined>(undefined);

  const handleClose = () => {
    setSelectedActivity(undefined);
    onClose();
  };

  const updateAchievementId = (achievementId: string) => {
    if (selectedActivity) {
      setSelectedActivity({
        ...(selectedActivity as IActivityDetails),
        achievement: { id: achievementId } as IAchievementDetails,
      });
      updateAchievementIdInActivityDay({ achievementId, activityId: selectedActivity.id });
    }
  };

  return (
    <Dialog open={Boolean(activityDay)} onClose={handleClose}>
      <Box sx={{ minWidth: '300px' }}>
        {selectedActivity ? (
          <ActivityDetailsModalContent
            refetchActivitiesVariables={refetchActivitiesVariables}
            activity={selectedActivity}
            goal={goal}
            updateAchievementId={updateAchievementId}
            back={() => setSelectedActivity(undefined)}
            removeActivityFromDay={removeActivityFromDay}
          />
        ) : (
          <ActivityDetailsModalList
            activityDay={activityDay}
            onSelect={(activity) => setSelectedActivity(activity)}
            onClose={handleClose}
          />
        )}
      </Box>
    </Dialog>
  );
};
