import React, { useCallback, useState } from 'react';

import Cropper, { Area } from 'react-easy-crop';

import { Box, Button, Input, InputLabel, ListItemButton, TextField } from '@mui/material';

import { cardBodyFontStyles } from '../../styles/card-font-styles';
import { useColours } from '../../utils/use-colours';
import getCroppedImg from './getCroppedImage';

interface IActivityImageUploaderProps {
  saveCroppedImage: (file: Blob, url: string) => void;
  setCropperOpen?: (isOpen: boolean) => void;
  listItemButton?: boolean;
  label: string;
}

export const ActivityImageUploader: React.FC<IActivityImageUploaderProps> = ({
  saveCroppedImage,
  setCropperOpen = () => null,
  listItemButton,
  label,
}) => {
  const colours = useColours();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [newImageDimensions, setNewImageDimensions] = useState<{
    croppedArea: Area;
    croppedAreaPixels: Area;
  }>();
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleSetPreview = (value?: string) => {
    setPreview(value);
    setCropperOpen(Boolean(value));
  };

  const handleClose = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setNewImageDimensions(undefined);
    handleSetPreview(undefined);
  };

  const handleChange = (e: any) => {
    if (e.target.files[0]) {
      updatePreview(e.target.files[0]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpload = (uploadFile: Blob, url: string) => {
    saveCroppedImage(uploadFile, url);
    handleClose();
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setNewImageDimensions({ croppedArea, croppedAreaPixels });
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      if (!preview || !newImageDimensions) {
        return;
      }
      const croppedImage = await getCroppedImg(preview, newImageDimensions.croppedAreaPixels, 0);
      if (croppedImage) {
        handleUpload(croppedImage?.file, croppedImage.url);
      }
    } catch (e) {
      console.error(e);
    }
  }, [handleUpload, newImageDimensions, preview]);

  const updatePreview = (file: File) => {
    if (!file) {
      handleSetPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    handleSetPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  };

  return (
    <>
      {listItemButton ? (
        <ListItemButton>
          <InputLabel htmlFor="image-selection">{label}</InputLabel>
          <Input
            id="image-selection"
            type="file"
            onChange={handleChange}
            fullWidth
            sx={{ ...cardBodyFontStyles(colours), display: 'none' }}
          />
        </ListItemButton>
      ) : (
        <TextField
          margin="dense"
          id="image"
          label={label}
          InputLabelProps={{ shrink: true }}
          type="file"
          fullWidth
          variant="outlined"
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />
      )}
      {Boolean(preview) && (
        <Box
          sx={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            zIndex: 10,
          }}
        >
          <Cropper
            image={preview}
            crop={crop}
            zoom={zoom}
            aspect={2 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <Button
            onClick={showCroppedImage}
            sx={{ marginLeft: 3, marginTop: 3 }}
            variant="contained"
          >
            Save Image
          </Button>
          <Button
            onClick={handleClose}
            sx={{ marginLeft: 3, marginTop: 3 }}
            color="inherit"
            variant="contained"
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};
