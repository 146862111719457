import * as Yup from 'yup';

import { yupFirstNameValidation, yupLastNameValidation } from '../../yup';
import { UpdatePersonalDetailsFormInputs } from './types';

export const UpdatePersonalDetailsFormSchema: Yup.SchemaOf<UpdatePersonalDetailsFormInputs> =
  Yup.object({
    firstName: yupFirstNameValidation,
    lastName: yupLastNameValidation,
  });
