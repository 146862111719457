import {
  TFontScaleBoulderingGrades,
  TVScaleBoulderingGrades,
} from '../../../../constants/bouldering-grades';

export const vScaleBoulderingGradeToNumber = (grade: TVScaleBoulderingGrades): number => {
  switch (grade) {
    case 'VB':
      return 1;
    case 'V0-':
      return 2;
    case 'V0':
      return 3;
    case 'V0+':
      return 4;
    case 'V1':
      return 5;
    case 'V2':
      return 6;
    case 'V3':
      return 7;
    case 'V4':
      return 8;
    case 'V5':
      return 9;
    case 'V6':
      return 10;
    case 'V7':
      return 11;
    case 'V8':
      return 12;
    case 'V9':
      return 13;
    case 'V10':
      return 14;
    case 'V11':
      return 15;
    case 'V12':
      return 16;
    case 'V13':
      return 17;
    case 'V14':
      return 18;
    case 'V15':
      return 19;
    case 'V16':
      return 20;
    case 'V17':
      return 21;
  }
};

export const fontScaleBoulderingGradeToNumber = (grade: TFontScaleBoulderingGrades): number => {
  switch (grade) {
    case '4':
      return 1;
    case '5':
      return 2;
    case '5+':
      return 3;
    case '6A':
      return 4;
    case '6A+':
      return 5;
    case '6B':
      return 6;
    case '6B+':
      return 7;
    case '6C':
      return 8;
    case '6C+':
      return 9;
    case '7A':
      return 10;
    case '7A+':
      return 11;
    case '7B':
      return 12;
    case '7B+':
      return 13;
    case '7C':
      return 14;
    case '7C+':
      return 15;
    case '8A':
      return 16;
    case '8A+':
      return 17;
    case '8B':
      return 18;
    case '8B+':
      return 19;
    case '8C+':
      return 20;
    case '9A':
      return 21;
  }
};
