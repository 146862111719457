import { gql } from '@apollo/client';

export const GET_CLUB_LIST = gql`
  query GetClubList($userId: String!, $limit: Int!, $offset: Int!) {
    getClubList(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        user {
          id
          email
          firstName
          lastName
          isClub
          isClubPrivate
        }
      }
      count
    }
  }
`;
