import { IActivityDetails } from '../pages/my-progress/types';
import { IGoalDetails } from '../validation/schemas/set-goal-form/types';

export const isGoalComplete = ({
  goal,
  addActivity,
}: {
  goal?: IGoalDetails;
  addActivity?: IActivityDetails;
}) => {
  if (goal) {
    if (addActivity?.completed) {
      if (parseInt(addActivity?.grade) >= parseInt(goal.grade)) {
        if (!goal.flash || (goal.flash && addActivity?.attempts === 1)) {
          return true;
        }
      }
    }
  }

  return false;
};
