import React from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { ModalHeader } from '../../components/modal-header';
import { LogPlanForm } from '../log-plan-form';

interface ILogPlanModalProps {
  refetchPlannerVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const LogPlanModal: React.FC<ILogPlanModalProps> = ({
  refetchPlannerVariables,
  isOpen,
  onClose,
  onSuccess,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title={'Log a plan'} />
      <DialogContent sx={{ padding: 2 }} dividers>
        <LogPlanForm onSuccess={onSuccess} refetchPlannerVariables={refetchPlannerVariables} />
      </DialogContent>
    </Dialog>
  );
};
