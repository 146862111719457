import { EBoulderingGradeType, ERopeGradeType } from '../../../../constants/grade-types';
import { ClimbingType } from '../../../../pages/my-progress/types';
import {
  numberToFontScaleBoulderingGrade,
  numberToVScaleBoulderingGrade,
} from './number-to-bouldering-grade';
import { numberToFrenchRopeGrade, numberToYDSRopeGrade } from './number-to-rope-grade';

export const numberToGrade = ({
  boulderingGradeType,
  ropeGradeType,
  grade,
  type,
}: {
  grade?: string;
  type?: string;
  boulderingGradeType: EBoulderingGradeType;
  ropeGradeType: ERopeGradeType;
}) => {
  if (!grade || !type) {
    return '';
  }

  if (type === ClimbingType.BOULDER) {
    if (boulderingGradeType === EBoulderingGradeType.FONT_SCALE) {
      return numberToFontScaleBoulderingGrade(parseInt(grade));
    }
    // Default should be VScale
    return numberToVScaleBoulderingGrade(parseInt(grade));
  }

  if (ropeGradeType === ERopeGradeType.YDS) {
    return numberToYDSRopeGrade(parseInt(grade));
  }

  // Default should be French
  return numberToFrenchRopeGrade(parseInt(grade));
};
