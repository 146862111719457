import * as React from 'react';

import { ApolloError } from '@apollo/client';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Alert, Box, Icon as MuiIcon, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IMessageRequest } from '../../../../api/types/message-request';
import { COMPONENT_HEIGHTS } from '../../../../constants/component-dimentions';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { MessageSummary } from '../message-summary';

interface IRequestsProps {
  requests?: IMessageRequest[];
  handleSetSelectedRequestId: (id: string) => void;
  messageRequestsLoading: boolean;
  requestsOpen: boolean;
  setRequestsOpen: (value: boolean) => void;
  closeMessageList: () => void;
  messageRequestsError?: ApolloError | undefined;
}

export const Requests: React.FC<IRequestsProps> = ({
  requests,
  handleSetSelectedRequestId,
  messageRequestsLoading,
  messageRequestsError,
  requestsOpen,
  setRequestsOpen,
  closeMessageList,
}) => {
  const handleSetRequestsOpen = () => {
    if (!requestsOpen) {
      closeMessageList();
    }
    setRequestsOpen(!requestsOpen);
  };

  const numberOfMessages = requests?.length;

  const Icon = () => {
    switch (numberOfMessages) {
      case undefined:
      case 0:
        return <FilterNoneIcon />;
      case 1:
        return <Filter1Icon color="primary" />;
      case 2:
        return <Filter2Icon color="primary" />;
      case 3:
        return <Filter3Icon color="primary" />;
      case 4:
        return <Filter4Icon color="primary" />;
      case 5:
        return <Filter5Icon color="primary" />;
      case 6:
        return <Filter6Icon color="primary" />;
      case 7:
        return <Filter7Icon color="primary" />;
      case 8:
        return <Filter8Icon color="primary" />;
      case 9:
        return <Filter9Icon color="primary" />;
      default:
        return <Filter9PlusIcon color="primary" />;
    }
  };

  const RenderResultInfo = () => {
    if (messageRequestsLoading) {
      return <LoadingSpinner sx={{ mt: 2 }} label="Loading Requests..." />;
    }

    if (messageRequestsError) {
      return (
        <Alert sx={{ width: '100%' }} severity="error">
          {messageRequestsError.message}
        </Alert>
      );
    }

    if (!requests?.length) {
      return (
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{'No Message Requests! :('}</Typography>
          <Typography>
            <MuiIcon>
              <SentimentDissatisfiedIcon />
            </MuiIcon>
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <ListItemButton onClick={handleSetRequestsOpen}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary="Requests" />
        {requestsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={requestsOpen}
        timeout="auto"
        unmountOnExit
        sx={{
          maxHeight: `calc(${window.innerHeight}px - ${COMPONENT_HEIGHTS.HEADER} - 120px)`,
          overflowY: 'auto',
        }}
      >
        <RenderResultInfo />
        {requests?.map((data) => (
          <MessageSummary
            key={data.id}
            id={data.id}
            userId={data.requester.id}
            isNewMessage={true}
            onClick={handleSetSelectedRequestId}
            name={getNameFromUser(data.requester)}
            message={data.message}
          />
        ))}
      </Collapse>
    </>
  );
};
