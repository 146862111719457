import { useEffect } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

import {
  fontScaleBoulderingGrades,
  vScaleBoulderingGrades,
} from '../../../../constants/bouldering-grades';
import { EBoulderingGradeType, ERopeGradeType } from '../../../../constants/grade-types';
import { frenchRopeGrades, ydsRopeGrades } from '../../../../constants/rope-grades';
import { ClimbingType } from '../../../../pages/my-progress/types';
import { LogActivityFormFormSchema } from '../../../../validation/schemas/log-activity-form';
import { LogActivityFormInputs } from '../../../../validation/schemas/log-activity-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { SubmitButton } from '../../../components/submit-button';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { ActivityImageUploader } from '../../activity-image-uploader';

interface ILogActivityFormComponentProps {
  onSubmit: SubmitHandler<LogActivityFormInputs>;
  completed: boolean;
  achievement: boolean;
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setAchievement: React.Dispatch<React.SetStateAction<boolean>>;
  setImageForUpload: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  tab?: ClimbingType;
  error: ApolloError | undefined;
  loading: boolean;
  setCropperOpen: (isOpen: boolean) => void;
  communityForm?: boolean;
}

export const LogActivityFormComponent = ({
  onSubmit,
  achievement,
  completed,
  setImageForUpload,
  setAchievement,
  setComplete,
  error,
  loading,
  setCropperOpen,
  tab = ClimbingType.BOULDER,
  communityForm = false,
}: ILogActivityFormComponentProps) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LogActivityFormInputs>({
    mode: 'onChange',
    defaultValues: {
      type: tab,
      grade: '',
      date: new Date(),
      completed: false,
      achievement: false,
      intensity: 5,
      attempts: 1,
    },
    resolver: yupResolver(LogActivityFormFormSchema),
  });

  const activityType = watch('type');
  const bouldering = activityType === ClimbingType.BOULDER;

  useEffect(() => {
    setValue('grade', '');
  }, [activityType, setValue]);

  const grades = () => {
    if (activityType) {
      if (bouldering) {
        if (cookies.boulderingGradePreference === EBoulderingGradeType.FONT_SCALE) {
          return fontScaleBoulderingGrades;
        }
        return vScaleBoulderingGrades;
      }

      if (cookies.ropeGradePreference === ERopeGradeType.YDS) {
        return ydsRopeGrades;
      }
      return frenchRopeGrades;
    }

    return ['Please select a climbing type first...'];
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Climbing type"
            fullWidth
            error={Boolean(errors.type?.message)}
            helperText={errors.type?.message}
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
          >
            <MenuItem value={ClimbingType.BOULDER}>Bouldering</MenuItem>
            <MenuItem value={ClimbingType.TOP_ROPE}>Top Rope</MenuItem>
            <MenuItem value={ClimbingType.LEAD}>Lead</MenuItem>
          </TextField>
        )}
      />
      <Controller
        name="grade"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Grade"
            fullWidth
            error={Boolean(errors.grade?.message)}
            helperText={errors.grade?.message}
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
          >
            {grades().map((value) => (
              <MenuItem
                key={value}
                value={value}
                disabled={value === 'Please select a climbing type first...'}
              >
                {value}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <FormControl error={Boolean(errors.completed?.message)} sx={{ width: '100%' }}>
        <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={
              <Switch name="completed" value={completed} onClick={() => setComplete(!completed)} />
            }
            label="Completed"
          />
        </FormGroup>
      </FormControl>
      {!communityForm && (
        <FormControl error={Boolean(errors.achievement?.message)} sx={{ width: '100%' }}>
          <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Switch
                  name="achievement"
                  value={achievement}
                  disabled={communityForm}
                  onClick={() => setAchievement(!achievement)}
                />
              }
              label="Share with community"
            />
          </FormGroup>
        </FormControl>
      )}
      <Controller
        name="attempts"
        control={control}
        defaultValue={5}
        render={() => (
          <Box p={1}>
            <Typography sx={{ mb: 0 }} id="track-inverted-slider" gutterBottom>
              Attempts
            </Typography>
            <Slider
              onChange={(_, value) => setValue('attempts', value as number)}
              valueLabelDisplay="off"
              max={5}
              min={1}
              step={1}
              defaultValue={1}
              marks={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 },
                { label: '5+', value: 5 },
              ]}
            />
          </Box>
        )}
      />
      <Controller
        name="intensity"
        control={control}
        defaultValue={5}
        render={() => (
          <Box p={1}>
            <Typography sx={{ mb: 0 }} id="track-inverted-slider" gutterBottom>
              Intensity
            </Typography>
            <Slider
              onChange={(_, value) => setValue('intensity', value as number)}
              valueLabelDisplay="auto"
              max={10}
              step={1}
              defaultValue={5}
              marks={true}
            />
          </Box>
        )}
      />
      <TextField
        multiline
        minRows={2}
        margin="dense"
        id="comment"
        label="Comment (optional)"
        type="string"
        fullWidth
        variant="outlined"
        error={Boolean(errors.comment?.message)}
        helperText={errors.comment?.message}
        sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
        {...register('comment')}
      />
      {Boolean(achievement && !communityForm) && (
        <ActivityImageUploader
          label={'Image (optional)'}
          setCropperOpen={setCropperOpen}
          saveCroppedImage={(file) => setImageForUpload(file)}
        />
      )}
      <Controller
        control={control}
        name="date"
        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
          <MobileDatePicker
            {...field}
            inputRef={ref}
            label="Date"
            inputFormat="dd/MM/yyyy"
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                onBlur={onBlur}
                name={name}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
              />
            )}
          />
        )}
      />
      <ErrorAlert showError={Boolean(error?.message)} />
      <SubmitButton loading={loading} label={'Save climb'} />
    </form>
  );
};
