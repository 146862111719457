import React, { useState } from 'react';

import { HashLink } from 'react-router-hash-link';

import { useQuery } from '@apollo/client';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import { GET_COMMUNITY_ACHIEVEMENTS } from '../../api/query/getCommunityAchievements';
import { IAchievementDetails } from '../../api/types/achievement';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { SubmitButton } from '../../component-library/components/submit-button';
import { AchievementPost } from '../../component-library/page-layout/achievement-post';
import { cardTitleFontStyles } from '../../component-library/styles/card-title-font-styles';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { useIsMobile } from '../../component-library/utils/use-is-mobile';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';

interface IClubCommunityPageProps {
  clubId: string;
}

export const ClubCommunityPage: React.FC<IClubCommunityPageProps> = ({ clubId }) => {
  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_COMMUNITY_ACHIEVEMENTS, {
    variables: {
      userId: clubId,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  const isMobile = useIsMobile();

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading clubs community feed...'}
      />
    );
  }

  return (
    <Box
      id="head-of-community-page"
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
          m: 'auto',
          backgroundColor: colours.pageBackgroundColour,
        }}
      >
        <Box
          sx={{
            pb: 2,
            marginBottom: '0.8rem',
            borderBottomRadius: isMobile ? 0 : '0.8rem',
            boxShadow: '0 0 0 1px rgb(0 0 0 / 15%), 0 2px 3px rgb(0 0 0 / 20%)',
            backgroundColor: colours.elevatedComponentColour,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItem: 'center',
            pt: 2,
          }}
        >
          <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center', ml: 2 }}>
            Club community feed
          </Typography>
          <Button
            size="small"
            sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
            variant="contained"
            color="primary"
            onClick={() =>
              refetch({
                userId: cookies.signedInUser,
                limit: paginationValues.limit,
                offset: paginationValues.offset,
              })
            }
          >
            <ReplayIcon />
          </Button>
        </Box>
        {error && (
          <Box
            sx={{
              margin: 'auto',
              justifyContent: 'center',
              width: '100%',
              p: 2,
              pb: 0,
            }}
          >
            <Alert sx={{ width: '100%' }} severity="error">
              Oops! Something went wrong!
            </Alert>
          </Box>
        )}
        {data?.getCommunityAchievements?.data && !data?.getCommunityAchievements.data?.length && (
          <Box
            sx={{
              margin: 'auto',
              justifyContent: 'center',
              width: '100%',
              p: 2,
              pb: 0,
            }}
          >
            <Alert sx={{ width: '100%' }} severity="info">
              {'Nobody in this club has posted any achievements!'}
            </Alert>
          </Box>
        )}
        {data?.getCommunityAchievements?.data?.map((achievement: IAchievementDetails) => (
          <React.Fragment key={achievement.id}>
            {/* {Boolean(idx !== 0 && idx % 3 === 0) && <Adsense dataAdSlot="9072317848" />} */}
            <AchievementPost
              achievement={achievement}
              key={achievement.id}
              refetchAchievementsVariables={{
                userId: cookies.signedInUser,
                limit: paginationValues.limit,
                offset: paginationValues.offset,
              }}
            />
          </React.Fragment>
        ))}
        {Boolean(
          data?.getCommunityAchievements?.data?.length > 0 &&
            data?.getCommunityAchievements?.data?.length < data?.getCommunityAchievements?.count,
        ) && (
          <SubmitButton
            sx={{ mb: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getCommunityAchievements?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getCommunityAchievements: {
                      __typename: 'GetCommunityAchievementsResult',
                      data: [
                        ...previousResult.getCommunityAchievements.data,
                        ...fetchMoreResult.getCommunityAchievements.data,
                      ],
                      count: fetchMoreResult.getCommunityAchievements.count,
                    },
                  };
                },
              })
            }
          />
        )}
        {data?.getCommunityAchievements?.data?.length > 5 && (
          <HashLink to="#head-of-community-page" smooth style={{ textDecoration: 'none' }}>
            <Typography sx={{ color: colours.primaryThemeColour, mb: 1 }}>Back to top</Typography>
          </HashLink>
        )}
      </Stack>
    </Box>
  );
};
