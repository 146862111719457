import { gql } from '@apollo/client';

export const GET_PLAN = gql`
  query GetPlan($planId: String!, $userId: String!) {
    getPlan(planId: $planId, userId: $userId) {
      id
      location
      datePosted
      planDateTime
      user {
        id
        firstName
        lastName
      }
      commentsLength
      joinsLength
      joinFilled
    }
  }
`;
