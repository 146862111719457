import { SxProps, Theme } from '@mui/material';

import { IColours } from '../../../../../constants/colours';

export const getGradeStyles = (
  colours: IColours,
  goal: string = '0',
  grade: string = '0',
): SxProps<Theme> => {
  const diff = parseInt(goal) - parseInt(grade);

  if (diff === undefined) {
    return { backgroundColor: colours.primaryThemeColour };
  }
  if (diff > 3) {
    return { backgroundColor: colours.lighterPrimaryBackground, color: 'black' };
  }
  if (diff === 3) {
    return { backgroundColor: colours.lightPrimaryBackground, color: 'black' };
  }
  if (diff === 2) {
    return { backgroundColor: colours.midPrimaryBackground };
  }
  if (diff === 1) {
    return { backgroundColor: colours.highPrimaryBackground };
  }
  if (diff <= 0) {
    return { backgroundColor: colours.primaryThemeColour };
  }
  return {};
};
