import { useEffect, useState } from 'react';

import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useNavigate } from 'react-router';

import { Avatar } from '@mui/material';
import { Box } from '@mui/system';

import { RoutePath } from '../../../constants';
import { profilePictureThumbnailUrl } from '../../../constants/profile-picture-thumbnail-url';
import { profilePictureUrl } from '../../../constants/profile-picture-url';
import { LoadingSpinner } from '../loading-spinner';

interface IProfilePictureProps {
  userId?: string;
  profilePicUrl?: string;
  navigable?: boolean;
  thumbnail?: boolean;
}

export const ProfilePicture: React.FC<IProfilePictureProps> = ({
  userId,
  profilePicUrl,
  navigable,
  thumbnail,
}) => {
  const [localProfilePic, setLocalProfilePic] = useState<string | undefined>();
  const [loadingImage, setLoadingImage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const storage = getStorage();
      const pathReference = ref(
        storage,
        thumbnail ? profilePictureThumbnailUrl(userId) : profilePictureUrl(userId),
      );

      if (pathReference) {
        setLoadingImage(true);
        getDownloadURL(pathReference)
          .then((url) => {
            setLocalProfilePic(url);
          })
          .catch((error) => {
            switch (error.code) {
              case 'storage/object-not-found':
                // File doesn't exist
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          })
          .finally(() => {
            setLoadingImage(false);
          });
      }
    }
  }, [thumbnail, userId]);

  useEffect(() => {
    if (profilePicUrl) {
      setLocalProfilePic(profilePicUrl);
    }
  }, [profilePicUrl]);

  const handleClick = () => {
    if (navigable) {
      navigate(`${RoutePath.PROFILE}${userId}`);
    }
  };

  if (loadingImage) {
    return (
      <LoadingSpinner
        size={thumbnail ? 20 : undefined}
        sx={{
          height: '100%',
          width: '100%',
        }}
      />
    );
  }

  if (localProfilePic) {
    return (
      <Box
        onClick={handleClick}
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: 100,
          backgroundImage: `url(${localProfilePic})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  }

  return (
    <Avatar
      onClick={handleClick}
      src="/static/images/avatar/1.jpg"
      sx={{ width: '100%', height: '100%' }}
    />
  );
};
