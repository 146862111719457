import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation Mutation($id: String!, $senderId: String!, $message: String!) {
    sendMessage(id: $id, senderId: $senderId, message: $message) {
      id
      message
      sender {
        id
        email
        firstName
        lastName
      }
      receiver {
        id
      }
      __typename
    }
  }
`;
