import { gql } from '@apollo/client';

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation AcceptFriendRequest($id: String!, $isClub: Boolean) {
    acceptFriendRequest(id: $id, isClub: $isClub) {
      id
      date
      friend {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
