import { IColours } from '../../../../../constants/colours';

export const getAttemptsSliderColour = (colours: IColours, value?: number) => {
  if (value) {
    if (value === 5) {
      return colours.lighterPrimaryBackground;
    }
    if (value === 4) {
      return colours.lightPrimaryBackground;
    }
    if (value === 3) {
      return colours.midPrimaryBackground;
    }
    if (value === 2) {
      return colours.highPrimaryBackground;
    }
  }

  return colours.primaryThemeColour;
};
