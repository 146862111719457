import { IAchievementDetails } from '../../api/types/achievement';

export enum ClimbingType {
  BOULDER = 'BOULDER',
  TOP_ROPE = 'TOP_ROPE',
  LEAD = 'LEAD',
}

export enum ProgressViewType {
  GRAPH = 'GRAPH',
  LIST = 'LIST',
}

export interface IActivityDay {
  id: string;
  date: string;
  activities: IActivityDetails[];
}

export interface IActivityDetails {
  id: string;
  date: string;
  type: ClimbingType;
  grade: string;
  intensity: number;
  completed: boolean;
  attempts: number;
  comment: string;
  activityDay?: Partial<IActivityDay>;
  achievement?: Partial<IAchievementDetails>;
}
