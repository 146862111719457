import { gql } from '@apollo/client';

export const REPORT_POST = gql`
  mutation ReportPost(
    $reporterId: String!
    $achievementId: String!
    $reason: String
    $block: Boolean!
  ) {
    reportPost(
      reporterId: $reporterId
      achievementId: $achievementId
      reason: $reason
      block: $block
    ) {
      id
    }
  }
`;
