import { IMessageRequest } from '../../../api/types/message-request';
import { IMessageRoom } from '../../../api/types/message-room';
import { MessageContentInner } from './message-content-inner';
import { RequestContent } from './request-content';

interface IMessageContentProps {
  isRequest: boolean;
  selectedMessage?: IMessageRoom;
  request: IMessageRequest;
  resetLists: () => void;
  closeMessage: () => void;
}
export const MessageContent: React.FC<IMessageContentProps> = ({
  isRequest,
  request,
  selectedMessage,
  resetLists,
  closeMessage,
}) => {
  if (isRequest) {
    return <RequestContent request={request} resetLists={resetLists} closeMessage={closeMessage} />;
  }

  if (selectedMessage) {
    return <MessageContentInner selectedMessage={selectedMessage} closeMessage={closeMessage} />;
  }

  return null;
};
