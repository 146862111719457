import {
  TFontScaleBoulderingGrades,
  TVScaleBoulderingGrades,
} from '../../../../constants/bouldering-grades';

export const numberToVScaleBoulderingGrade = (value: number): TVScaleBoulderingGrades | '' => {
  switch (value) {
    case 0:
      return '';
    case 1:
      return 'VB';
    case 2:
      return 'V0-';
    case 3:
      return 'V0';
    case 4:
      return 'V0+';
    case 5:
      return 'V1';
    case 6:
      return 'V2';
    case 7:
      return 'V3';
    case 8:
      return 'V4';
    case 9:
      return 'V5';
    case 10:
      return 'V6';
    case 11:
      return 'V7';
    case 12:
      return 'V8';
    case 13:
      return 'V9';
    case 14:
      return 'V10';
    case 15:
      return 'V11';
    case 16:
      return 'V12';
    case 17:
      return 'V13';
    case 18:
      return 'V14';
    case 19:
      return 'V15';
    case 20:
      return 'V16';
    case 21:
      return 'V17';
    default:
      return '';
  }
};

export const numberToFontScaleBoulderingGrade = (
  value: number,
): TFontScaleBoulderingGrades | '' => {
  switch (value) {
    case 0:
      return '';
    case 1:
      return '4';
    case 2:
      return '5';
    case 3:
      return '5+';
    case 4:
      return '6A';
    case 5:
      return '6A+';
    case 6:
      return '6B';
    case 7:
      return '6B+';
    case 8:
      return '6C';
    case 9:
      return '6C+';
    case 10:
      return '7A';
    case 11:
      return '7A+';
    case 12:
      return '7B';
    case 13:
      return '7B+';
    case 14:
      return '7C';
    case 15:
      return '7C+';
    case 16:
      return '8A';
    case 17:
      return '8A+';
    case 18:
      return '8B';
    case 19:
      return '8B+';
    case 20:
      return '8C+';
    case 21:
      return '9A';
    default:
      return '';
  }
};
