import React, { useState } from 'react';

import { Dialog, DialogContent } from '@mui/material';

import { ClimbingType } from '../../../pages/my-progress/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { ModalHeader } from '../../components/modal-header';
import { LogActivityForm } from '../log-activity-form';

interface ILogActivityModalProps {
  tab: ClimbingType;
  refetchActivitiesVariables: {
    userId: string;
    type: ClimbingType;
    limit: number;
    offset: number;
  };
  goal?: IGoalDetails;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (goalComplete: boolean, achievementSaved: boolean) => void;
}

export const LogActivityModal: React.FC<ILogActivityModalProps> = ({
  tab,
  refetchActivitiesVariables,
  isOpen,
  goal,
  onClose,
  onSuccess,
}) => {
  const [cropperOpen, setCropperOpen] = useState<boolean>(false);
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <ModalHeader onClose={onClose} title={'Log a climb'} hideHeader={cropperOpen} />
      <DialogContent sx={{ padding: 2 }} dividers>
        <LogActivityForm
          onSuccess={onSuccess}
          tab={tab}
          goal={goal}
          setCropperOpen={(isOpen) => setCropperOpen(isOpen)}
          refetchActivitiesVariables={refetchActivitiesVariables}
        />
      </DialogContent>
    </Dialog>
  );
};
