import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

interface IGoalNotification {
  showNotification: boolean;
  handleClick: () => void;
}
export const GoalNotification: React.FC<IGoalNotification> = ({
  handleClick,
  showNotification,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(showNotification);
  }, [showNotification]);

  const click = () => {
    handleClick();
    setOpen(false);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button sx={{ mr: 1 }} color="primary" variant="contained" size="small" onClick={click}>
        Set goal
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar open={open} onClose={handleClose} message="You have no goal set" action={action} />
  );
};
