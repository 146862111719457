import { gql } from '@apollo/client';

export const REPORT_COMMENT = gql`
  mutation ReportComment(
    $reporterId: String!
    $commentId: String!
    $reason: String
    $block: Boolean!
  ) {
    reportComment(reporterId: $reporterId, commentId: $commentId, reason: $reason, block: $block) {
      id
    }
  }
`;
