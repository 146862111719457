import React from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import { ADD_PLAN } from '../../../api/mutation/addPlan';
import { LogPlanFormFormSchema } from '../../../validation/schemas/log-plan-form';
import { LogPlanFormInputs } from '../../../validation/schemas/log-plan-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface ILogPlanFormProps {
  refetchPlannerVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  onSuccess: () => void;
}

export const LogPlanForm: React.FC<ILogPlanFormProps> = ({ onSuccess }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<LogPlanFormInputs>({
    mode: 'onChange',
    defaultValues: {
      date: new Date(new Date(new Date().setHours(18)).setMinutes(0)),
    },
    resolver: yupResolver(LogPlanFormFormSchema),
  });

  const [addPlan, { loading, error }] = useMutation(ADD_PLAN);

  const onSubmit: SubmitHandler<LogPlanFormInputs> = async (data) => {
    await addPlan({
      variables: {
        input: {
          userId: cookies.signedInUser,
          location: data.location,
          date: data.date,
        },
      },
    }).then(() => {
      onSuccess();
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        margin="dense"
        id="location"
        label="Location"
        type="string"
        fullWidth
        variant="outlined"
        error={Boolean(errors.location?.message)}
        helperText={errors.location?.message}
        sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
        {...register('location')}
      />
      <Controller
        control={control}
        name="date"
        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
          <MobileDateTimePicker
            {...field}
            inputRef={ref}
            label="Date"
            inputFormat="dd/MM/yyyy hh:mm"
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                onBlur={onBlur}
                name={name}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
              />
            )}
          />
        )}
      />
      <ErrorAlert showError={Boolean(error?.message)} />
      <SubmitButton loading={loading} label={'Save plan'} />
    </form>
  );
};
