import {
  TFontScaleBoulderingGrades,
  TVScaleBoulderingGrades,
} from '../../../../constants/bouldering-grades';
import { EBoulderingGradeType, ERopeGradeType } from '../../../../constants/grade-types';
import { TFrenchRopeGrades, TYDSRopeGrades } from '../../../../constants/rope-grades';
import { ClimbingType } from '../../../../pages/my-progress/types';
import {
  fontScaleBoulderingGradeToNumber,
  vScaleBoulderingGradeToNumber,
} from './bouldering-grade-to-number';
import { frenchRopeGradeToNumber, ydsRopeGradeToNumber } from './rope-grade-to-number';

export const gradeToNumber = ({
  boulderingGradeType,
  grade,
  ropeGradeType,
  type,
}: {
  grade: string;
  type?: ClimbingType;
  boulderingGradeType: EBoulderingGradeType;
  ropeGradeType: ERopeGradeType;
}) => {
  if (type === ClimbingType.BOULDER) {
    if (boulderingGradeType === EBoulderingGradeType.FONT_SCALE) {
      return fontScaleBoulderingGradeToNumber(grade as TFontScaleBoulderingGrades);
    }

    // Default bouldering should be V-SCALE
    return vScaleBoulderingGradeToNumber(grade as TVScaleBoulderingGrades);
  }

  if (ropeGradeType === ERopeGradeType.YDS) {
    return ydsRopeGradeToNumber(grade as TYDSRopeGrades);
  }

  // Default rope should be french
  return frenchRopeGradeToNumber(grade as TFrenchRopeGrades);
};
