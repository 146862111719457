import React from 'react';

import { Box, Button, Stack } from '@mui/material';

import { useColours } from '../../utils/use-colours';
import { useIsMobile } from '../../utils/use-is-mobile';
import { Banner } from '../banner';
import { TileContent } from '../tile-content';

interface IHalfBannerProps {
  backgroundImage: string;
  preTitle?: string;
  title?: string;
  subtitle?: string;
  bodyText?: React.ReactNode;
  ctaText?: string;
  handleCtaClick?: Function;
  alignItems?: 'left' | 'right';
}

/**
 * This component will split the page in two between a image and text section.
 * The text can be left or right of the image.
 */
export const HalfBanner: React.FC<IHalfBannerProps> = ({
  backgroundImage,
  preTitle,
  title,
  subtitle,
  bodyText,
  ctaText,
  handleCtaClick,
  alignItems = 'left',
}) => {
  const isMobile = useIsMobile();
  const colours = useColours();

  const Content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems,
      }}
      padding={2}
    >
      <TileContent preTitle={preTitle} title={title} subtitle={subtitle} bodyText={bodyText} />
      {Boolean(ctaText) && (
        <Button
          variant="contained"
          size="medium"
          sx={{ color: colours.primaryInverseColour, mt: 2 }}
          onClick={() => (handleCtaClick ? handleCtaClick() : null)}
        >
          {ctaText}
        </Button>
      )}
    </Box>
  );

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      sx={{
        width: '100%',
        alignItems: 'center',
        backgroundColor: colours.lighterPrimaryBackground,
        justifyContent: alignItems === 'left' ? 'space-between' : 'flex-start',
      }}
    >
      {Boolean(alignItems === 'left') && Content}
      <Banner width={isMobile ? '100%' : '65%'} backgroundImage={backgroundImage} />
      {Boolean(alignItems === 'right') && Content}
    </Stack>
  );
};
