import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import DoneIcon from '@mui/icons-material/Done';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  Typography,
} from '@mui/material';

import { GET_SUGGESTED_FRIENDS } from '../../../api/query/getSuggestedFriends';
import { SEND_FRIEND_REQUEST } from '../../../api/query/sendFriendRequest';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ProfilePicture } from '../../components/profile-picture';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

export const SuggestedFriends: React.FC = () => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [sentRequests, setSentRequests] = useState<string[]>([]);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const [sendFriendRequest, { loading: sendLoading, error: sendError }] =
    useMutation(SEND_FRIEND_REQUEST);

  const { loading, error, data, fetchMore, refetch } = useQuery(GET_SUGGESTED_FRIENDS, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  const handleAddFriend = (id: string) => {
    if (Boolean(sentRequests.find((f) => f === id))) {
      return;
    }
    sendFriendRequest({
      variables: { senderUserId: cookies.signedInUser, receiverUserId: id },
    }).then(() => {
      setSentRequests([...sentRequests, id]);
    });
  };

  return (
    <List sx={{ bgcolor: 'background.paper', paddingBottom: 0, p: 2 }} component="nav">
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center' }}>
          Suggested members
        </Typography>
        <Button
          size="small"
          sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          color="primary"
          onClick={() =>
            refetch({
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {loading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading suggested friends...'}
        />
      )}

      <ErrorAlert
        message={
          sendError?.message.includes('already sent a request to this person!')
            ? 'You have already sent a request to this person!'
            : undefined
        }
        showError={Boolean(error?.message || sendError?.message)}
      />
      {Boolean(data?.getSuggestedFriends?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          No suggested friends could be found.
          <br />
          Suggestions are based on friends of your existing community members.
        </Alert>
      )}
      {data?.getSuggestedFriends?.data?.map(({ friend }: any) => {
        const requestSent = Boolean(sentRequests.find((f) => f === friend.id));
        return (
          <React.Fragment key={friend.id}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                  <ProfilePicture userId={friend.id} navigable />
                </Box>
                <Box sx={{ overflow: 'hidden' }}>
                  <Typography sx={cardTitleFontStyles}>{getNameFromUser(friend)}</Typography>
                  <Typography sx={cardSubtitleFontStyles(colours)}>{`${friend.email}`}</Typography>
                </Box>
              </Box>
              <IconButton
                disabled={sendLoading || requestSent}
                onClick={() => handleAddFriend(friend.id)}
              >
                {requestSent ? (
                  <DoneIcon />
                ) : sendLoading ? (
                  <CircularProgress size={20} thickness={3} />
                ) : (
                  <PersonAddAltIcon />
                )}
              </IconButton>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          data?.getSuggestedFriends?.data?.length > 0 &&
            data?.getSuggestedFriends?.data?.length % paginationValues.offset !== 0,
        ) && (
          <SubmitButton
            sx={{ mr: 2 }}
            variant="outlined"
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getSuggestedFriends?.data?.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getSuggestedFriends: {
                      data: [
                        ...previousResult.getSuggestedFriends.data,
                        ...fetchMoreResult.getSuggestedFriends.data,
                      ],
                      count: fetchMoreResult.getSuggestedFriends.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </List>
  );
};
