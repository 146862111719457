import { useMutation } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert, Box, Stack, Typography } from '@mui/material';

import { ACCEPT_MESSAGE_REQUEST } from '../../../../api/mutation/acceptMessageRequest';
import { DECLINE_MESSAGE_REQUEST } from '../../../../api/mutation/declineMessageRequest';
import { GET_MESSAGE_REQUESTS } from '../../../../api/query/getMessageRequests';
import { GET_MESSAGE_ROOMS } from '../../../../api/query/getMessageRooms';
import { IMessageRequest } from '../../../../api/types/message-request';
import { SubmitButton } from '../../../components/submit-button';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { Message } from '../message';

interface IRequestContentProps {
  request: IMessageRequest;
  resetLists: () => void;
  closeMessage: () => void;
}
export const RequestContent: React.FC<IRequestContentProps> = ({
  request,
  resetLists,
  closeMessage,
}) => {
  const colour = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [acceptRequest, { loading: acceptLoading, error: acceptError }] = useMutation(
    ACCEPT_MESSAGE_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_MESSAGE_REQUESTS,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getMessages: [
              ...(data?.getMessageRequests || []).filter((f: any) => f.id !== request.id),
            ],
          }),
        );
        cache.updateQuery(
          {
            query: GET_MESSAGE_ROOMS,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getMessages: [result?.data?.acceptMessageRequest, ...(data?.getMessageRooms || [])],
          }),
        );
      },
    },
  );

  const [declineRequest, { loading: declineLoading, error: declineError }] = useMutation(
    DECLINE_MESSAGE_REQUEST,
    {
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_MESSAGE_REQUESTS,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getMessages: [
              ...(data?.getMessageRequests || []).filter(
                (f: any) => f.id !== result?.data?.declineMessageRequest,
              ),
            ],
          }),
        );
      },
    },
  );

  const handleAcceptRequest = () => {
    acceptRequest({ variables: { id: request.id } }).then(() => {
      resetLists();
    });
  };

  const handleDeclineRequest = () => {
    declineRequest({ variables: { id: request.id } }).then(() => {
      resetLists();
    });
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        p={2}
        sx={{
          backgroundColor: colour.midPrimaryBackground,
          color: colour.primaryInverseColour,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ArrowBackIcon onClick={closeMessage} />
        <Box pl={2}>
          <Typography variant="h6" noWrap component="div">
            {`${request.requester.firstName} ${request.requester.lastName}`}
          </Typography>
          <Typography variant="subtitle2" noWrap component="div">
            {request.gym}
          </Typography>
        </Box>
      </Box>
      <Box
        p={2}
        sx={{
          flex: 1,
          overflow: 'auto',
          bgcolor: colour.pageBackgroundColour,
        }}
      >
        <Box>
          <Message
            name={`${request.requester.firstName} ${request.requester.lastName}`}
            message={request.message}
          />
        </Box>
      </Box>
      <Box p={2} sx={{ bgcolor: colour.pageBackgroundColour }}>
        {acceptError && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="error">
            {acceptError.message}
          </Alert>
        )}
        {declineError && (
          <Alert sx={{ width: '100%', marginBottom: 2 }} severity="error">
            {declineError.message}
          </Alert>
        )}
        <Stack direction={'row'} gap={4}>
          <SubmitButton
            label={'Accept'}
            onClick={handleAcceptRequest}
            disabled={acceptLoading || declineLoading}
            loading={acceptLoading}
          />
          <SubmitButton
            label={'Decline'}
            colour="inherit"
            onClick={handleDeclineRequest}
            disabled={acceptLoading || declineLoading}
            loading={declineLoading}
          />
        </Stack>
      </Box>
    </Box>
  );
};
