import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { RoutePath } from '../../../constants';

// Links to show all users
export const GeneralLinks = [{ href: RoutePath.HELP, label: 'Help', icon: <HelpIcon /> }];

export const ClubLinks = (clubId: string) => [
  { href: `${RoutePath.PROFILE}${clubId}`, label: 'Profile', icon: <PersonOutlineIcon /> },
  { href: RoutePath.MANAGE_CLUB_COMMUNITY, label: 'Members', icon: <GroupAddIcon /> },
  { href: RoutePath.MY_PLANNER, label: 'Planner', icon: <CalendarMonthIcon /> },
  { href: RoutePath.COMMUNITY, label: 'Community', icon: <GroupsIcon /> },
  {
    href: RoutePath.MY_CLUB,
    label: 'Account',
    icon: <AccountCircleOutlinedIcon />,
  },
];

// Extra links to show non logged in users
export const ClubNotSignedInLinks = [
  {
    href: RoutePath.CLUB_AUTHENTICATION,
    label: 'Sign in',
    icon: <AccountCircleOutlinedIcon />,
  },
];

// Extra links to show non logged in users
export const NotSignedInLinks = [
  {
    href: RoutePath.AUTHENTICATION,
    label: 'Sign in',
    icon: <AccountCircleOutlinedIcon />,
  },
];

// Extra links to show users that are logged in
// TODO: Optionally display this only if the user is logged in
export const AccountLinks = (userId: string) => [
  { href: `${RoutePath.PROFILE}${userId}`, label: 'My Profile', icon: <PersonOutlineIcon /> },
  { href: RoutePath.COMMUNITY, label: 'Community', icon: <GroupsIcon /> },
  { href: RoutePath.PROGRESS, label: 'Progress', icon: <BarChartIcon /> },
  { href: RoutePath.PLANNER, label: 'Planner', icon: <CalendarMonthIcon /> },
  { href: RoutePath.MANAGE_CLUBS, label: 'Clubs', icon: <GroupsIcon /> },
  { href: RoutePath.GOALS, label: 'Goals', icon: <EmojiEventsIcon /> },
  { href: RoutePath.MANAGE_COMMUNITY, label: 'Manage community', icon: <GroupAddIcon /> },
  {
    href: RoutePath.MY_ACCOUNT,
    label: 'Account',
    icon: <AccountCircleOutlinedIcon />,
  },
  // { href: RoutePath.BUDDY_SEARCH, label: 'Buddy search', icon: <PersonAddAltIcon /> },
  // { href: RoutePath.MESSAGES, label: 'Messages', icon: <MailIcon /> },
];
