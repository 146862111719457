import React, { useState } from 'react';

import { addMonths } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { ADD_GOAL } from '../../../api/mutation/addGoal';
import { GET_GOAL } from '../../../api/query/getGoal';
import { vScaleBoulderingGrades } from '../../../constants/bouldering-grades';
import { frenchRopeGrades } from '../../../constants/rope-grades';
import { ClimbingType } from '../../../pages/my-progress/types';
import { SetGoalFormFormSchema } from '../../../validation/schemas/set-goal-form';
import { IGoalFormDetails } from '../../../validation/schemas/set-goal-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { gradeToNumber } from '../progress-graph/utils/grade-to-number';

interface IGoalsFormProps {
  onBack: () => void;
  climbingType?: ClimbingType;
}

export const GoalsForm: React.FC<IGoalsFormProps> = ({ onBack, climbingType }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IGoalFormDetails>({
    mode: 'onChange',
    defaultValues: {
      flash: false,
      grade: '',
      targetDate: addMonths(new Date(), 1),
    },
    resolver: yupResolver(SetGoalFormFormSchema),
  });

  const [flash, setFlash] = useState<boolean>(false);

  const [addGoal, { loading, error }] = useMutation(ADD_GOAL, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_GOAL,
          variables: {
            userId: cookies.signedInUser,
          },
        },
        (data) => ({
          getGoal: [
            ...(data?.getGoal || []).filter((f: any) => f.type !== result.data.addGoal.type),
            result?.data?.addGoal,
          ],
        }),
      );
    },
  });

  const onSubmit: SubmitHandler<IGoalFormDetails> = (data) => {
    addGoal({
      variables: {
        input: {
          userId: cookies.signedInUser,
          type: climbingType,
          grade: gradeToNumber({
            boulderingGradeType: cookies.boulderingGradePreference,
            ropeGradeType: cookies.ropeGradePreference,
            grade: data.grade,
            type: climbingType,
          })?.toString(),
          flash: flash,
          targetDate: data.targetDate,
        },
      },
    }).then(() => onBack());
  };

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2">What grade are you aiming for?</Typography>
        <Controller
          name="grade"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              label="Grade"
              fullWidth
              error={Boolean(errors.grade?.message)}
              helperText={errors.grade?.message}
              sx={{ mb: 4, mt: 1, backgroundColor: colours.primaryInverseColour }}
            >
              {(climbingType === ClimbingType.BOULDER
                ? vScaleBoulderingGrades
                : frenchRopeGrades
              ).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Typography variant="body2">Is your goal to be flashing it? (one attempt)</Typography>
        <FormControl error={Boolean(errors.flash?.message)} sx={{ width: '100%' }}>
          <FormGroup sx={{ mb: 4, flexDirection: 'row', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={<Switch name="flash" value={flash} onClick={() => setFlash(!flash)} />}
              label="Flash"
            />
          </FormGroup>
        </FormControl>
        <Typography variant="body2">When do you want achieve this goal by?</Typography>
        <Controller
          control={control}
          name="targetDate"
          render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
            <MobileDatePicker
              {...field}
              inputRef={ref}
              label="Date"
              inputFormat="dd/MM/yyyy"
              renderInput={(inputProps) => (
                <TextField
                  {...inputProps}
                  onBlur={onBlur}
                  name={name}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  sx={{ mt: 1, backgroundColor: colours.primaryInverseColour }}
                />
              )}
            />
          )}
        />
        <List
          dense
          sx={{
            pl: 2,
            listStyleType: 'disc',
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem sx={{ pl: 1 }}>
            <Typography component={'div'} variant="body2" mt={2}>
              The goal start date will be today.
            </Typography>
          </ListItem>
          <ListItem sx={{ pl: 1 }}>
            <Typography component={'div'} variant="body2" mb={1}>
              The previously set bouldering goal will be deleted.
            </Typography>
          </ListItem>
        </List>
        <Box>
          <ErrorAlert showError={Boolean(error)} />
          <Button
            onClick={onBack}
            fullWidth
            variant="contained"
            color="inherit"
            sx={{ mt: 1, mb: 1 }}
          >
            Cancel
          </Button>
          <SubmitButton loading={loading} label={'Save Goal'} />
        </Box>
      </form>
    </Box>
  );
};
