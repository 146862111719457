import * as React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { useColours } from '../../utils/use-colours';
import { TileContent } from '../tile-content';

interface IBasicCardProps {
  preTitle?: string;
  title?: string;
  subtitle?: string;
  bodyText?: React.ReactNode;
  ctaText?: string;
  handleCtaClick?: Function;
  alignItems?: 'flex-start' | 'center' | 'flex-end';
}

export const BasicCard: React.FC<IBasicCardProps> = ({
  preTitle,
  title,
  subtitle,
  bodyText,
  ctaText,
  handleCtaClick,
  alignItems = 'flex-start',
}) => {
  const colours = useColours();

  return (
    <Card
      sx={{
        minWidth: 275,
        maxWidth: 350,
        display: 'flex',
        flexDirection: 'column',
        alignItems,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems,
          textAlign: alignItems,
        }}
      >
        <TileContent preTitle={preTitle} title={title} subtitle={subtitle} bodyText={bodyText} />
      </CardContent>
      {Boolean(ctaText) && (
        <CardActions>
          <Button
            variant="contained"
            size="medium"
            sx={{ color: colours.primaryInverseColour, marginBottom: '16px' }}
            onClick={() => (handleCtaClick ? handleCtaClick() : null)}
          >
            {ctaText}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
