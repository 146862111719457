import { TFrenchRopeGrades, TYDSRopeGrades } from '../../../../constants/rope-grades';

export const frenchRopeGradeToNumber = (grade: TFrenchRopeGrades): number => {
  switch (grade) {
    case '1':
      return 0;
    case '2':
      return 1;
    case '3':
      return 2;
    case '4':
      return 3;
    case '5a':
      return 4;
    case '5b':
      return 5;
    case '5c':
      return 6;
    case '6a':
      return 7;
    case '6a+':
      return 8;
    case '6b':
      return 9;
    case '6b+':
      return 10;
    case '6c':
      return 11;
    case '6c+':
      return 12;
    case '7a':
      return 13;
    case '7a+':
      return 14;
    case '7b':
      return 15;
    case '7b+':
      return 16;
    case '7c':
      return 17;
    case '7c+':
      return 18;
    case '8a':
      return 19;
    case '8a+':
      return 20;
    case '8b':
      return 21;
    case '8b+':
      return 22;
    case '8c':
      return 23;
    case '8c+':
      return 24;
    case '9a':
      return 25;
    case '9a+':
      return 26;
    case '9b':
      return 27;
    case '9b+':
      return 28;
    case '9c':
      return 29;
    case '9c+':
      return 30;
  }
};

export const ydsRopeGradeToNumber = (grade: TYDSRopeGrades): number => {
  switch (grade) {
    case '5.2':
      return 0;
    case '5.3':
      return 1;
    case '5.4':
      return 2;
    case '5.5':
      return 3;
    case '5.6':
      return 4;
    case '5.7':
      return 5;
    case '5.8':
      return 6;
    case '5.9':
      return 7;
    case '5.10a':
      return 8;
    case '5.10b':
      return 9;
    case '5.10c':
      return 10;
    case '5.10d':
      return 11;
    case '5.11a':
      return 12;
    case '5.11b':
      return 13;
    case '5.11c':
      return 14;
    case '5.11d':
      return 15;
    case '5.12a':
      return 16;
    case '5.12b':
      return 17;
    case '5.12c':
      return 18;
    case '5.12d':
      return 19;
    case '5.13a':
      return 20;
    case '5.13b':
      return 21;
    case '5.13c':
      return 22;
    case '5.13d':
      return 23;
    case '5.14a':
      return 24;
    case '5.14b':
      return 25;
    case '5.14c':
      return 26;
    case '5.14d':
      return 27;
    case '5.15a':
      return 28;
    case '5.15b':
      return 29;
    case '5.15c':
      return 30;
  }
};
