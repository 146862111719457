export const getNumberOfAttempts = (attempts: number, completed: boolean) => {
  if (attempts === 1) {
    if (completed) {
      return 'Flashed!';
    } else {
      return '1 attempt';
    }
  }

  return `${attempts} attempts`;
};
