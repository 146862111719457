import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { Box, Button, DialogContent, IconButton, Typography } from '@mui/material';

import { REMOVE_ACTIVITY } from '../../../../api/mutation/removeActivity';
import { STAR_ACTIVITY } from '../../../../api/mutation/starActivity';
import { GET_BOULDERING_ACTIVITIES } from '../../../../api/query/getBoulderingActivities';
import { GET_LEAD_ACTIVITIES } from '../../../../api/query/getLeadActivities';
import { GET_TOP_ROPE_ACTIVITIES } from '../../../../api/query/getTopRopeActivities';
import { ClimbingType, IActivityDetails } from '../../../../pages/my-progress/types';
import { IGoalDetails } from '../../../../validation/schemas/set-goal-form/types';
import { ErrorAlert } from '../../../components/error-alert';
import { SubmitButton } from '../../../components/submit-button';
import { cardBodyFontStyles } from '../../../styles/card-font-styles';
import { cardSmallSelectFontStyles } from '../../../styles/card-small-select-font-styles';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { ActivityDetails } from '../../activity-details';
import { getDifferenceInGradeString } from './utils/get-difference-in-grade-string';

interface IActivityDetailsModalContentProps {
  refetchActivitiesVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  activity?: IActivityDetails;
  goal?: IGoalDetails;
  back: () => void;
  updateAchievementId: (achievementId: string) => void;
  removeActivityFromDay: (id: string) => void;
}

export const ActivityDetailsModalContent: React.FC<IActivityDetailsModalContentProps> = ({
  activity,
  refetchActivitiesVariables,
  goal,
  back,
  updateAchievementId,
  removeActivityFromDay,
}) => {
  const [deleteSelected, setDeleteSelected] = useState<boolean>(false);
  const [achievementId, setAchievementId] = useState<string | undefined>(activity?.achievement?.id);
  const { cookies } = useClimbingCommunityCookies();

  const refetchQueryDoc = () => {
    switch (activity?.type) {
      case ClimbingType.BOULDER:
        return GET_BOULDERING_ACTIVITIES;
      case ClimbingType.LEAD:
        return GET_LEAD_ACTIVITIES;
      default:
        return GET_TOP_ROPE_ACTIVITIES;
    }
  };

  // const refetchQueryData = (data: any) => {
  //   switch (activity?.type) {
  //     case ClimbingType.BOULDER:
  //       return {
  //         getBoulderingActivities: [
  //           ...(data?.getBoulderingActivities || []).filter(
  //             (activity: IActivityDetails) => activity.id !== achievementId,
  //           ),
  //         ],
  //       };

  //     case ClimbingType.LEAD:
  //       return {
  //         getLeadActivities: [
  //           ...(data?.getLeadActivities || []).filter(
  //             (activity: IActivityDetails) => activity.id !== achievementId,
  //           ),
  //         ],
  //       };

  //     case ClimbingType.TOP_ROPE:
  //       return {
  //         getTopRopeActivities: [
  //           ...(data?.getTopRopeActivities || []).filter(
  //             (activity: IActivityDetails) => activity.id !== achievementId,
  //           ),
  //         ],
  //       };
  //   }
  // };

  const refetchQueryStarData = (data: any, result: any) => {
    const mapper = (item: IActivityDetails): IActivityDetails => {
      if (item.id === activity?.id) {
        return {
          ...item,
          achievement: result?.data?.starActivity,
        };
      }
      return item;
    };

    switch (activity?.type) {
      case ClimbingType.BOULDER:
        return {
          getBoulderingActivities: [...(data?.getBoulderingActivities || [])?.map(mapper)],
        };

      case ClimbingType.LEAD:
        return {
          getLeadActivities: [...(data?.getLeadActivities || []).map(mapper)],
        };

      case ClimbingType.TOP_ROPE:
        return {
          getTopRopeActivities: [...(data?.getTopRopeActivities || []).map(mapper)],
        };
    }
  };

  const [removeActivity, { loading, error }] = useMutation(REMOVE_ACTIVITY, {
    refetchQueries: [GET_BOULDERING_ACTIVITIES, GET_LEAD_ACTIVITIES, GET_TOP_ROPE_ACTIVITIES],
    // update: (cache) => {
    //   cache.updateQuery(
    //     {
    //       query: refetchQueryDoc(),
    //       variables: refetchActivitiesVariables,
    //     },
    //     (data) => refetchQueryData(data),
    //   );
    // },
  });

  const [starActivity, { loading: loadingStar, error: errorStar }] = useMutation(STAR_ACTIVITY, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: refetchQueryDoc(),
          variables: refetchActivitiesVariables,
        },
        (data) => refetchQueryStarData(data, result),
      );
    },
  });

  const handleRemoveActivity = () => {
    removeActivity({ variables: { id: activity?.id } }).then(() => {
      removeActivityFromDay(activity!.id);
      back();
      setDeleteSelected(false);
    });
  };

  const handleStarActivity = () => {
    if (!achievementId) {
      starActivity({ variables: { id: activity?.id, userId: cookies.signedInUser } }).then(
        (result) => {
          updateAchievementId(result?.data?.starActivity?.id);
          setAchievementId(result?.data?.starActivity?.id);
        },
      );
    }
  };

  const colours = useColours();

  return (
    <>
      <Box
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: 300,
        }}
      >
        <IconButton sx={{ padding: 0 }} aria-label="close" onClick={back}>
          <ArrowBackIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ mr: 1 }} variant="h6" component="h4">
            {deleteSelected ? 'Are you sure?' : 'Climb details'}
          </Typography>
        </Box>
      </Box>
      <DialogContent sx={{ padding: 2 }} dividers>
        {deleteSelected ? (
          <>
            <ErrorAlert
              showError={Boolean(error)}
              message={
                error?.message.includes('violates foreign key constraint')
                  ? 'Because this activity has been shared with your community, you must delete it from your profile.'
                  : undefined
              }
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => setDeleteSelected(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <SubmitButton
                fullWidth={false}
                onClick={handleRemoveActivity}
                label={'Delete'}
                loading={loading}
              />
            </Box>
          </>
        ) : (
          <>
            <ActivityDetails colours={colours} activity={activity} goal={goal} />
            <Typography sx={cardBodyFontStyles(colours)}>
              {getDifferenceInGradeString(activity?.grade, goal?.grade)}
            </Typography>
            <ErrorAlert showError={Boolean(errorStar?.message)} noMargin />
          </>
        )}
      </DialogContent>
      {Boolean(!deleteSelected) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: colours.highPrimaryBackground,
          }}
        >
          <Button
            sx={{
              ...cardSmallSelectFontStyles(colours),
              color: achievementId ? colours.primaryInverseColour : colours.primaryThemeColour,
              backgroundColor: achievementId
                ? colours.primaryThemeColour
                : colours.primaryInverseColour,
            }}
            disabled={loadingStar}
            variant="contained"
            onClick={() => handleStarActivity()}
            endIcon={
              achievementId ? (
                <GroupsIcon sx={{ height: 25, width: 25 }} />
              ) : (
                <GroupsOutlinedIcon sx={{ height: 25, width: 25 }} />
              )
            }
          >
            {achievementId ? 'Shared' : 'Share'}
          </Button>
          <IconButton
            sx={{
              minWidth: 0,
              padding: 0,
              color: colours.primaryInverseColour,
            }}
            onClick={() => setDeleteSelected(true)}
          >
            <DeleteForeverIcon sx={{ height: 25, width: 25 }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
