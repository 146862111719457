import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Dialog, DialogContent } from '@mui/material';

import { GET_GOAL } from '../../../api/query/getGoal';
import { ModalHeader } from '../../components/modal-header';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { CommunityLogActivityForm } from '../log-activity-form/community-log-activity';

interface ICommunityLogActivityModalProps {
  refetchActivitiesVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (goalComplete: boolean, achievementSaved: boolean) => void;
}

export const CommunityLogActivityModal: React.FC<ICommunityLogActivityModalProps> = ({
  refetchActivitiesVariables,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const { data } = useQuery(GET_GOAL, {
    variables: {
      userId: cookies.signedInUser,
    },
  });

  const [cropperOpen, setCropperOpen] = useState<boolean>(false);
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <ModalHeader onClose={onClose} title={'Log a climb'} hideHeader={cropperOpen} />
      <DialogContent sx={{ padding: 2 }} dividers>
        <CommunityLogActivityForm
          onSuccess={onSuccess}
          goals={data?.getGoal}
          setCropperOpen={(isOpen) => setCropperOpen(isOpen)}
          refetchActivitiesVariables={refetchActivitiesVariables}
        />
      </DialogContent>
    </Dialog>
  );
};
