import React, { useEffect } from 'react';

import { useLocation } from 'react-router';

import { useMutation, useQuery } from '@apollo/client';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { IconButton, Popover } from '@mui/material';

import { MARK_NOTIFICATIONS_READ } from '../../../api/mutation/markNotificationsRead';
import { GET_NOTIFICATIONS } from '../../../api/query/getNotifications';
import { INotification } from '../../../api/types/notification';
import { POLLING_INTERVAL } from '../../../constants/polling-interval';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { PopoverContent } from './popover-content';

export const NotificationsPopover: React.FC = () => {
  const colours = useColours();
  const location = useLocation();
  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error, refetch, startPolling } = useQuery(GET_NOTIFICATIONS, {
    variables: { userId: cookies.signedInUser },
    initialFetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(POLLING_INTERVAL);
  }, [startPolling]);

  useEffect(() => {
    if (location) {
      refetch({ userId: cookies.signedInUser });
    }
  }, [refetch, cookies.signedInUser, location]);

  const [markNotificationsRead] = useMutation(MARK_NOTIFICATIONS_READ);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    markNotificationsRead({ variables: { userId: cookies.signedInUser } });
  };

  const handleClosePopover = () => {
    refetch();
    setAnchorEl(null);
  };

  const unreadNotifications =
    data?.getNotifications && data?.getNotifications.find((f: INotification) => !f.read);

  const popoverId = Boolean(anchorEl) ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton onClick={handleClickPopover} aria-describedby={popoverId}>
        {unreadNotifications ? (
          <NotificationsActiveIcon sx={{ color: colours.primaryInverseColour }} />
        ) : (
          <NotificationsNoneIcon sx={{ color: colours.primaryInverseColour }} />
        )}
      </IconButton>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContent
          notifications={data?.getNotifications}
          handleClosePopover={handleClosePopover}
          loading={loading}
          error={error}
        />
      </Popover>
    </>
  );
};
