import * as Yup from 'yup';

import { yupConfirmPasswordValidation, yupEmailValidation, yupPasswordValidation } from '../../yup';
import { CreateClubFormInputs } from './types';

export const CreateClubFormSchema: Yup.SchemaOf<CreateClubFormInputs> = Yup.object({
  firstName: Yup.string().required('You must enter a club name'),
  email: yupEmailValidation,
  password: yupPasswordValidation,
  confirmPassword: yupConfirmPasswordValidation,
});
