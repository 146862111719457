import React from 'react';

import { ApolloError } from '@apollo/client';
import { Alert, Divider, List } from '@mui/material';

import { INotification } from '../../../../api/types/notification';
import { ErrorAlert } from '../../../components/error-alert';
import { LoadingSpinner } from '../../../components/loading-spinner';
import { NotificationListItem } from '../notification-list-item';

interface IPopoverContentProps {
  handleClosePopover: () => void;
  notifications?: INotification[];
  loading: boolean;
  error?: ApolloError;
}

export const PopoverContent: React.FC<IPopoverContentProps> = ({
  handleClosePopover,
  notifications,
  loading,
  error,
}) => {
  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        width: 275,
        maxHeight: 500,
        overflow: 'auto',
      }}
    >
      <ErrorAlert showError={Boolean(error?.message)} message={error?.message} noMargin />
      {loading && <LoadingSpinner label={'Loading notifications'} />}
      {Boolean(notifications?.length === 0) && (
        <Alert sx={{ width: '100%' }} severity="info">
          You don't have any notifications
        </Alert>
      )}
      {notifications?.map((notification: INotification) => (
        <React.Fragment key={notification.id}>
          <NotificationListItem notification={notification} onClose={handleClosePopover} />
          <Divider variant="fullWidth" />
        </React.Fragment>
      ))}
    </List>
  );
};
