import { gql } from '@apollo/client';

export const LIKE_ACHIEVEMENT = gql`
  mutation LikeAchievement($id: String!, $userId: String!) {
    likeAchievement(id: $id, userId: $userId) {
      id
      date
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
