import { gql } from '@apollo/client';

export const JOIN_PLAN = gql`
  mutation JoinPlan($id: String!, $userId: String!) {
    joinPlan(id: $id, userId: $userId) {
      id
      date
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
