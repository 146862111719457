import { useState } from 'react';

import { Typography } from '@mui/material';

import { Comment } from '../../page-layout/achievement-post/comments/comment';
import { cardCommentFontStyles } from '../../styles/card-comment-font-styles';
import { cardSmallActionFontStyles } from '../../styles/card-small-action-font-styles';
import { useColours } from '../../utils/use-colours';

interface IReportedCommentProps {
  comment: string;
}

export const ReportedComment: React.FC<IReportedCommentProps> = ({ comment }) => {
  const colours = useColours();

  const [showComment, setShowComment] = useState(false);

  return (
    <>
      {showComment && <Comment comment={comment} />}
      {!showComment && (
        <Typography sx={{ ...cardCommentFontStyles, color: colours.subtextColour }}>
          This comment has been hidden due to it being reported.
        </Typography>
      )}
      {!showComment && (
        <Typography onClick={() => setShowComment(true)} sx={cardSmallActionFontStyles(colours)}>
          Show comment
        </Typography>
      )}
    </>
  );
};
