import React, { useMemo, useState } from 'react';

import { Box, Stack, Tab, Tabs } from '@mui/material';

import { useColours } from '../../component-library/utils/use-colours';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ClubsPlanner } from './clubs-planner';
import { CommunityPlanner } from './community-planner';
import { PlannerType } from './types';

export const PlannerPage: React.FC = () => {
  const colours = useColours();
  const [tab, setTab] = useState<PlannerType>(PlannerType.COMMUNITY);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: PlannerType) =>
    setTab(value);

  const PageContentByTab: JSX.Element = useMemo(() => {
    switch (tab) {
      case PlannerType.COMMUNITY:
        return <CommunityPlanner />;
      case PlannerType.CLUBS:
        return <ClubsPlanner />;
    }
  }, [tab]);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
        sx={{
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
          m: 'auto',
        }}
      >
        <Tabs
          value={tab}
          sx={{ width: '100%', mb: 2 }}
          onChange={handleTabChange}
          aria-label="Planner tabs"
          variant="fullWidth"
        >
          <Tab value={PlannerType.COMMUNITY} label="Community" />
          <Tab value={PlannerType.CLUBS} label="Clubs" />
        </Tabs>
        {PageContentByTab}
      </Stack>
    </Box>
  );
};
