import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import { Bar } from 'react-chartjs-2';

import { Alert, Box } from '@mui/material';

import { EBoulderingGradeType, ERopeGradeType } from '../../../constants/grade-types';
import { ClimbingType, IActivityDetails } from '../../../pages/my-progress/types';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { getBackgroundColourData } from './utils/get-background-colour-data';
import { getBorderColourData } from './utils/get-border-colour-data';
import { numberToGrade } from './utils/number-to-grade';

interface IProgressGraphProps {
  clickItem: (value: string) => void;
  bouldering?: boolean;
  items: IActivityDetails[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = ({
  bouldering,
  items,
  clickItem,
  boulderingGradeType,
  ropeGradeType,
}: {
  bouldering?: boolean;
  items: IActivityDetails[];
  clickItem: (value: string) => void;
  boulderingGradeType: EBoulderingGradeType;
  ropeGradeType: ERopeGradeType;
}) => ({
  onClick: (_evt: any, element: any) => {
    if (element.length > 0) {
      const index = element[0].index;
      if (items[index]) {
        clickItem(items[index].date);
      }
    }
  },
  plugins: {
    tooltip: { enabled: false },
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      min: Math.min(...getData({ items })) - 1,
      max: Math.max(...getData({ items })) + 1,
      ticks: {
        precision: 0,
        callback: (value: any) =>
          numberToGrade({
            boulderingGradeType,
            ropeGradeType,
            grade: value.toString(),
            type: bouldering ? ClimbingType.BOULDER : ClimbingType.TOP_ROPE,
          }),
      },
    },
  },
});

const getData = ({ items }: { bouldering?: boolean; items: IActivityDetails[] }): number[] => {
  return items.map((item) => parseInt(item.grade));
};

const data = ({
  bouldering,
  labels,
  items,
}: {
  bouldering?: boolean;
  labels: string[];
  items: IActivityDetails[];
}) => ({
  labels: labels,
  datasets: [
    {
      data: [...getData({ bouldering, items })],
      backgroundColor: [...getBackgroundColourData(items), 'rgb(201, 203, 207, 0)'],
      borderColor: [...getBorderColourData(items), 'rgb(201, 203, 207, 0)'],
      borderWidth: 2,
    },
  ],
});

export const ProgressGraph: React.FC<IProgressGraphProps> = ({ bouldering, items, clickItem }) => {
  const { cookies } = useClimbingCommunityCookies();
  const labels = items.map((item) => format(new Date(parseInt(item.date)), 'dd/MM/yy'));

  if (!items?.length) {
    return (
      <Box
        sx={{
          position: 'relative',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100vw',
          p: 2,
          pb: 0,
        }}
      >
        <Box>
          <Alert severity="info">There are no climbs to display in the graph</Alert>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        margin: 'auto',
        height: `calc(${window.innerHeight}px - 170px - 56px)`,
        width: '100%',
        p: 2,
        pb: 0,
      }}
    >
      <Bar
        options={options({
          bouldering,
          items: items,
          clickItem,
          boulderingGradeType: cookies.boulderingGradePreference,
          ropeGradeType: cookies.ropeGradePreference,
        })}
        height="100%"
        width="100%"
        data={data({ bouldering, labels, items: items })}
      />
    </Box>
  );
};
