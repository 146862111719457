import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { ClimbingType } from '../../../pages/my-progress/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { GoalsForm } from '../goals-modal/form';
import { ClimbingGoalDetailsWithEdit } from '../goals-modal/glimbing-goal-details-with-edit';

interface IGoalContentProps {
  tab: ClimbingType;
  setTab: (value: ClimbingType) => void;
  goals?: {
    bouldering: IGoalDetails;
    topRope: IGoalDetails;
    lead: IGoalDetails;
  };
}

export const GoalContent: React.FC<IGoalContentProps> = ({ tab, setTab, goals }) => {
  const [editGoal, setEditGoal] = useState(false);
  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: ClimbingType) => {
    setTab(value);
    setEditGoal(false);
  };

  const GoalsContent = () => {
    if (editGoal) {
      switch (tab) {
        case ClimbingType.BOULDER:
          return (
            <GoalsForm climbingType={ClimbingType.BOULDER} onBack={() => setEditGoal(false)} />
          );
        case ClimbingType.TOP_ROPE:
          return (
            <GoalsForm climbingType={ClimbingType.TOP_ROPE} onBack={() => setEditGoal(false)} />
          );
        case ClimbingType.LEAD:
          return <GoalsForm climbingType={ClimbingType.LEAD} onBack={() => setEditGoal(false)} />;
      }
    }

    const getGoal = () => {
      switch (tab) {
        case ClimbingType.BOULDER:
          return goals?.bouldering;
        case ClimbingType.TOP_ROPE:
          return goals?.topRope;
        case ClimbingType.LEAD:
          return goals?.lead;
      }
    };

    return (
      <ClimbingGoalDetailsWithEdit
        goalDetails={getGoal()}
        selectEditGoal={() => setEditGoal(true)}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="Climbing type tabs"
          variant="fullWidth"
        >
          <Tab sx={{ p: 1 }} value={ClimbingType.BOULDER} label="Boulder" />
          <Tab sx={{ p: 1 }} value={ClimbingType.TOP_ROPE} label="Top Rope" />
          <Tab sx={{ p: 1 }} value={ClimbingType.LEAD} label="Lead" />
        </Tabs>
      </Box>
      <GoalsContent />
    </>
  );
};
