import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ListItem, ListItemButton, Typography } from '@mui/material';

import { INotification } from '../../../../api/types/notification';
import { RoutePath } from '../../../../constants';
import { cardSubtitleFontStyles } from '../../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../../utils/get-get-since-now-display-text';
import { getNameFromUser } from '../../../utils/get-name-from-user';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';

interface INotificationListItemProps {
  notification: INotification;
  onClose: () => void;
}

export const NotificationListItem: React.FC<INotificationListItemProps> = ({
  notification,
  onClose,
}) => {
  const navigate = useNavigate();
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const handleClick = (path: string) => {
    navigate(path);
    onClose();
  };

  const listItemStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    p: 1,
    backgroundColor: notification.read
      ? colours.pageBackgroundColour
      : colours.elevatedComponentColour,
  };

  if (notification.achievementComment) {
    return (
      <ListItemButton
        sx={listItemStyle}
        onClick={() =>
          handleClick(`${RoutePath.ACHIEVEMENT}${notification.achievementComment?.achievement?.id}`)
        }
      >
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Comment</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {notification.achievementComment.achievement?.user.id === cookies.signedInUser
              ? `${getNameFromUser(notification.achievementComment.user)} commented on your post`
              : `${getNameFromUser(
                  notification.achievementComment.user,
                )} also commented on a post you commented on`}
          </Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.achievementLike) {
    return (
      <ListItemButton
        sx={listItemStyle}
        onClick={() =>
          handleClick(`${RoutePath.ACHIEVEMENT}${notification.achievementLike?.achievement?.id}`)
        }
      >
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Like</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.achievementLike.user,
          )} liked your post`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.planJoin) {
    return (
      <ListItemButton
        sx={listItemStyle}
        onClick={() => handleClick(`${RoutePath.PLAN}${notification.planJoin?.plan?.id}`)}
      >
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>
            {notification.planJoin.plan?.user.id === cookies.signedInUser
              ? 'Someone is joining your plan'
              : `Someone is joining a plan you're involved in`}
          </Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {notification.planJoin.plan?.user.id === cookies.signedInUser
              ? `${getNameFromUser(notification.planJoin.user)} is joining your climbing session`
              : `${getNameFromUser(
                  notification.planJoin.user,
                )} is joining a climbing session you're involved in`}
          </Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.planComment) {
    return (
      <ListItemButton
        sx={listItemStyle}
        onClick={() => handleClick(`${RoutePath.PLAN}${notification.planComment?.plan?.id}`)}
      >
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>
            {notification.planComment.plan?.user.id === cookies.signedInUser
              ? 'New comment on your plan'
              : `New comment on a plan you're involved in`}
          </Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {notification.planComment.plan?.user.id === cookies.signedInUser
              ? `${getNameFromUser(notification.planComment.user)} has commented on your plan`
              : `${getNameFromUser(
                  notification.planComment.user,
                )} has commented on a plan you're involved in`}
          </Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.friendRequest) {
    return (
      <ListItemButton sx={listItemStyle} onClick={() => handleClick(RoutePath.MANAGE_COMMUNITY)}>
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Community Request</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.friendRequest.sender,
          )} sent you a community request`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.friend) {
    return (
      <ListItemButton sx={listItemStyle} onClick={() => handleClick(RoutePath.MANAGE_COMMUNITY)}>
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Community Member</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.friend.friend,
          )} accepted your community request`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.achievement) {
    return (
      <ListItemButton
        sx={listItemStyle}
        onClick={() => handleClick(`${RoutePath.ACHIEVEMENT}${notification.achievement?.id}`)}
      >
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Goal completed</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>You have completed a goal!</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.message) {
    return (
      <ListItemButton sx={listItemStyle} onClick={() => handleClick(RoutePath.MESSAGES)}>
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Message</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.message.sender,
          )} sent you a message`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.messageRequest) {
    return (
      <ListItemButton sx={listItemStyle} onClick={() => handleClick(RoutePath.MESSAGES)}>
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Message Request</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.messageRequest.requester,
          )} sent you a message request`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  if (notification.messageRoom) {
    return (
      <ListItemButton sx={listItemStyle} onClick={() => handleClick(RoutePath.MESSAGES)}>
        <ListItem
          alignItems="center"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            cursor: 'pointer',
            p: 0,
          }}
        >
          <Typography sx={cardTitleFontStyles}>Message request accepted</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>{`${getNameFromUser(
            notification.messageRoom.user1,
          )} has accepted your budy search request`}</Typography>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {getDateSinceNowDisplayText(notification.date)}
          </Typography>
        </ListItem>
      </ListItemButton>
    );
  }

  return null;
};
