import { useState } from 'react';

import { Typography } from '@mui/material';

import { IAchievementDetails } from '../../../api/types/achievement';
import { cardSmallSelectFontStyles } from '../../styles/card-small-select-font-styles';
import { useColours } from '../../utils/use-colours';
import { LikesListModal } from './likes-list-modal';

export interface ILikesProps {
  achievement: IAchievementDetails;
  achievementLiked: boolean;
}

export const Likes: React.FC<ILikesProps> = ({ achievement, achievementLiked }) => {
  const colours = useColours();
  const [isLikesModalOpen, setIsLikesModalOpen] = useState(false);

  return (
    <>
      {isLikesModalOpen && (
        <LikesListModal achievement={achievement} onCloseModal={() => setIsLikesModalOpen(false)} />
      )}
      <Typography sx={cardSmallSelectFontStyles(colours)} onClick={() => setIsLikesModalOpen(true)}>
        {`${(achievement.likesLength || 0) + (achievementLiked ? 1 : 0)} likes`}
      </Typography>
    </>
  );
};
