import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ListIcon from '@mui/icons-material/List';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';

import { GET_GOAL } from '../../api/query/getGoal';
import { ActivityDetailsModal } from '../../component-library/page-layout/activity-details-modal';
import { BoulderingProgress } from '../../component-library/page-layout/bouldering-progress';
import { GoalAndAchievementMessageModal } from '../../component-library/page-layout/goal-and-achievement-message-modal';
import { GoalsModal } from '../../component-library/page-layout/goals-modal';
import { GraphPagination } from '../../component-library/page-layout/graph-pagination';
import { LeadProgress } from '../../component-library/page-layout/lead-progress';
import { LogActivityModal } from '../../component-library/page-layout/log-activity-modal';
import { IFilterState } from '../../component-library/page-layout/my-progress-filter-popover/types';
import { TopRopeProgress } from '../../component-library/page-layout/top-rope-progress';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { ClimbingType, IActivityDay, ProgressViewType } from './types';

export const MyProgressPage: React.FC = () => {
  const [tab, setTab] = useState<ClimbingType>(ClimbingType.BOULDER);
  const [isLogActivityModalOpen, setIsLogActivityModalOpen] = useState(false);
  const [goalAndAchievementMessageModalValues, setGoalAndAchievementMessageModalValues] = useState<{
    goalComplete: boolean;
    achievementSaved: boolean;
  }>({ goalComplete: false, achievementSaved: false });
  const [myGoalsModalOpen, setMyGoalsModalOpen] = useState(false);
  const [paginationValues, updatePaginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });
  const [activeDetails, setActiveDetails] = useState<IActivityDay | undefined>(undefined);
  const [viewType, setViewType] = useState<ProgressViewType>(ProgressViewType.GRAPH);

  const updateAchievementIdInActivityDay = ({
    activityId,
    achievementId,
  }: {
    activityId: string;
    achievementId: string;
  }) => {
    if (activeDetails) {
      setActiveDetails({
        ...activeDetails,
        activities: activeDetails.activities.map((a) => {
          if (a.id === activityId) {
            return {
              ...a,
              achievement: { id: achievementId },
            };
          }

          return a;
        }),
      });
    }
  };

  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error } = useQuery(GET_GOAL, {
    variables: {
      userId: cookies.signedInUser,
    },
  });

  const getGoal = () => {
    switch (tab) {
      case ClimbingType.BOULDER:
        return data?.getGoal.find((f: any) => f.type === ClimbingType.BOULDER);
      case ClimbingType.TOP_ROPE:
        return data?.getGoal.find((f: any) => f.type === ClimbingType.TOP_ROPE);
      case ClimbingType.LEAD:
        return data?.getGoal.find((f: any) => f.type === ClimbingType.LEAD);
    }
  };

  const showNotification = (): boolean => {
    if (isLogActivityModalOpen) {
      return false;
    }
    if (!myGoalsModalOpen && data?.getGoal && !loading && !error) {
      if (
        tab === ClimbingType.BOULDER &&
        !data.getGoal.find((f: any) => f.type === ClimbingType.BOULDER)
      ) {
        return true;
      }
      if (
        tab === ClimbingType.TOP_ROPE &&
        !data.getGoal.find((f: any) => f.type === ClimbingType.TOP_ROPE)
      ) {
        return true;
      }
      if (
        tab === ClimbingType.LEAD &&
        !data.getGoal.find((f: any) => f.type === ClimbingType.LEAD)
      ) {
        return true;
      }
    }
    return false;
  };

  const removeActivityFromDay = (id: string) => {
    setActiveDetails({
      ...(activeDetails as IActivityDay),
      activities: (activeDetails?.activities || []).filter((f) => f.id !== id),
    });
  };

  const initialFilterState: IFilterState = {
    completedOnly: false,
    flashedOnly: false,
    fivePlusOnly: false,
    twoToFourOnly: false,
  };
  const [filterState, setFilterState] = React.useState<IFilterState>(initialFilterState);

  const handleChangeFilterState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState({
      ...initialFilterState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleViewTypeChange = () => {
    if (viewType === ProgressViewType.GRAPH) {
      setViewType(ProgressViewType.LIST);
    } else {
      setViewType(ProgressViewType.GRAPH);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: ClimbingType) =>
    setTab(value);

  const PageContentByTab: React.ReactNode = useMemo(() => {
    switch (tab) {
      case ClimbingType.BOULDER:
        return (
          <BoulderingProgress
            viewType={viewType}
            filterState={filterState}
            paginationValues={paginationValues}
            clickItem={(value: IActivityDay) => setActiveDetails(value)}
          />
        );
      case ClimbingType.TOP_ROPE:
        return (
          <TopRopeProgress
            viewType={viewType}
            filterState={filterState}
            paginationValues={paginationValues}
            clickItem={(value: IActivityDay) => setActiveDetails(value)}
          />
        );
      case ClimbingType.LEAD:
        return (
          <LeadProgress
            viewType={viewType}
            filterState={filterState}
            paginationValues={paginationValues}
            clickItem={(value: IActivityDay) => setActiveDetails(value)}
          />
        );
      default:
        return null;
    }
  }, [filterState, paginationValues, tab, viewType]);

  const colours = useColours();

  return (
    <Stack direction="column" sx={{ backgroundColor: colours.elevatedComponentColour }}>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="Climbing type tabs"
          variant="fullWidth"
        >
          <Tab sx={{ p: 1 }} value={ClimbingType.BOULDER} label="Boulder" />
          <Tab sx={{ p: 1 }} value={ClimbingType.TOP_ROPE} label="Top Rope" />
          <Tab sx={{ p: 1 }} value={ClimbingType.LEAD} label="Lead" />
        </Tabs>
      </Box>
      <Box display={'flex'} width={'100%'} pl={2} pr={2} justifyContent={'space-between'} mb={1}>
        <Box>
          <Button
            size="small"
            sx={{ mr: 1, minWidth: 0 }}
            variant="contained"
            color={'inherit'}
            onClick={handleViewTypeChange}
          >
            {viewType === ProgressViewType.GRAPH ? <ListIcon /> : <BarChartIcon />}
          </Button>
        </Box>
        <Box>
          <Button
            size="small"
            sx={{ mr: 1, minWidth: 0 }}
            variant="contained"
            onClick={() => setMyGoalsModalOpen(true)}
          >
            <EmojiEventsIcon />
          </Button>
          <GoalsModal
            showNotification={showNotification()}
            open={myGoalsModalOpen}
            tab={tab}
            setTab={setTab}
            goals={{
              bouldering: data?.getGoal.find((f: any) => f.type === ClimbingType.BOULDER),
              topRope: data?.getGoal.find((f: any) => f.type === ClimbingType.TOP_ROPE),
              lead: data?.getGoal.find((f: any) => f.type === ClimbingType.LEAD),
            }}
            setOpen={() => setMyGoalsModalOpen(true)}
            onClose={(value) => setMyGoalsModalOpen(value)}
          />
          <Button
            size="small"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              setMyGoalsModalOpen(false);
              setIsLogActivityModalOpen(true);
            }}
          >
            Log Climb
          </Button>
        </Box>
        <LogActivityModal
          tab={tab}
          refetchActivitiesVariables={{
            userId: cookies.signedInUser,
            type: tab,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          }}
          goal={getGoal()}
          isOpen={isLogActivityModalOpen}
          onClose={() => setIsLogActivityModalOpen(false)}
          onSuccess={(goalComplete: boolean, achievementSaved: boolean) => {
            setIsLogActivityModalOpen(false);
            if (goalComplete || achievementSaved) {
              setGoalAndAchievementMessageModalValues({ goalComplete, achievementSaved });
            }
          }}
        />
        <GoalAndAchievementMessageModal
          values={goalAndAchievementMessageModalValues}
          setNewGoal={() => {
            setGoalAndAchievementMessageModalValues({
              goalComplete: false,
              achievementSaved: false,
            });
            setMyGoalsModalOpen(true);
          }}
          onClose={() =>
            setGoalAndAchievementMessageModalValues({
              goalComplete: false,
              achievementSaved: false,
            })
          }
        />
        <ActivityDetailsModal
          refetchActivitiesVariables={{
            userId: cookies.signedInUser,
            type: tab,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          }}
          updateAchievementIdInActivityDay={updateAchievementIdInActivityDay}
          activityDay={activeDetails}
          goal={getGoal()}
          onClose={() => setActiveDetails(undefined)}
          removeActivityFromDay={removeActivityFromDay}
        />
      </Box>
      {PageContentByTab}
      <GraphPagination
        offset={paginationValues.offset}
        limit={paginationValues.limit}
        updatePaginationValues={updatePaginationValues}
        filterState={filterState}
        handleChangeFilterState={handleChangeFilterState}
      />
    </Stack>
  );
};
