import React from 'react';

import { Box, Button, Card, Grid, Typography } from '@mui/material';

import { IProfile } from '../../../api/types/profile';
import { IColours } from '../../../constants/colours';
import { EBoulderingGradeType, ERopeGradeType } from '../../../constants/grade-types';
import { ClimbingType } from '../../../pages/my-progress/types';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { numberToGrade } from '../progress-graph/utils/number-to-grade';

interface IProfileOverviewStatsProps {
  profile?: IProfile;
}

const TitleRowGridItem = ({ title, colours }: { title?: string; colours: IColours }) => {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        {title && <Typography sx={cardSmallSubtitleFontStyles(colours)}>{title}</Typography>}
      </Box>
    </Grid>
  );
};

const TopRowGridItem = ({ subtitle, colours }: { subtitle?: string; colours: IColours }) => {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 1,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          p: 1,
        }}
      >
        {subtitle && <Typography sx={cardSmallSubtitleFontStyles(colours)}>{subtitle}</Typography>}
      </Box>
    </Grid>
  );
};

const SecondRowGridTitleItem = ({ title, colours }: { title: string; colours: IColours }) => {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Typography sx={cardSmallSubtitleFontStyles(colours)}>{title}</Typography>
      </Box>
    </Grid>
  );
};

const SecondRowGridItem = ({
  grade,
  type,
  boulderingGradeType,
  ropeGradeType,
  colours,
}: {
  typeString: string;
  grade?: string;
  boulderingGradeType: EBoulderingGradeType;
  ropeGradeType: ERopeGradeType;
  type: ClimbingType;
  colours: IColours;
}) => {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        {grade ? (
          <Button variant="outlined" size="small" sx={{ textTransform: 'none' }}>
            {numberToGrade({
              boulderingGradeType,
              ropeGradeType,
              grade: grade.toString(),
              type,
            })}
          </Button>
        ) : (
          <Typography sx={cardSmallSubtitleFontStyles(colours)}>N/A</Typography>
        )}
      </Box>
    </Grid>
  );
};

export const ProfileOverviewStats: React.FC<IProfileOverviewStatsProps> = ({ profile }) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  return (
    <Card sx={{ m: 2, p: 2, backgroundColor: colours.elevatedComponentColour }}>
      <Grid container>
        {TitleRowGridItem({
          title: '',
          colours,
        })}
        {TitleRowGridItem({
          title: 'Boulder',
          colours,
        })}
        {TitleRowGridItem({
          title: 'Top Rope',
          colours,
        })}
        {TitleRowGridItem({
          title: 'Lead',
          colours,
        })}
      </Grid>
      <Grid container>
        {TopRowGridItem({
          subtitle: 'Total',
          colours,
        })}
        {TopRowGridItem({
          subtitle: profile?.totalBoulderClimbNumber.toString(),
          colours,
        })}
        {TopRowGridItem({
          subtitle: profile?.totalTopRopeClimbNumber.toString(),
          colours,
        })}
        {TopRowGridItem({
          subtitle: profile?.totalLeadClimbNumber.toString(),
          colours,
        })}
      </Grid>
      <Grid container>
        {SecondRowGridTitleItem({
          title: 'Best',
          colours,
        })}
        {SecondRowGridItem({
          grade: profile?.highestBoulderClimb?.grade,
          type: ClimbingType.BOULDER,
          typeString: 'Boulder',
          ropeGradeType: cookies.ropeGradePreference,
          boulderingGradeType: cookies.boulderingGradePreference,
          colours,
        })}
        {SecondRowGridItem({
          grade: profile?.highestTopRopeClimb?.grade,
          type: ClimbingType.TOP_ROPE,
          typeString: 'Top Rope',
          ropeGradeType: cookies.ropeGradePreference,
          boulderingGradeType: cookies.boulderingGradePreference,
          colours,
        })}
        {SecondRowGridItem({
          grade: profile?.highestLeadClimb?.grade,
          type: ClimbingType.LEAD,
          typeString: 'Lead',
          ropeGradeType: cookies.ropeGradePreference,
          boulderingGradeType: cookies.boulderingGradePreference,
          colours,
        })}
      </Grid>
    </Card>
  );
};
