import { gql } from '@apollo/client';

export const GET_COMMUNITY_ACHIEVEMENTS = gql`
  query GetCommunityAchievements($userId: String!, $limit: Int!, $offset: Int!) {
    getCommunityAchievements(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        date
        comment
        reported
        user {
          id
          firstName
          lastName
          isClub
        }
        activity {
          id
          attempts
          comment
          completed
          grade
          intensity
          type
        }
        goal {
          id
          type
          active
          grade
          flash
          targetDate
          beginningDate
          completionDate
        }
        commentsLength
        likesLength
        likeFilled
      }
      count
    }
  }
`;
