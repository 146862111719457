import React, { useState } from 'react';

import { encrypt } from 'n-krypta';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { UPDATE_PASSWORD } from '../../../api/mutation/updatePassword';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { UpdatePasswordFormSchema } from '../../../validation/schemas/update-password';
import { UpdatePasswordFormInputs } from '../../../validation/schemas/update-password/types';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { ToastNotification } from '../../components/toast-notification';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

/**
 * This page contains all the details of the logged in user.
 * It should only be visible to logged in users.
 * Only the logged in user can see their own account details.
 */
export const UpdatePassword: React.FC = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePasswordFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(UpdatePasswordFormSchema),
  });

  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [successToastDisplay, setSuccessToastDisplay] = useState(false);
  const [currentPwdShown, setCurrentPwdShown] = useState(false);
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  const [updatePassword, { loading, error }] = useMutation(UPDATE_PASSWORD);

  const handleUpdatePasswordSubmit = (formData: UpdatePasswordFormInputs) => {
    const encryptedCurrentPassword = encrypt(formData.currentPassword, ENCRYPTION_SECRET);
    const encryptedNewPassword = encrypt(formData.newPassword?.trim(), ENCRYPTION_SECRET);
    updatePassword({
      variables: {
        userId: cookies.signedInUser,
        currentPassword: encryptedCurrentPassword,
        newPassword: encryptedNewPassword,
      },
    }).then(() => {
      setSuccessToastDisplay(true);
      setValue('currentPassword', '');
      setValue('confirmPassword', '');
      setValue('newPassword', '');
    });
  };

  const togglePasswordVisibility = (id: string) => {
    if (id === 'currentPassword') {
      setCurrentPwdShown(!currentPwdShown);
    } else if (id === 'newPassword') {
      setNewPwdShown(!newPwdShown);
    } else if (id === 'confirmPassword') {
      setConfirmPwdShown(!confirmPwdShown);
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <ToastNotification
        isOpen={successToastDisplay}
        message={'New password saved!'}
        onClose={() => setSuccessToastDisplay(false)}
      />
      <form onSubmit={handleSubmit(handleUpdatePasswordSubmit)}>
        <CardHeader
          avatar={<KeyIcon sx={{ color: colours.subtextColour }} />}
          title={<Typography sx={cardSubtitleFontStyles(colours)}>Update password</Typography>}
        />
        <CardContent>
          <TextField
            fullWidth
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.currentPassword?.message)}
            helperText={errors.currentPassword?.message}
            id="currentPassword"
            label="Current Password"
            variant="outlined"
            type={currentPwdShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon
                    onClick={() => {
                      togglePasswordVisibility('currentPassword');
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
            {...register('currentPassword')}
          />
          <TextField
            fullWidth
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.newPassword?.message)}
            helperText={errors.newPassword?.message}
            id="newPassword"
            label="New Password"
            variant="outlined"
            type={newPwdShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon
                    onClick={() => {
                      togglePasswordVisibility('newPassword');
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
            {...register('newPassword')}
          />
          <TextField
            fullWidth
            sx={{ mb: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.confirmPassword?.message)}
            helperText={errors.confirmPassword?.message}
            id="confirmPassword"
            label="Confirm password"
            variant="outlined"
            type={confirmPwdShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon
                    onClick={() => {
                      togglePasswordVisibility('confirmPassword');
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
            {...register('confirmPassword')}
          />
          <ErrorAlert
            message={Boolean(error?.message.includes('Incorrect')) ? error?.message : undefined}
            showError={Boolean(error?.message)}
          />
          <SubmitButton label={'Update Password'} loading={loading} />
        </CardContent>
      </form>
    </Card>
  );
};
