import React, { useEffect } from 'react';

import ConfettiExplosion from 'react-confetti-explosion';

import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { ModalHeader } from '../../components/modal-header';
import { cardBodyFontStyles } from '../../styles/card-font-styles';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';

interface IGoalAndAchievementMessageModalProps {
  onClose: () => void;
  setNewGoal: () => void;
  values: { goalComplete: boolean; achievementSaved: boolean };
}

export const GoalAndAchievementMessageModal: React.FC<IGoalAndAchievementMessageModalProps> = ({
  values,
  setNewGoal,
  onClose,
}) => {
  const colours = useColours();

  const getTitle = () => {
    if (values.goalComplete) {
      return 'Goal complete! 🎉';
    }

    return 'Climb shared!';
  };

  const [isExploding, setIsExploding] = React.useState(false);
  useEffect(() => {
    if (values.goalComplete) {
      setIsExploding(true);
    }
  }, [values.goalComplete]);

  return (
    <>
      <Dialog open={values.achievementSaved || values.goalComplete} onClose={onClose}>
        {isExploding && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ConfettiExplosion duration={4000} zIndex={100000} />
          </Box>
        )}
        <ModalHeader onClose={onClose} title={getTitle()} />
        <DialogContent sx={{ padding: 2 }} dividers>
          {Boolean(values.goalComplete) && (
            <Box mb={2}>
              {`Congratulations, you have completed your goal!`}
              <Typography sx={{ ...cardBodyFontStyles(colours) }}></Typography>
              <Typography sx={cardSmallSubtitleFontStyles(colours)}>
                {`You should now consider setting a new goal!`}
              </Typography>
            </Box>
          )}
          {Boolean(values.achievementSaved) && (
            <Box>
              {`This climb has ${values.goalComplete ? 'also ' : ''}been shared to your community.`}
              <Typography sx={cardBodyFontStyles(colours)}></Typography>
              <Typography
                sx={cardSmallSubtitleFontStyles(colours)}
              >{`It will be visible to members of your community on the Community page and on your profile.`}</Typography>
            </Box>
          )}
          {Boolean(values.goalComplete) && (
            <Button sx={{ mt: 2 }} fullWidth variant="contained" onClick={setNewGoal}>
              Set new goal
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
