import { gql } from '@apollo/client';

export const CREATE_CLUB = gql`
  mutation ClubSignup(
    $email: String!
    $password: String!
    $firstName: String!
    $isClubPrivate: Boolean!
  ) {
    clubSignup(
      email: $email
      password: $password
      firstName: $firstName
      isClubPrivate: $isClubPrivate
    ) {
      id
      email
      firstName
    }
  }
`;
