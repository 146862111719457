import React from 'react';

import { useNavigate } from 'react-router';

import LogoutIcon from '@mui/icons-material/Logout';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { CookieNames, RoutePath } from '../../../constants';
import { AvailableLogin, SignedInUserType } from '../../../constants/cookie-names';
import { getDateInFuture } from '../../utils/get-date-in-future';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface ILogoutNavItemProps {
  isClub?: boolean;
}

export const LogoutNavItem: React.FC<ILogoutNavItemProps> = ({ isClub }) => {
  const navigate = useNavigate();
  const colours = useColours();

  const { cookies, removeCookie, setCookie } = useClimbingCommunityCookies();

  const handleOnClick = () => {
    setCookie(
      CookieNames.AVAILABLE_LOGINS,
      [
        ...(cookies.availableLogins ?? []).filter(
          (f: AvailableLogin) => f.type !== SignedInUserType.USER,
        ),
      ],
      {
        expires: getDateInFuture({ daysInFuture: 60 }),
      },
    );
    removeCookie(CookieNames.SIGNED_IN_USER);
    if (isClub) {
      removeCookie(CookieNames.SIGNED_IN_CLUB);
    }
    removeCookie(CookieNames.ROPE_GRADE_PREFERENCE);
    removeCookie(CookieNames.BOULDERING_GRADE_PREFERENCE);
    navigate(isClub ? RoutePath.CLUB_AUTHENTICATION : RoutePath.AUTHENTICATION);
  };

  // We don't want to give the ability to logout if they're not logged in.
  if (!cookies?.signedInUser) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleOnClick} data-testid="logout-nav-item-button">
        <ListItemIcon sx={{ color: colours.primaryInverseColour }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={'Logout'} sx={{ color: colours.primaryInverseColour }} />
      </ListItemButton>
    </ListItem>
  );
};
