import { gql } from '@apollo/client';

export const BUDDY_SEARCH_CREATE = gql`
  mutation BuddySearchCreate($userId: String!, $input: BuddySearchInput!) {
    buddySearchCreate(userId: $userId, input: $input) {
      gym
      ability
      additionalInformation
      bouldering
      topRope
      lead
      monAm
      monPm
      tueAm
      tuePm
      wedAm
      wedPm
      thuAm
      thuPm
      friAm
      friPm
      satAm
      satPm
      sunAm
      sunPm
    }
  }
`;
