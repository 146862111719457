import { gql } from '@apollo/client';

export const ADD_PLAN = gql`
  mutation AddPlan($input: PlanInput!) {
    addPlan(input: $input) {
      id
      location
      datePosted
      planDateTime
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
