import React, { useState } from 'react';

import { Box, Divider, Grid, Stack, Tab, Tabs } from '@mui/material';

import { HalfBanner } from '../../component-library/components/half-banner';
import { BuddySearchForm } from '../../component-library/page-layout/buddy-search-form';
import { BuddySearchList } from '../../component-library/page-layout/buddy-search-list';
import { MyListings } from '../../component-library/page-layout/my-listings';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { useIsMobile } from '../../component-library/utils/use-is-mobile';
import { BuddySearchListType } from './types';

/**
 * This page will be the base of the buddy search system
 */

export const BuddySearchPage: React.FC = () => {
  const { cookies } = useClimbingCommunityCookies();
  const [tab, setTab] = useState<BuddySearchListType>(BuddySearchListType.SEARCH);

  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: BuddySearchListType,
  ) => setTab(value);

  const isMobile = useIsMobile();
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <HalfBanner
        backgroundImage="url(/images/buddy-finder-banner.jpeg)"
        title="Buddy Search"
        bodyText={
          <>
            {`Find a climbing partner with ease using the Climbing Community buddy search.`}
            <br />
            {`Search for existing listings that match your availability in the gym you want to climb in or create your own unique listing that other people can request to join.`}
          </>
        }
        subtitle="(Beta)"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {cookies.signedInUser && (
            <Tabs
              sx={{ margin: 2, marginBottom: 0 }}
              value={tab}
              onChange={handleTabChange}
              aria-label="Search and My listings tabs"
              variant="fullWidth"
            >
              <Tab value={BuddySearchListType.SEARCH} label="Search" />
              <Tab value={BuddySearchListType.MY_LISTINGS} label="My Listings" />
            </Tabs>
          )}
          <Box p={2}>
            {tab === BuddySearchListType.SEARCH ? <BuddySearchList /> : <MyListings />}
          </Box>
        </Grid>
        {isMobile && (
          <Divider sx={{ width: '100%' }} component="div" role="presentation" variant="middle" />
        )}
        <Grid item xs={12} md={6}>
          <Box p={2}>
            <BuddySearchForm />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
