import * as Yup from 'yup';

import { yupAbilityValidation, yupGymValidation, yupOptionalBoolean } from '../../yup';
import { BuddySearchInputs } from './types';

export const BuddySearchFormSchema: Yup.SchemaOf<BuddySearchInputs> = Yup.object({
  ability: yupAbilityValidation,
  gym: yupGymValidation,
  additionalInformation: yupAbilityValidation,
  bouldering: Yup.bool().test(
    'oneOfRequired',
    'One of bouldering, topRope or lead must be selected',
    function (value) {
      return this.parent.topRope || this.parent.lead || value;
    },
  ),
  topRope: yupOptionalBoolean,
  lead: yupOptionalBoolean,
  monAm: yupOptionalBoolean,
  monPm: yupOptionalBoolean,
  tueAm: yupOptionalBoolean,
  tuePm: yupOptionalBoolean,
  wedAm: yupOptionalBoolean,
  wedPm: yupOptionalBoolean,
  thuAm: yupOptionalBoolean,
  thuPm: yupOptionalBoolean,
  friAm: yupOptionalBoolean,
  friPm: yupOptionalBoolean,
  satAm: yupOptionalBoolean,
  satPm: yupOptionalBoolean,
  sunAm: yupOptionalBoolean,
  sunPm: yupOptionalBoolean,
});
