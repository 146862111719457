/**
 * The cookie names we want to use throughout the website.
 *
 * signedInEmail - is used to determine whether or not the user has signed in,
 * and can be used to gether the users information on return to the website.
 *  */
export enum CookieNames {
  SIGNED_IN_USER = 'signedInUser',
  BOULDERING_GRADE_PREFERENCE = 'boulderingGradePreference',
  ROPE_GRADE_PREFERENCE = 'ropeGradePreference',
  SIGNED_IN_CLUB = 'signedInClub',
  AVAILABLE_LOGINS = 'availableLogins',
}

export enum SignedInUserType {
  USER = 'user',
  CLUB = 'club',
}

export interface AvailableLogin {
  id: string;
  type: SignedInUserType;
}
