import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';

import { GET_MESSAGE_REQUESTS } from '../../api/query/getMessageRequests';
import { GET_MESSAGE_ROOMS } from '../../api/query/getMessageRooms';
import { IMessageRequest } from '../../api/types/message-request';
import { IMessageRoom } from '../../api/types/message-room';
import { MessageContent } from '../../component-library/page-layout/message-content';
import { MessageList } from '../../component-library/page-layout/message-list';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { useIsMobile } from '../../component-library/utils/use-is-mobile';
import { COMPONENT_HEIGHTS } from '../../constants/component-dimentions';
import { POLLING_INTERVAL } from '../../constants/polling-interval';

export const MessagesPage: React.FC = () => {
  const { cookies } = useClimbingCommunityCookies();

  const [selectedRequestId, setSelectedRequestId] = useState<string | undefined>();
  const [selectedMessageId, setSelectedMessageId] = useState<string | undefined>();

  const handleCloseMessage = () => {
    setSelectedRequestId(undefined);
    setSelectedMessageId(undefined);
  };

  const resetLists = () => {
    setSelectedMessageId(undefined);
    setSelectedRequestId(undefined);
  };

  const handleSetSelectedRequestId = (id: string) => {
    setSelectedMessageId(undefined);
    setSelectedRequestId(id);
  };

  const handleSetSelectedMessageId = (id: string) => {
    setSelectedRequestId(undefined);
    setSelectedMessageId(id);
  };

  const {
    data: messageRequests,
    loading: messageRequestsLoading,
    error: messageRequestsError,
    startPolling: startRequestPolling,
  } = useQuery(GET_MESSAGE_REQUESTS, {
    variables: { userId: cookies.signedInUser },
  });

  const {
    data: messageRooms,
    loading: messageRoomsLoading,
    error: messageRoomsError,
    startPolling: startMessagePolling,
  } = useQuery(GET_MESSAGE_ROOMS, {
    variables: { userId: cookies.signedInUser },
  });

  useEffect(() => {
    startMessagePolling(POLLING_INTERVAL);
    startRequestPolling(POLLING_INTERVAL);
  }, [startMessagePolling, startRequestPolling]);

  const isRequest = Boolean(selectedRequestId);

  const isMobile = useIsMobile();
  return (
    <Grid
      container
      mt={0}
      sx={{ height: `calc(${window.innerHeight}px - ${COMPONENT_HEIGHTS.HEADER})` }}
    >
      {!isMobile || (isMobile && !selectedRequestId && !selectedMessageId) ? (
        <Grid item xs={12} sm={4} lg={3}>
          <MessageList
            messages={messageRooms?.getMessageRooms}
            requests={messageRequests?.getMessageRequests}
            handleSetSelectedRequestId={handleSetSelectedRequestId}
            handleSetSelectedMessageId={handleSetSelectedMessageId}
            messageRoomsLoading={messageRoomsLoading}
            messageRoomsError={messageRoomsError}
            messageRequestsLoading={messageRequestsLoading}
            messageRequestsError={messageRequestsError}
          />
        </Grid>
      ) : null}
      {(selectedRequestId || selectedMessageId) && (
        <Grid item xs={12} sm={8} lg={9} sx={{ height: '100%' }}>
          <MessageContent
            isRequest={isRequest}
            selectedMessage={messageRooms?.getMessageRooms.find(
              (f: IMessageRoom) => f.id === selectedMessageId,
            )}
            request={messageRequests?.getMessageRequests?.find(
              (f: IMessageRequest) => f.id === selectedRequestId,
            )}
            resetLists={resetLists}
            closeMessage={handleCloseMessage}
          />
        </Grid>
      )}
    </Grid>
  );
};
