import React from 'react';

import { Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { IBuddySearch } from '../../../../api/types';
import { ProfilePicture } from '../../../components/profile-picture';

interface IBuddySearchListItem {
  idx: number;
  item: IBuddySearch;
  otherListingsItem?: boolean;
  onClickBuddySearchItem: (idx: number) => void;
}

export const BuddySearchListItem: React.FC<IBuddySearchListItem> = ({
  idx,
  item,
  otherListingsItem,
  onClickBuddySearchItem,
}) => {
  const primaryText = () => {
    const text = [];
    if (item.bouldering) {
      text.push('Bouldering');
    }
    if (item.topRope) {
      text.push('Top Rope');
    }
    if (item.lead) {
      text.push('Lead Climbing');
    }
    return text.join(', ');
  };

  return (
    <>
      <ListItem
        alignItems="center"
        sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}
        onClick={() => onClickBuddySearchItem(idx)}
      >
        <Box sx={{ width: 50, height: 50, mr: 2 }}>
          <ProfilePicture userId={item.user.id} navigable />
        </Box>
        <ListItemText
          primary={primaryText()}
          secondary={
            <>
              <Typography
                sx={{ display: 'inline', textTransform: 'capitalize' }}
                component="span"
                variant="body2"
                color="text.primaryInverseColour"
              >
                {otherListingsItem ? item.gym : `${item.user.firstName} ${item.user.lastName}`}
              </Typography>

              <Typography component="span" sx={{ display: 'inline' }}>
                {Boolean(item.additionalInformation) && ` — ${item.additionalInformation}`}
              </Typography>

              <Typography
                sx={{ display: 'block' }}
                color="text.primaryInverseColour"
                component="span"
                variant="subtitle2"
              >
                {item.ability}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
};
