import { useState } from 'react';
import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';

import { STAR_ACTIVITY } from '../../../api/mutation/starActivity';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getCommunityAchievements';
import { GET_USER_ACTIVITIES } from '../../../api/query/getUserActivities';
import { IActivityDetails } from '../../../pages/my-progress/types';
import { getClimbingTitleFromType } from '../../../utils/get-climbing-title-from-type';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ModalHeader } from '../../components/modal-header';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getDateSinceNowDisplayText } from '../../utils/get-get-since-now-display-text';
import { getNumberOfAttempts } from '../../utils/get-number-of-attempts';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { numberToGrade } from '../progress-graph/utils/number-to-grade';

interface IAddNewAchievementModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  refetchCommunityActivitiesVariables: any;
}

export const AddNewAchievementModal: React.FC<IAddNewAchievementModalProps> = ({
  isOpen,
  onCloseModal,
  refetchCommunityActivitiesVariables,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 5,
    offset: 0,
  });

  const { loading, error, data, fetchMore } = useQuery(GET_USER_ACTIVITIES, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  const [starActivity, { loading: loadingStar, error: errorStar }] = useMutation(STAR_ACTIVITY, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_COMMUNITY_ACHIEVEMENTS,
          variables: refetchCommunityActivitiesVariables,
        },
        (data) => ({
          getCommunityAchievements: {
            data: [result?.data?.starActivity, ...(data?.getCommunityAchievements?.data || [])],
            count: data?.getCommunityAchievements?.count + 1,
          },
        }),
      );
      cache.updateQuery(
        {
          query: GET_USER_ACTIVITIES,
          variables: {
            userId: cookies.signedInUser,
            limit: paginationValues.limit,
            offset: paginationValues.offset,
          },
        },
        (data) => ({
          getUserActivities: {
            data: [
              ...(data?.getUserActivities?.data || []).filter(
                (ac: IActivityDetails) => ac.id !== result?.data?.starActivity?.activity?.id,
              ),
            ],
            count: data?.getUserActivities?.count,
          },
        }),
      );
    },
  });

  const onSelect = (item: IActivityDetails) => {
    starActivity({ variables: { id: item.id, userId: cookies.signedInUser } }).then(() => {
      onCloseModal();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal} title={'Add achievement'} />
      <DialogContent sx={{ p: 0, minWidth: '300px' }}>
        {(loading || loadingStar) && (
          <LoadingSpinner label={loading ? 'Loading activities...' : 'Saving achievement...'} />
        )}
        <ErrorAlert showError={Boolean(error?.message || errorStar?.message)} />
        {Boolean(!data?.getUserActivities?.data?.length) && (
          <Box sx={{ p: 2 }}>
            <Alert
              sx={{ width: '100%' }}
              severity="info"
            >{`You don't have any logged activities!`}</Alert>
          </Box>
        )}
        {Boolean(!(loading || loadingStar) && data?.getUserActivities?.data?.length) ? (
          <List
            sx={{
              bgcolor: 'background.paper',
              paddingBottom: 0,
              maxHeight: `calc(${window.innerHeight}px - 170px - 56px)`,
              overflow: 'auto',
            }}
          >
            {data?.getUserActivities?.data?.map((item: IActivityDetails, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  <ListItemButton
                    sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    onClick={() => onSelect(item)}
                  >
                    <ListItem
                      alignItems="center"
                      sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box>
                          <Typography sx={cardSubtitleFontStyles(colours)}>
                            {getClimbingTitleFromType(item.type)}
                          </Typography>
                          <Typography sx={cardSubtitleFontStyles(colours)}>
                            {getDateSinceNowDisplayText(item.activityDay?.date)}
                          </Typography>
                          <Typography sx={cardTitleFontStyles}>
                            {`${numberToGrade({
                              boulderingGradeType: cookies.boulderingGradePreference,
                              ropeGradeType: cookies.ropeGradePreference,
                              grade: item.grade,
                              type: item.type,
                            })} — ${getNumberOfAttempts(item.attempts, item.completed)}`}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {item.completed ? (
                            <CheckCircleIcon color="primary" />
                          ) : (
                            <CancelIcon color="action" />
                          )}
                        </Box>
                      </Box>
                    </ListItem>
                  </ListItemButton>
                  <Divider variant="fullWidth" component="li" />
                </React.Fragment>
              );
            })}

            {Boolean(
              data?.getUserActivities?.data?.length > 0 &&
                data?.getUserActivities?.data?.length < data?.getUserActivities?.count,
            ) && (
              <Box sx={{ p: 2 }}>
                <SubmitButton
                  label={'Load more'}
                  loading={loading}
                  onClick={() =>
                    fetchMore({
                      variables: {
                        offset: data?.getUserActivities?.data.length,
                      },
                      updateQuery: (previousResult, { fetchMoreResult }) => {
                        return {
                          getUserActivities: {
                            data: [
                              ...previousResult.getUserActivities.data,
                              ...fetchMoreResult.getUserActivities.data,
                            ],
                            count: fetchMoreResult.getUserActivities.count,
                          },
                        };
                      },
                    })
                  }
                />
              </Box>
            )}
          </List>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
