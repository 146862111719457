import React, { useEffect, useState } from 'react';

import { encrypt } from 'n-krypta';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CREATE_USER } from '../../../api/mutation/userSignup';
import { CookieNames, RoutePath } from '../../../constants';
import { SignedInUserType } from '../../../constants/cookie-names';
import { ENCRYPTION_SECRET } from '../../../constants/encryption-secret';
import { SignUpFormInputs, SignUpFormSchema } from '../../../validation/schemas';
import { ErrorAlert } from '../../components/error-alert';
import { RouterLink } from '../../components/router-link';
import { SubmitButton } from '../../components/submit-button';
import { cardSmallActionFontStyles } from '../../styles/card-small-action-font-styles';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { getDateInFuture } from '../../utils/get-date-in-future';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

/**
 * The form for the sign up journey.
 * Includes Firstname, Lastname, Email, Password and Confirm password.
 */
export const SignUpContent: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<SignUpFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(SignUpFormSchema),
  });

  const colours = useColours();

  const [passwordShown, setPasswordShown] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

  const { setCookie, cookies } = useClimbingCommunityCookies();

  const [userSignup, { loading, error }] = useMutation(CREATE_USER);

  const navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    setValue('acceptedPrivacyPolicy', acceptedPrivacyPolicy);
    if (acceptedPrivacyPolicy) {
      trigger('acceptedPrivacyPolicy');
    }
  }, [acceptedPrivacyPolicy, setValue, trigger]);

  const onSubmit: SubmitHandler<SignUpFormInputs> = (data) => {
    const encryptedPassword = encrypt(data.password?.trim(), ENCRYPTION_SECRET);
    userSignup({
      variables: {
        email: data.email?.trim(),
        password: encryptedPassword,
        firstName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
      },
    }).then((result) => {
      setCookie(CookieNames.SIGNED_IN_USER, result.data.userSignup.id, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(
        CookieNames.BOULDERING_GRADE_PREFERENCE,
        result.data.userSignup.boulderingGradeType,
        {
          expires: getDateInFuture({ daysInFuture: 60 }),
        },
      );
      setCookie(CookieNames.ROPE_GRADE_PREFERENCE, result.data.userSignup.ropeGradeType, {
        expires: getDateInFuture({ daysInFuture: 60 }),
      });
      setCookie(
        CookieNames.AVAILABLE_LOGINS,
        Array.from(
          new Set([
            ...(cookies.availableLogins ?? []),
            { id: result.data.userSignup.id, type: SignedInUserType.USER },
          ]),
        ),
        {
          expires: getDateInFuture({ daysInFuture: 60 }),
        },
      );
      navigate(RoutePath.HELP);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <TextField
            fullWidth
            error={Boolean(errors.firstName?.message)}
            helperText={errors.firstName?.message}
            id="firstName"
            label="First name"
            variant="outlined"
            {...register('firstName')}
          />
          <TextField
            fullWidth
            error={Boolean(errors.lastName?.message)}
            helperText={errors.lastName?.message}
            id="lastName"
            label="Last name"
            variant="outlined"
            {...register('lastName')}
          />
          <TextField
            fullWidth
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            id="email"
            label="Email"
            variant="outlined"
            {...register('email')}
          />
          <TextField
            fullWidth
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            id="password"
            label="Password"
            variant="outlined"
            type={passwordShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon onClick={togglePasswordVisiblity} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }}
            {...register('password')}
          />

          <TextField
            fullWidth
            error={Boolean(errors.confirmPassword?.message)}
            helperText={errors.confirmPassword?.message}
            id="confirmPassword"
            label="Confirm password"
            variant="outlined"
            type={passwordShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityIcon onClick={togglePasswordVisiblity} sx={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
            }}
            {...register('confirmPassword')}
          />
          <ErrorAlert showError={Boolean(error)} />
          <FormControl required error={true} component="fieldset" sx={{ m: 3 }} variant="standard">
            <FormControlLabel
              onChange={() => setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}
              control={
                <Controller
                  name="acceptedPrivacyPolicy"
                  control={control}
                  render={({ field }) => <Checkbox {...field} />}
                />
              }
              label={
                <Typography display={'inline'} sx={cardSmallSubtitleFontStyles(colours)}>
                  {`By selecting "Sign up"; you are confirming that you have read and agree to Climbing
                Community's `}
                  <RouterLink sx={{ display: 'inline' }} href={RoutePath.PRIVACY_POLICY}>
                    <Typography display={'inline'} sx={cardSmallActionFontStyles(colours)}>
                      Privacy Policy
                    </Typography>
                  </RouterLink>
                </Typography>
              }
            />
            {Boolean(errors.acceptedPrivacyPolicy?.message) && (
              <FormHelperText>You must confirm before signing up</FormHelperText>
            )}
          </FormControl>
          <SubmitButton label="Sign up" loading={loading} type="submit" variant="contained" />
        </Stack>
      </form>
    </>
  );
};
