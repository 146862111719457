import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';

import { UPDATE_USER_SETTINGS } from '../../../api/mutation/updateUserSettings';
import { GET_USER } from '../../../api/query/getUser';
import { IUser } from '../../../api/types';
import { CookieNames } from '../../../constants';
import { EBoulderingGradeType, ERopeGradeType } from '../../../constants/grade-types';
import { LoadingSpinner } from '../../components/loading-spinner';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IUpdateUserOptionsProps {
  user: IUser;
  loading: boolean;
}

export const UpdateUserOptions: React.FC<IUpdateUserOptionsProps> = ({ user }) => {
  const colours = useColours();
  const { cookies, setCookie } = useClimbingCommunityCookies();

  useEffect(() => {
    if (user) {
      setHideGoals(user.hideGoals);
      setHideSummary(user.hideSummary);
      setAutoPostGoalDisabled(user.autoPostGoalDisabled);
    }
  }, [user?.hideGoals, user?.hideSummary, user?.autoPostGoalDisabled, user]);

  const [hideGoals, setHideGoals] = useState(Boolean(user?.hideGoals));
  const [hideSummary, setHideSummary] = useState(Boolean(user?.hideSummary));
  const [autoPostGoalDisabled, setAutoPostGoalDisabled] = useState(
    Boolean(user?.autoPostGoalDisabled),
  );

  const [updateUserSettings, { loading }] = useMutation(UPDATE_USER_SETTINGS);

  const handleChangeHideGoals = () => {
    const newValue = !hideGoals;
    updateUserSettings({
      variables: { userId: cookies.signedInUser, hideGoals: newValue },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              hideGoals: newValue,
            },
          }),
        );
      },
    }).then(() => {
      setHideGoals(newValue);
    });
  };

  const handleChangeHideSummary = () => {
    const newValue = !hideSummary;
    updateUserSettings({
      variables: { userId: cookies.signedInUser, hideSummary: newValue },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              hideSummary: newValue,
            },
          }),
        );
      },
    }).then(() => {
      setHideSummary(newValue);
    });
  };

  const handleChangeAutoPostGoalDisabled = () => {
    const newValue = !autoPostGoalDisabled;
    updateUserSettings({
      variables: { userId: cookies.signedInUser, autoPostGoalDisabled: newValue },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              autoPostGoalDisabled: newValue,
            },
          }),
        );
      },
    }).then(() => {
      setAutoPostGoalDisabled(newValue);
    });
  };

  const handleChangeBoulderingType = (event: SelectChangeEvent<string>) => {
    const gradeType = event.target.value as EBoulderingGradeType;
    updateUserSettings({
      variables: { userId: cookies.signedInUser, boulderingGradeType: gradeType },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              boulderingGradeType: gradeType,
            },
          }),
        );
      },
    }).then(() => {
      setCookie(CookieNames.BOULDERING_GRADE_PREFERENCE, gradeType);
    });
  };

  const handleChangeRopeType = (event: SelectChangeEvent<string>) => {
    const gradeType = event.target.value as ERopeGradeType;
    updateUserSettings({
      variables: { userId: cookies.signedInUser, ropeGradeType: gradeType },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              ropeGradeType: gradeType,
            },
          }),
        );
      },
    }).then(() => {
      setCookie(CookieNames.ROPE_GRADE_PREFERENCE, gradeType);
    });
  };

  if (loading) {
    <Card sx={{ width: '100%' }}>
      <LoadingSpinner />
    </Card>;
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        avatar={<SettingsIcon sx={{ color: colours.subtextColour }} />}
        title={<Typography sx={cardSubtitleFontStyles(colours)}>Update user options</Typography>}
      />
      <CardContent>
        <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <FormControlLabel
            value={hideGoals}
            control={
              <Switch
                name="hideGoals"
                disabled={loading}
                checked={hideGoals}
                onClick={handleChangeHideGoals}
              />
            }
            label="Hide Goals from Profile"
          />
        </FormGroup>

        <FormControl sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                name="hideSummary"
                disabled={loading}
                checked={hideSummary}
                onClick={handleChangeHideSummary}
              />
            }
            label="Hide Summary from Profile"
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                name="autoPostGoalDisabled"
                disabled={loading}
                checked={autoPostGoalDisabled}
                onClick={handleChangeAutoPostGoalDisabled}
              />
            }
            label="Disable goal posting"
          />
        </FormControl>

        <FormControl sx={{ mt: 1, mb: 2 }} fullWidth>
          <InputLabel id="select-bouldering-grade">Bouldering grading</InputLabel>
          <Select
            id="select-bouldering-grade"
            disabled={loading}
            sx={{ backgroundColor: colours.primaryInverseColour }}
            variant="outlined"
            fullWidth
            label="Bouldering Grade"
            defaultValue={cookies.boulderingGradePreference}
            onChange={handleChangeBoulderingType}
          >
            <MenuItem value={EBoulderingGradeType.V_SCALE}>V Scale</MenuItem>
            <MenuItem value={EBoulderingGradeType.FONT_SCALE}>Font Scale</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="select-rope-grade">Rope grading</InputLabel>
          <Select
            id="select-rope-grade"
            disabled={loading}
            sx={{ backgroundColor: colours.primaryInverseColour }}
            variant="outlined"
            fullWidth
            label="Rope Grade"
            defaultValue={cookies.ropeGradePreference}
            onChange={handleChangeRopeType}
          >
            <MenuItem value={ERopeGradeType.FRENCH}>French</MenuItem>
            <MenuItem value={ERopeGradeType.YDS}>YDS</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};
