import { useState } from 'react';

import ReportIcon from '@mui/icons-material/Report';
import { Alert, IconButton, Popover } from '@mui/material';

import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';

interface IReportPostIconProps {
  achievementId: string;
}

export const ReportPostIcon: React.FC<IReportPostIconProps> = ({ achievementId }) => {
  const colours = useColours();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverId = Boolean(anchorEl) ? `achievement-post-reported-${achievementId}` : undefined;

  return (
    <>
      <IconButton onClick={handleClickPopover}>
        <ReportIcon
          aria-label="This post has been reported"
          height={24}
          width={24}
          sx={{ ...selectIconStyles(colours), color: colours.primaryThemeColour }}
        />
      </IconButton>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <Alert severity="warning">This post has been reported</Alert>
      </Popover>
    </>
  );
};
