import { IUser } from '../../../api/types';

export const getNameFromUser = (user?: IUser) => {
  if (user) {
    if (user.isClub) {
      return user.firstName;
    }
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
  }
  return '';
};
