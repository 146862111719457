import { Avatar, Box, Card, Stack, Typography } from '@mui/material';

interface IMessageProps {
  myMessage?: boolean;
  message: string;
  name: string;
}
export const Message: React.FC<IMessageProps> = ({ myMessage, message, name }) => {
  if (myMessage) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Card
          sx={{ maxWidth: '70%', marginBottom: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Stack p={1} pl={3} direction={'row'} alignItems="center">
            <Typography mr={2}>{message}</Typography>
            <Avatar alt={name} src="/static/images/avatar/1.jpg" />
          </Stack>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Card sx={{ maxWidth: '70%', marginBottom: 2 }}>
        <Stack p={1} pr={3} direction={'row'} alignItems="center">
          <Avatar alt={name} src="/static/images/avatar/1.jpg" />
          <Typography ml={2}>{message}</Typography>
        </Stack>
      </Card>
    </Box>
  );
};
