import { Box, Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { ProfilePicture } from '../../../components/profile-picture';

interface IMessageSummaryProps {
  id: string;
  userId: string;
  isNewMessage: boolean;
  name: string;
  message: string;
  onClick: (id: string) => void;
}

export const MessageSummary: React.FC<IMessageSummaryProps> = ({
  id,
  userId,
  name,
  message,
  onClick,
}) => {
  return (
    <List component="div" disablePadding>
      <ListItemButton
        sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}
        onClick={() => onClick(id)}
      >
        <ListItem alignItems="center" sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}>
          <Box sx={{ width: 50, height: 50, mr: 2 }}>
            <ProfilePicture userId={userId} />
          </Box>
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            secondaryTypographyProps={{
              variant: 'caption',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            primary={name}
            secondary={message}
          />
        </ListItem>
        <Divider variant="fullWidth" component="li" />
      </ListItemButton>
    </List>
  );
};
