import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { REPORT_COMMENT } from '../../../api/mutation/reportComment';
import { GET_ACHIEVEMENT_COMMENTS } from '../../../api/query/getAchievementComments';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getCommunityAchievements';
import { IUser } from '../../../api/types';
import { IAchievementDetails } from '../../../api/types/achievement';
import { IComment } from '../../../api/types/comment';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { ReportFormSchema } from '../../../validation/schemas/report-form';
import { ReportFormInputs } from '../../../validation/schemas/report-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { ModalHeader } from '../../components/modal-header';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IReportCommentModalProps {
  isOpen: boolean;
  user: IUser;
  commentId: string;
  getAchievementCommentsVariables: {
    id: string | undefined;
    userId: string;
    limit: number;
    offset: number;
  };
  getCommunityAchievementsVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
  onClose: () => void;
}

export const ReportCommentModal: React.FC<IReportCommentModalProps> = ({
  isOpen,
  user,
  getAchievementCommentsVariables,
  getCommunityAchievementsVariables,
  commentId,
  onClose,
}) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(ReportFormSchema),
    defaultValues: {
      reason: '',
    },
  });

  const [block, setBlock] = useState(false);
  const [reportUser, { loading, error }] = useMutation(REPORT_COMMENT);

  const handleReportUser = (formData: ReportFormInputs) => {
    reportUser({
      variables: {
        reporterId: cookies.signedInUser,
        commentId: commentId,
        reason: formData.reason,
        block: block,
      },
      update: (cache) => {
        if (block) {
          cache.updateQuery(
            {
              query: GET_COMMUNITY_ACHIEVEMENTS,
              variables: getCommunityAchievementsVariables,
            },
            (data) => ({
              getCommunityAchievements: {
                data: [
                  ...(data?.getCommunityAchievements?.data || []).filter(
                    (item: IAchievementDetails) => item.user.id !== user.id,
                  ),
                ],
                count: data?.getCommunityAchievements?.count - 1,
              },
            }),
          );
        }
        cache.updateQuery(
          {
            query: GET_ACHIEVEMENT_COMMENTS,
            variables: getAchievementCommentsVariables,
          },
          (data) => ({
            getAchievementComments: {
              data: [
                ...(data?.getAchievementComments?.data || []).filter(
                  (c: IComment) => c.id !== commentId,
                ),
              ],
              count: data?.getAchievementComments?.count - 1,
            },
          }),
        );
      },
    }).then(() => {
      onClose();
    });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <ModalHeader onClose={onClose} title={'Report comment'} />
        <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
          <form onSubmit={handleSubmit(handleReportUser)} style={{ width: '100%' }}>
            <Typography sx={cardSubtitleFontStyles(colours)}>
              {`Please provide the reason for reporting ${getNameFromUser(
                user,
              )}'s comment so that we can review and act on it`}
            </Typography>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              sx={{ mb: 1, mt: 2, backgroundColor: colours.primaryInverseColour }}
              error={Boolean(errors.reason?.message)}
              helperText={errors.reason?.message}
              id="reason"
              label="Reason"
              variant="outlined"
              {...register('reason')}
            />
            <FormControlLabel
              onChange={() => setBlock(!block)}
              control={<Checkbox checked={block} />}
              label="Block user"
            />
            <SubmitButton sx={{ mb: 1 }} loading={loading} label={'Report comment'} />
          </form>
          <ErrorAlert showError={Boolean(error?.message)} />
        </DialogContent>
      </Dialog>
    </>
  );
};
