import { gql } from '@apollo/client';

export const UPDATE_ACHIEVEMENT_COMMENT = gql`
  mutation UpdateAchievementComment($id: String!, $userId: String!, $comment: String!) {
    updateAchievementComment(id: $id, userId: $userId, comment: $comment) {
      id
      comment
    }
  }
`;
