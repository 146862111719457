import { IActivityDetails } from '../../../pages/my-progress/types';
import { IFilterState } from '../../page-layout/my-progress-filter-popover/types';
import { filterProgressList } from '../filter-progress-list';

export const transformProgressData = (
  getActivities: any,
  filterState: IFilterState,
  shouldSortOnDate: boolean,
): (IActivityDetails & { totalClimbs: number })[] => {
  const filtered = (getActivities ?? [])
    .map((i: any): (IActivityDetails & { totalClimbs: number }) | undefined => {
      const filter = filterProgressList(i?.activities, filterState);
      const bestClimbOfDay = [...filter]?.sort((a, b) => {
        const result = parseInt(b.grade) - parseInt(a.grade);
        if (result === 0) {
          if (!b.completed && a.completed) {
            return -1;
          }
          if (b.completed && !a.completed) {
            return 1;
          }
          return a.attempts - b.attempts;
        }
        return result;
      });

      if (bestClimbOfDay[0]) {
        return { ...bestClimbOfDay[0], date: i.date, totalClimbs: i?.activities.length };
      }

      return undefined;
    })
    .filter((f: IActivityDetails) => f);

  if (shouldSortOnDate) {
    return filtered.sort(
      (a: IActivityDetails, b: IActivityDetails) =>
        new Date(parseInt(a.date)).getTime() - new Date(parseInt(b.date)).getTime(),
    );
  }

  return filtered;
};
