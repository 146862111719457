import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Card, Stack } from '@mui/material';

import { GET_GOAL } from '../../api/query/getGoal';
import { ErrorAlert } from '../../component-library/components/error-alert';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { GoalContent } from '../../component-library/page-layout/goals-content';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ClimbingType } from '../my-progress/types';

export const GoalsPage: React.FC = () => {
  const [tab, setTab] = useState<ClimbingType>(ClimbingType.BOULDER);

  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error } = useQuery(GET_GOAL, {
    variables: {
      userId: cookies.signedInUser,
    },
  });

  const colours = useColours();

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading your goals...'}
      />
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        mb={2}
        sx={{
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
          m: 'auto',
        }}
      >
        <Card
          sx={{
            width: '100%',
          }}
        >
          <ErrorAlert showError={Boolean(error?.message)} />
          <GoalContent
            tab={tab}
            setTab={setTab}
            goals={{
              bouldering: data?.getGoal.find((f: any) => f.type === ClimbingType.BOULDER),
              topRope: data?.getGoal.find((f: any) => f.type === ClimbingType.TOP_ROPE),
              lead: data?.getGoal.find((f: any) => f.type === ClimbingType.LEAD),
            }}
          />
        </Card>
      </Stack>
    </Box>
  );
};
