import { gql } from '@apollo/client';

export const GET_BOULDERING_ACTIVITIES = gql`
  query GetBoulderingActivities($userId: String!, $limit: Int!, $offset: Int!) {
    getBoulderingActivities(userId: $userId, limit: $limit, offset: $offset) {
      __typename
      id
      date
      activities {
        id
        attempts
        comment
        completed
        grade
        intensity
        type
        achievement {
          id
        }
      }
    }
  }
`;
