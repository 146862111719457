export type TVScaleBoulderingGrades =
  | 'VB'
  | 'V0-'
  | 'V0'
  | 'V0+'
  | 'V1'
  | 'V2'
  | 'V3'
  | 'V4'
  | 'V5'
  | 'V6'
  | 'V7'
  | 'V8'
  | 'V9'
  | 'V10'
  | 'V11'
  | 'V12'
  | 'V13'
  | 'V14'
  | 'V15'
  | 'V16'
  | 'V17';

export const vScaleBoulderingGrades: TVScaleBoulderingGrades[] = [
  'VB',
  'V0-',
  'V0',
  'V0+',
  'V1',
  'V2',
  'V3',
  'V4',
  'V5',
  'V6',
  'V7',
  'V8',
  'V9',
  'V10',
  'V11',
  'V12',
  'V13',
  'V14',
  'V15',
  'V16',
  'V17',
];
export type TFontScaleBoulderingGrades =
  | '4'
  | '5'
  | '5+'
  | '6A'
  | '6A+'
  | '6B'
  | '6B+'
  | '6C'
  | '6C+'
  | '7A'
  | '7A+'
  | '7B'
  | '7B+'
  | '7C'
  | '7C+'
  | '8A'
  | '8A+'
  | '8B'
  | '8B+'
  | '8C+'
  | '9A';

export const fontScaleBoulderingGrades: TFontScaleBoulderingGrades[] = [
  '4',
  '5',
  '5+',
  '6A',
  '6A+',
  '6B',
  '6B+',
  '6C',
  '6C+',
  '7A',
  '7A+',
  '7B',
  '7B+',
  '7C',
  '7C+',
  '8A',
  '8A+',
  '8B',
  '8B+',
  '8C+',
  '9A',
];
