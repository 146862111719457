import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import { useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Grid, Stack } from '@mui/material';

import { GET_USER } from '../../api/query/getUser';
import { DeleteAccountModal } from '../../component-library/page-layout/delete-account-modal';
import { ImageUploader } from '../../component-library/page-layout/image-uploader';
import { UpdateEmail } from '../../component-library/page-layout/update-email';
import { UpdatePassword } from '../../component-library/page-layout/update-password';
import { UpdatePersonalDetails } from '../../component-library/page-layout/update-personal-details';
import { UpdateUserOptions } from '../../component-library/page-layout/update-user-options';
import { UserDetails } from '../../component-library/page-layout/user-details';
import { getDateInFuture } from '../../component-library/utils/get-date-in-future';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { CookieNames, RoutePath } from '../../constants';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { AvailableLogin, SignedInUserType } from '../../constants/cookie-names';

/**
 * This page contains all the details of the logged in user.
 * It should only be visible to logged in users.
 * Only the logged in user can see their own account details.
 */
export const MyAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const { removeCookie, setCookie, cookies } = useClimbingCommunityCookies();

  const [deleteAccountSelected, setDeleteAccountSelected] = useState(false);
  // const [createClubSelected, setCreateClubSelected] = useState(false);

  const { data, loading, error } = useQuery(GET_USER, {
    variables: { userId: cookies.signedInUser },
  });

  const handleOnClick = () => {
    setCookie(
      CookieNames.AVAILABLE_LOGINS,
      [
        ...(cookies.availableLogins ?? []).filter(
          (f: AvailableLogin) => f.type !== SignedInUserType.USER,
        ),
      ],
      {
        expires: getDateInFuture({ daysInFuture: 60 }),
      },
    );
    removeCookie(CookieNames.SIGNED_IN_USER);
    removeCookie(CookieNames.ROPE_GRADE_PREFERENCE);
    removeCookie(CookieNames.BOULDERING_GRADE_PREFERENCE);
    navigate(RoutePath.AUTHENTICATION);
  };

  // const handleOnClickCreateClub = () => setCreateClubSelected(true);
  // const hideCreateClubModal = () => setCreateClubSelected(false);

  const selectDeleteAccount = () => setDeleteAccountSelected(true);
  const hideDeleteAccountModal = () => setDeleteAccountSelected(false);

  return (
    <Stack direction="column" pt={2} justifyContent="center" alignItems="center">
      <DeleteAccountModal isOpen={deleteAccountSelected} onClose={hideDeleteAccountModal} />
      {/* <CreateClubModal isOpen={createClubSelected} onClose={hideCreateClubModal} /> */}
      <Grid container spacing={3} sx={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <Grid item xs={12}>
          <ImageUploader />
        </Grid>
        <Grid item xs={12}>
          <UserDetails data={data} loading={loading} error={error} />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate(`${RoutePath.PROFILE}${cookies.signedInUser}`)}
            endIcon={<PersonOutlineIcon />}
          >
            View Profile
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleOnClickCreateClub}
            endIcon={<GroupsIcon />}
          >
            Create Club
          </Button>
        </Grid> */}
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleOnClick} endIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Grid>
        <Grid item xs={12}>
          <UpdateUserOptions user={data?.getUser} loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <UpdateEmail />
        </Grid>
        <Grid item xs={12}>
          <UpdatePassword />
        </Grid>
        <Grid item xs={12}>
          <UpdatePersonalDetails />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
            onClick={selectDeleteAccount}
            endIcon={<DeleteIcon />}
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
