import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import HttpsIcon from '@mui/icons-material/Https';
import { Card, CardContent, CardHeader, FormControlLabel, Switch, Typography } from '@mui/material';

import { UPDATE_CLUB_PRIVACY } from '../../../api/mutation/updateClubPrivacy';
import { GET_USER } from '../../../api/query/getUser';
import { ErrorAlert } from '../../components/error-alert';
import { ToastNotification } from '../../components/toast-notification';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IUpdateClubPrivacyProps {
  isClubPrivate: boolean;
}

export const UpdateClubPrivacy: React.FC<IUpdateClubPrivacyProps> = ({ isClubPrivate }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [successToastDisplay, setSuccessToastDisplay] = useState(false);
  const [privateClub, setPrivateClub] = useState(isClubPrivate);

  const [updateClubPrivacy, { loading, error }] = useMutation(UPDATE_CLUB_PRIVACY);

  const handleUpdateClubPrivacy = () => {
    updateClubPrivacy({
      variables: {
        userId: cookies.signedInUser,
        isClubPrivate: !privateClub,
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_USER,
            variables: { userId: cookies.signedInUser },
          },
          (data) => ({
            getUser: {
              ...data?.getUser,
              isClubPrivate: result?.data?.updateClubPrivacy?.isClubPrivate,
            },
          }),
        );
      },
    }).then(() => {
      setPrivateClub(!privateClub);
      setSuccessToastDisplay(true);
    });
  };

  return (
    <Card sx={{ width: '100%' }}>
      <ToastNotification
        isOpen={successToastDisplay}
        message={'New privacy state saved!'}
        onClose={() => setSuccessToastDisplay(false)}
      />
      <CardHeader
        avatar={<HttpsIcon sx={{ color: colours.subtextColour }} />}
        title={<Typography sx={cardSubtitleFontStyles(colours)}>Update privacy</Typography>}
      />
      <CardContent>
        <FormControlLabel
          control={
            <Switch
              checked={privateClub}
              value={privateClub}
              disabled={loading}
              onClick={() => handleUpdateClubPrivacy()}
            />
          }
          label="Private club"
        />
        <ErrorAlert showError={Boolean(error?.message)} />
      </CardContent>
    </Card>
  );
};
