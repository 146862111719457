import { Alert } from '@mui/material';

interface IErrorAlertProps {
  showError: boolean;
  message?: string;
  noMargin?: boolean;
}

export const ErrorAlert: React.FC<IErrorAlertProps> = ({ showError, message, noMargin }) => {
  if (showError) {
    return (
      <Alert sx={{ width: '100%', marginBottom: noMargin ? 0 : 2 }} severity="error">
        {message ? message : 'Oops! Something went wrong!'}
      </Alert>
    );
  }

  return null;
};
