import { IActivityDetails } from '../../../pages/my-progress/types';
import { IFilterState } from '../../page-layout/my-progress-filter-popover/types';

export const filterProgressList = (
  list: IActivityDetails[],
  filterState: IFilterState,
): IActivityDetails[] => {
  if (filterState.completedOnly) {
    return list.filter((f) => f.completed);
  }
  if (filterState.fivePlusOnly) {
    return list.filter((f) => f.attempts === 5);
  }
  if (filterState.twoToFourOnly) {
    return list.filter((f) => f.attempts > 1 && f.attempts < 5);
  }
  if (filterState.flashedOnly) {
    return list.filter((f) => f.attempts === 1 && f.completed);
  }
  return list;
};
