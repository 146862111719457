import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query SearchUsers($email: String!, $firstname: String!, $lastname: String!) {
    searchUsers(email: $email, firstname: $firstname, lastname: $lastname) {
      id
      email
      firstName
      lastName
    }
  }
`;
