import { gql } from '@apollo/client';

export const GET_USER_ACTIVITIES = gql`
  query GetUserActivities($userId: String!, $limit: Int!, $offset: Int!) {
    getUserActivities(userId: $userId, limit: $limit, offset: $offset) {
      data {
        id
        attempts
        comment
        completed
        grade
        intensity
        type
        activityDay {
          date
        }
        achievement {
          id
        }
      }
      count
    }
  }
`;
