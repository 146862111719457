/**
 * All the routes for the website belong in here.
 * There should be no 'hard coded' routes in the project, and we should only use and add values in this enum.
 *  */
export enum RoutePath {
  HOMEPAGE = '/',
  AUTHENTICATION = '/authentication',
  CLUB_AUTHENTICATION = '/club-authentication',
  PLANNER = '/planner',
  MY_PLANNER = '/my-planner',
  PLAN = '/plan',
  BUDDY_SEARCH = '/buddy-search',
  COMMUNITY = '/community',
  MESSAGES = '/messages',
  MY_ACCOUNT = '/my-account',
  MY_CLUB = '/my-club',
  SHOE_EXCHANGE = '/shoe-exchange',
  CLIMBING_GRADES = '/climbing-grades',
  PROGRESS = '/progress',
  ACHIEVEMENT = '/achievement',
  ACTIVITY = '/activity',
  PROFILE = '/profile',
  MANAGE_COMMUNITY = '/manage-community',
  MANAGE_CLUB_COMMUNITY = '/manage-club-community',
  MANAGE_CLUBS = '/manage-clubs',
  HELP = '/help',
  GOALS = '/goals',
  PRIVACY_POLICY = '/privacy-policy',
  UNKNOWN = '*',
}

export const BASE_PATH = 'https://climbingcommunity.co.uk';
