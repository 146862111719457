import React, { PropsWithChildren } from 'react';

import { Box, Stack } from '@mui/material';

import { useIsMobile } from '../../utils/use-is-mobile';

interface IBannerProps {
  contentAlignment?: 'left' | 'center' | 'right';
  backgroundImage?: string;
  height?: string;
  width?: string;
}

/**
 * The banner component is used to display a large image agross the entire width of the website
 * with optional addition information on the left, right or center of the image.
 */
export const Banner: React.FC<PropsWithChildren<IBannerProps>> = ({
  contentAlignment = 'left',
  backgroundImage,
  height,
  width,
  children,
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        height: height ? height : `${window.innerHeight * 0.4}px`,
        minWidth: width ? width : '100%',
        backgroundImage,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Stack
        direction="row"
        justifyContent={isMobile ? 'center' : 'space-around'}
        alignItems="center"
        spacing={0}
        sx={{ height: '100%' }}
      >
        {Boolean(contentAlignment === 'right' || contentAlignment === 'center') && <div />}
        {Boolean(contentAlignment === 'right') && <div />}
        {children}
        {Boolean(contentAlignment === 'left' || contentAlignment === 'center') && <div />}
        {Boolean(contentAlignment === 'left') && <div />}
      </Stack>
    </Box>
  );
};
