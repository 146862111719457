import { Typography } from '@mui/material';

import { cardBodyFontStyles } from '../../styles/card-font-styles';
import { cardLargeTitleFontStyles } from '../../styles/card-large-title-font-styles';
import { cardSmallSubtitleFontStyles } from '../../styles/card-small-subtitle-font-styles';
import { useColours } from '../../utils/use-colours';

interface ITileContentProps {
  preTitle?: string;
  title?: string;
  subtitle?: string;
  bodyText?: React.ReactNode;
}

/**
 * This component is common and will be used in the card content and half banner content, to keep consistency
 * throughout the website.
 */
export const TileContent: React.FC<ITileContentProps> = ({
  preTitle,
  title,
  subtitle,
  bodyText,
}) => {
  const colours = useColours();
  return (
    <>
      {Boolean(preTitle) && (
        <Typography sx={cardSmallSubtitleFontStyles(colours)} color="text.secondary" gutterBottom>
          {preTitle}
        </Typography>
      )}
      {Boolean(title) && (
        <Typography variant="h2" sx={cardLargeTitleFontStyles} component="div">
          {title}
        </Typography>
      )}
      {Boolean(subtitle) && (
        <Typography sx={{ ...cardSmallSubtitleFontStyles, mb: 1.5 }} color="text.secondary">
          {subtitle}
        </Typography>
      )}
      {Boolean(bodyText) && (
        <Typography variant="body2" sx={cardBodyFontStyles(colours)}>
          {bodyText}
        </Typography>
      )}
    </>
  );
};
