import React from 'react';

import { ClubCommunityTab } from '../../../pages/manage-club-community/types';
import { ManageCommunityFriends } from '../manage-community-friends';
import { ManageCommunityRequests } from '../manage-community-requests';

interface IManageClubCommunityContent {
  tab: ClubCommunityTab;
}

export const ManageClubCommunityContent: React.FC<IManageClubCommunityContent> = ({ tab }) => {
  if (tab === ClubCommunityTab.members) {
    return <ManageCommunityFriends />;
  }

  return <ManageCommunityRequests isClub />;
};
