import React from 'react';

import { initializeApp } from 'firebase/app';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { App } from './pages';

const client = new ApolloClient({
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : 'https://climbing-community-api.onrender.com',
  cache: new InMemoryCache(),
});

const firebaseConfig = {
  storageBucket: 'climbing-community-80703.appspot.com',
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    {/* <React.StrictMode> */}
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CookiesProvider>
        <CssBaseline />
        <App />
      </CookiesProvider>
    </LocalizationProvider>
    {/* </React.StrictMode> */}
  </ApolloProvider>,
);
