import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import { useQuery } from '@apollo/client';
import HttpsIcon from '@mui/icons-material/Https';
import { Alert, Box, Button, Typography } from '@mui/material';

import { GET_PROFILE } from '../../api/query/getProfile';
import { ErrorAlert } from '../../component-library/components/error-alert';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { ProfilePicture } from '../../component-library/components/profile-picture';
import { Comment } from '../../component-library/page-layout/achievement-post/comments/comment';
import { ClubAddingButtons } from '../../component-library/page-layout/club-adding-buttons';
import { ClubMultiFeed } from '../../component-library/page-layout/club-multi-feed';
import { ProfileAchievements } from '../../component-library/page-layout/profile-achievements';
import { ProfileAddingButtons } from '../../component-library/page-layout/profile-adding-buttons';
import { ProfileGoalOverview } from '../../component-library/page-layout/profile-goal-overview';
import { ProfileOverviewStats } from '../../component-library/page-layout/profile-overview-stats';
import { ProfileUserOptionsPopover } from '../../component-library/page-layout/profile-user-options';
import { cardLargeTitleFontStyles } from '../../component-library/styles/card-large-title-font-styles';
import { getNameFromUser } from '../../component-library/utils/get-name-from-user';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error } = useQuery(GET_PROFILE, {
    variables: { userId: id, myUserId: cookies.signedInUser },
    fetchPolicy: 'network-only',
  });

  const [showAchievements, setShowAchievements] = useState(false);

  if (data?.getProfile?.blocked?.id) {
    return (
      <Box
        sx={{
          minHeight: `calc(${window.innerHeight}px - 65px)`,
          m: 'auto',
          pt: 2,
          width: COMPONENT_WIDTHS.BODY,
          maxWidth: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert severity="error">
            <Typography>You are unable to view this users profile</Typography>
          </Alert>
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </Box>
    );
  }

  const isFriend = Boolean(cookies.signedInUser === id || data?.getProfile?.friend?.id);

  const canShowAchievements = Boolean(isFriend && !data?.getProfile?.user?.isClub);

  return (
    <Box
      sx={{
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        m: 'auto',
        pt: 2,
        mb: 4,
        width: COMPONENT_WIDTHS.BODY,
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ height: 150, width: 150 }}>
          <ProfilePicture userId={id} />
        </Box>
        <Box sx={{ m: 2, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ ...cardLargeTitleFontStyles, display: 'flex', alignItems: 'center' }}>
            {getNameFromUser(data?.getProfile.user)}
            {data?.getProfile.user.isClubPrivate && (
              <HttpsIcon
                fontSize="small"
                style={{ color: colours.subtextColour, marginLeft: '6px', height: 16 }}
              />
            )}
          </Typography>
          {!data?.getProfile.user.isClub &&
            data?.getProfile.user.id &&
            data?.getProfile.user.id !== cookies.signedInUser && (
              <ProfileUserOptionsPopover user={data?.getProfile.user} />
            )}
        </Box>
        {data?.getProfile?.user?.isClub && (
          <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', lignItems: 'center' }}>
            <Comment comment={data?.getProfile?.user?.lastName} />
          </Box>
        )}
      </Box>
      <ErrorAlert showError={Boolean(error?.message)} />
      {!cookies.signedInClub && !data?.getProfile?.user?.isClub && (
        <ProfileAddingButtons id={id} getProfile={data?.getProfile} />
      )}
      {!cookies.signedInClub && data?.getProfile?.user?.isClub && (
        <ClubAddingButtons id={id} getProfile={data?.getProfile} />
      )}
      {loading && <LoadingSpinner sx={{ mb: 2 }} />}
      {Boolean(
        data?.getProfile && !data?.getProfile.user.hideSummary && !data?.getProfile.user.isClub,
      ) && <ProfileOverviewStats profile={data?.getProfile} />}
      {Boolean(
        data?.getProfile && !data?.getProfile?.user?.hideGoals && !data?.getProfile?.user?.isClub,
      ) && <ProfileGoalOverview profile={data?.getProfile} />}
      {Boolean(canShowAchievements && !showAchievements) && (
        <Box sx={{ m: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setShowAchievements(true)}
          >
            Show achievements
          </Button>
        </Box>
      )}
      {canShowAchievements && showAchievements && <ProfileAchievements userId={id} />}
      {data?.getProfile?.user?.isClub && id && isFriend && <ClubMultiFeed clubId={id} />}
    </Box>
  );
};
