import { IColours } from '../../../../../constants/colours';

export const getIntensitySliderColour = (colours: IColours, value?: number) => {
  if (value) {
    if (value === 10) {
      return colours.primaryThemeColour;
    }
    if (value > 7) {
      return colours.highPrimaryBackground;
    }
    if (value > 4) {
      return colours.midPrimaryBackground;
    }
    if (value > 3) {
      return colours.lightPrimaryBackground;
    }
  }

  return colours.lighterPrimaryBackground;
};
