import { gql } from '@apollo/client';

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($userId: String!, $password: String!) {
    deleteAccount(userId: $userId, password: $password) {
      id
      email
      firstName
      lastName
    }
  }
`;
