import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
  query GetMessages($id: String!) {
    getMessages(id: $id) {
      id
      message
      sender {
        id
        email
        firstName
        lastName
      }
      __typename
    }
  }
`;
