import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import { Box, Card, Stack, Tab, Tabs } from '@mui/material';

import { ClubSignUpContent } from '../../component-library/page-layout/club-sign-up-content';
import { LoginContent } from '../../component-library/page-layout/login-content';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';
import { CookieNames, RoutePath } from '../../constants';
import { AvailableLogin, SignedInUserType } from '../../constants/cookie-names';
import { AuthenticationPageType } from './types';

/**
 * The page where the users are able to login or sign up to the system.
 * The page will show login or sign up content depending on the tab selected by user.
 */
export const ClubAuthenticationPage: React.FC = () => {
  const colours = useColours();

  const navigate = useNavigate();
  const { cookies, setCookie, removeCookie } = useClimbingCommunityCookies();
  const [initialCookies] = useState(cookies);

  useEffect(() => {
    if (!initialCookies.signedInClub) {
      const signedInClub: AvailableLogin | undefined = initialCookies?.availableLogins?.find(
        (f: AvailableLogin) => f.type === SignedInUserType.CLUB,
      );
      if (signedInClub) {
        setCookie(CookieNames.SIGNED_IN_USER, signedInClub.id);
        setCookie(CookieNames.SIGNED_IN_CLUB, true);
        navigate(RoutePath.MY_CLUB);
      }
    }
  }, [initialCookies, navigate, removeCookie, setCookie]);

  // The tab should always be login or sign-up
  const [tab, setTab] = useState<AuthenticationPageType>(AuthenticationPageType.LOGIN);

  // When a tab is clicked - update the tab state to reflect the new tab
  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: AuthenticationPageType,
  ) => setTab(value);

  // Determine whether to show login or sign up page.
  const PageContentByTab: JSX.Element = useMemo(() => {
    switch (tab) {
      case AuthenticationPageType.SIGN_UP:
        return <ClubSignUpContent />;
      default:
        return <LoginContent isClub />;
    }
  }, [tab]);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" margin={5}>
      <Card
        sx={{
          width: 450,
          maxWidth: '100%',
          backgroundColor: colours.elevatedComponentColour,
          p: 2,
          pt: 0,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: 2,
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="Log in and sign up tabs"
            variant="fullWidth"
          >
            <Tab value={AuthenticationPageType.LOGIN} label="Club log in" />
            <Tab value={AuthenticationPageType.SIGN_UP} label="New club" />
          </Tabs>
        </Box>
        {PageContentByTab}
      </Card>
    </Stack>
  );
};
