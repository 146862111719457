import { useState } from 'react';

import { useMutation } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, ListItem, ListItemButton, Popover } from '@mui/material';

import { DELETE_PLAN_COMMENT } from '../../../../api/mutation/deletePlanComment';
import { GET_COMMUNITY_PLANS } from '../../../../api/query/getCommunityPlans';
import { GET_PLAN_COMMENTS } from '../../../../api/query/getPlanComments';
import { IPlanComment } from '../../../../api/types/plan-comment';
import { IPlannerDetails } from '../../../../api/types/planner';
import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';

interface IPlanCommentOptionsPopoverProps {
  comment: IPlanComment;
  getPlanCommentsVariables: {
    id: string | undefined;
    userId: string;
    limit: number;
    offset: number;
  };
  getPlanVariables?: {
    userId: string;
    limit: number;
    offset: number;
  };
  planId: string;
}
export const PlanCommentOptionsPopover: React.FC<IPlanCommentOptionsPopoverProps> = ({
  comment,
  getPlanCommentsVariables,
  getPlanVariables,
  planId,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const getQueryDoc = () => {
    return GET_COMMUNITY_PLANS;
  };

  const getResultData = (data: any) => {
    const mapper = (item: IPlannerDetails) => {
      if (item.id === planId) {
        return {
          ...item,
          commentsLength: (item?.commentsLength || 1) - 1,
        };
      }
      return item;
    };

    return {
      getCommunityPlans: {
        data: [...(data?.getCommunityPlans?.data || []).map(mapper)],
        count: data?.getCommunityPlans?.count,
      },
    };
  };

  const handleOnClickDelete = () => {
    deletePlanComment({
      variables: { id: comment.id, userId: cookies.signedInUser },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_PLAN_COMMENTS,
            variables: getPlanCommentsVariables,
          },
          (data) => ({
            getPlanComments: [
              ...(data?.getPlanComments || []).filter((c: IPlanComment) => c.id !== comment.id),
            ],
          }),
        );
        cache.updateQuery(
          {
            query: getQueryDoc(),
            variables: getPlanVariables,
          },
          (data) => getResultData(data),
        );
      },
    }).then(() => {
      handleClosePopover();
    });
  };

  const [deletePlanComment] = useMutation(DELETE_PLAN_COMMENT);

  const popoverId = Boolean(anchorEl) ? `comment-options-popover-${comment.id}` : undefined;

  return (
    <>
      <IconButton onClick={handleClickPopover} sx={{ height: 15, p: 0 }}>
        <MoreVertIcon height={14} width={14} sx={selectIconStyles(colours)} />
      </IconButton>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {comment?.user?.id === cookies.signedInUser && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleOnClickDelete}>Delete</ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
