import React from 'react';

import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';

import { JOIN_CLUB } from '../../../api/mutation/joinClub';
import { LEAVE_CLUB } from '../../../api/mutation/leaveClub';
import { REMOVE_FRIEND } from '../../../api/mutation/removeFriend';
import { GET_PROFILE } from '../../../api/query/getProfile';
import { IProfile } from '../../../api/types/profile';
import { ErrorAlert } from '../../components/error-alert';
import { SubmitButton } from '../../components/submit-button';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IClubAddingButtonsProps {
  id?: string;
  getProfile?: IProfile;
}

export const ClubAddingButtons: React.FC<IClubAddingButtonsProps> = ({ id, getProfile }) => {
  const { cookies } = useClimbingCommunityCookies();

  const [joinClub, { loading: sendLoading, error: sendError }] = useMutation(JOIN_CLUB, {
    update: (cache, result) => {
      cache.updateQuery(
        {
          query: GET_PROFILE,
          variables: { userId: id, myUserId: cookies.signedInUser },
        },
        (data) => ({
          getProfile: {
            ...data?.getProfile,
            friend: !data?.getProfile.user.isClubPrivate
              ? {
                  id: result?.data?.joinClub.id,
                }
              : undefined,
          },
        }),
      );
    },
  });

  const [leaveClub, { loading: leaveLoading, error: leaveError }] = useMutation(REMOVE_FRIEND, {
    update: (cache) => {
      cache.updateQuery(
        {
          query: GET_PROFILE,
          variables: { userId: id, myUserId: cookies.signedInUser },
        },
        (data) => ({
          getProfile: {
            ...data?.getProfile,
            friend: undefined,
          },
        }),
      );
    },
  });

  const friendshipId = getProfile?.friend?.id;
  const sentFriendRequestId = getProfile?.sentFriendRequest?.id;

  if (!getProfile || id === cookies.signedInUser) {
    return null;
  }

  return (
    <>
      <Box sx={{ m: 2 }}>
        <ErrorAlert showError={Boolean(sendError?.message || leaveError?.message)} />
        {friendshipId ? (
          <SubmitButton
            variant="outlined"
            fullWidth
            onClick={() =>
              leaveClub({
                variables: { id: friendshipId },
              })
            }
            loading={leaveLoading}
            label={'Leave club'}
          />
        ) : (
          <SubmitButton
            variant="contained"
            fullWidth
            onClick={() =>
              joinClub({
                variables: { userId: cookies.signedInUser, clubId: id },
              })
            }
            disabled={Boolean(sentFriendRequestId)}
            loading={sendLoading}
            label={sentFriendRequestId ? 'Request sent' : 'Join club'}
          />
        )}
      </Box>
    </>
  );
};
