import React, { useState } from 'react';

import { useNavigate } from 'react-router';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Stack } from '@mui/material';

import { RoutePath } from '../../../constants';
import { COMPONENT_HEIGHTS } from '../../../constants/component-dimentions';
import { useColourTheme } from '../../utils/use-colour-theme';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { NotificationsPopover } from '../notifications-popover';
import { SideNavigation } from '../side-navigation';

/**
 * The header will show on every page in the website.
 * This should be used to help the user easily navigate around the site.
 */
export const Header: React.FC = () => {
  // Determines whether or not to display the nav menu
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  // Handlers for opening and closing the nav menu
  const openNavMenu = () => setIsNavMenuOpen(true);
  const closeNavMenu = () => setIsNavMenuOpen(false);

  const { cookies } = useClimbingCommunityCookies();
  const navigate = useNavigate();

  const colourTheme = useColourTheme();
  const colours = useColours();

  return (
    <>
      <SideNavigation isNavMenuOpen={isNavMenuOpen} closeNavMenu={closeNavMenu} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={2}
        paddingRight={2}
        sx={{ backgroundColor: colours.primaryThemeColour, height: COMPONENT_HEIGHTS.HEADER }}
      >
        <Box sx={{ display: 'flex', flexBasis: '100%' }}>
          <MenuIcon
            onClick={openNavMenu}
            sx={{ color: colours.primaryInverseColour, cursor: 'pointer' }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexBasis: '100%', flexGrow: 1, justifyContent: 'center' }}>
          <Box
            onClick={() => {
              if (cookies.signedInClub) {
                navigate(`${RoutePath.PROFILE}${cookies.signedInUser}`);
              } else {
                navigate(RoutePath.HOMEPAGE);
              }
            }}
            sx={{
              height: '3em',
              width: '8em',
              backgroundImage:
                colourTheme === 'light'
                  ? 'url(/images/logo-light.png)'
                  : 'url(/images/logo-dark.png)',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexBasis: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {Boolean(cookies.signedInUser) && <NotificationsPopover />}
          <IconButton
            onClick={() => {
              if (cookies.signedInClub) {
                navigate(cookies.signedInUser ? RoutePath.MY_CLUB : RoutePath.AUTHENTICATION);
              } else {
                navigate(cookies.signedInUser ? RoutePath.MY_ACCOUNT : RoutePath.AUTHENTICATION);
              }
            }}
          >
            <AccountCircleOutlinedIcon sx={{ color: colours.primaryInverseColour }} />
          </IconButton>
        </Box>
      </Stack>
    </>
  );
};
