import * as Yup from 'yup';

import {
  yupConfirmPasswordValidation,
  yupEmailValidation,
  yupFirstNameValidation,
  yupLastNameValidation,
  yupPasswordValidation,
} from '../../yup';
import { SignUpFormInputs } from './types';

export const SignUpFormSchema: Yup.SchemaOf<SignUpFormInputs> = Yup.object({
  firstName: yupFirstNameValidation,
  lastName: yupLastNameValidation,
  email: yupEmailValidation,
  password: yupPasswordValidation,
  confirmPassword: yupConfirmPasswordValidation,
  acceptedPrivacyPolicy: Yup.bool()
    .required()
    .test('required', 'You must confirm before signing up', function (value) {
      return value === true;
    }),
});
