import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation UserSignup(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    userSignup(email: $email, password: $password, firstName: $firstName, lastName: $lastName) {
      id
      email
      firstName
      lastName
      hideGoals
      hideSummary
      autoPostGoalDisabled
      boulderingGradeType
      ropeGradeType
    }
  }
`;
