import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

import { useColours } from '../../utils/use-colours';

interface IModalHeaderProps {
  title: string;
  hideHeader?: boolean;
  onClose: () => void;
}

export const ModalHeader: React.FC<IModalHeaderProps> = ({ onClose, title, hideHeader }) => {
  const colours = useColours();

  if (hideHeader) {
    return null;
  }

  return (
    <Box
      sx={{
        m: 0,
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colours.primaryThemeColour,
      }}
    >
      <Typography variant="h6" component="h4" sx={{ color: colours.primaryInverseColour }}>
        {title}
      </Typography>
      <IconButton sx={{ padding: 0 }} aria-label="close" onClick={onClose}>
        <CloseIcon sx={{ color: colours.primaryInverseColour }} />
      </IconButton>
    </Box>
  );
};
