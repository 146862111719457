import React, { useMemo, useState } from 'react';

import { Box, Card, Tab, Tabs } from '@mui/material';

import { ClubCommunityPage } from '../../../pages/club-community';
import { UserPlannerPage } from '../../../pages/user-planner';
import { useColours } from '../../utils/use-colours';
import { ClubMembers } from '../show-club-members';
import { ClubMultiFeedType } from './types';

interface IClubMultiFeedProps {
  clubId: string;
}

export const ClubMultiFeed: React.FC<IClubMultiFeedProps> = ({ clubId }) => {
  const colours = useColours();
  const [tab, setTab] = useState<ClubMultiFeedType>(ClubMultiFeedType.ACHIEVEMENTS);

  const handleTabChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: ClubMultiFeedType,
  ) => setTab(value);

  const PageContentByTab: JSX.Element = useMemo(() => {
    switch (tab) {
      case ClubMultiFeedType.MEMBERS:
        return <ClubMembers clubId={clubId} />;
      case ClubMultiFeedType.PLANS:
        return <UserPlannerPage clubId={clubId} />;
      case ClubMultiFeedType.ACHIEVEMENTS:
        return <ClubCommunityPage clubId={clubId} />;
    }
  }, [tab, clubId]);

  return (
    <Card
      sx={{
        width: 450,
        maxWidth: '100%',
        backgroundColor: colours.elevatedComponentColour,
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="Log in and sign up tabs"
          variant="fullWidth"
        >
          <Tab value={ClubMultiFeedType.ACHIEVEMENTS} label="Feed" />
          <Tab value={ClubMultiFeedType.PLANS} label="Plans" />
          <Tab value={ClubMultiFeedType.MEMBERS} label="Members" />
        </Tabs>
      </Box>
      {PageContentByTab}
    </Card>
  );
};
