import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import HttpsIcon from '@mui/icons-material/Https';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Divider, List, Typography } from '@mui/material';

import { REMOVE_FRIEND } from '../../../api/mutation/removeFriend';
import { GET_CLUB_LIST } from '../../../api/query/getClubList';
import { Friend } from '../../../api/types/friend';
import { RoutePath } from '../../../constants';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ProfilePicture } from '../../components/profile-picture';
import { RouterLink } from '../../components/router-link';
import { SubmitButton } from '../../components/submit-button';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { Comment } from '../achievement-post/comments/comment';
import { DeleteFriendConfirmationModal } from './delete-friend-confirmation-modal';

export const ManageClubs: React.FC = () => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();
  const [deleteFriend, setDeleteFriend] = useState<Friend | undefined>(undefined);

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_CLUB_LIST, {
    variables: {
      userId: cookies.signedInUser,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  const [removeFriend, { loading: removeLoading, error: removeError }] = useMutation(
    REMOVE_FRIEND,
    {
      update: (cache) => {
        cache.updateQuery(
          {
            query: GET_CLUB_LIST,
            variables: {
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            },
          },
          (data) => {
            return {
              getClubList: {
                data: [
                  ...(data?.getClubList?.data || []).filter((f: any) => f.id !== deleteFriend?.id),
                ],
                count: data?.getClubList?.count - 1,
              },
            };
          },
        );
      },
    },
  );

  const handleRemoveFriend = () => {
    removeFriend({ variables: { id: deleteFriend?.id } }).then(() => {
      setDeleteFriend(undefined);
    });
  };

  return (
    <List sx={{ bgcolor: 'background.paper', paddingBottom: 0, p: 2 }} component="nav">
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center', ml: 2 }}>
          My clubs
        </Typography>
        <Button
          size="small"
          sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          color="primary"
          onClick={() =>
            refetch({
              userId: cookies.signedInUser,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {loading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading clubs...'}
        />
      )}
      {Boolean(data?.getClubList?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          You are not a member of any clubs.
          <br />
          Try searching for some and joining them!
        </Alert>
      )}
      <DeleteFriendConfirmationModal
        isClub
        deleteFriend={deleteFriend}
        loading={removeLoading}
        error={removeError?.message}
        handleClickCancel={() => setDeleteFriend(undefined)}
        handleClickConfirm={handleRemoveFriend}
      />
      <ErrorAlert showError={Boolean(error?.message || removeError?.message)} />
      {data?.getClubList?.data?.map((friend: Friend) => (
        <React.Fragment key={friend.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                <ProfilePicture userId={friend.user.id} navigable />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <RouterLink href={`${RoutePath.PROFILE}${friend.user.id}`}>
                  <Typography
                    sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center' }}
                  >
                    {getNameFromUser(friend.user)}
                    {friend.user.isClubPrivate && (
                      <HttpsIcon
                        fontSize="small"
                        style={{ color: colours.subtextColour, marginLeft: '6px', height: 14 }}
                      />
                    )}
                  </Typography>
                </RouterLink>
                <RouterLink href={`${RoutePath.PROFILE}${friend.user.id}`}>
                  <Comment comment={`${friend.user.lastName}`} />
                </RouterLink>
              </Box>
            </Box>
          </Box>
          <Divider />
        </React.Fragment>
      ))}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          data?.getClubList?.data?.length > 0 &&
            data?.getClubList?.data?.length < data?.getClubList?.count,
        ) && (
          <SubmitButton
            variant="outlined"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getClubList?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getClubList: {
                      data: [
                        ...previousResult.getClubList.data,
                        ...fetchMoreResult.getClubList.data,
                      ],
                      count: fetchMoreResult.getClubList.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </List>
  );
};
