import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      id
      email
      password
      firstName
      lastName
      hideGoals
      hideSummary
      autoPostGoalDisabled
      boulderingGradeType
      ropeGradeType
      isClub
      isClubPrivate
    }
  }
`;
