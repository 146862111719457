import React from 'react';

import { format } from 'date-fns';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Alert,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import { IActivityDetails } from '../../../pages/my-progress/types';

interface IProgressListProps {
  clickItem: (value: string) => void;
  items: (IActivityDetails & { totalClimbs: number })[];
}

export const ProgressList: React.FC<IProgressListProps> = ({ clickItem, items }) => {
  if (!items?.length) {
    return (
      <Box
        sx={{
          position: 'relative',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100vw',
          p: 2,
          pb: 0,
        }}
      >
        <Box>
          <Alert severity="info">There are no climbs to display in the list</Alert>
        </Box>
      </Box>
    );
  }

  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        paddingBottom: 0,
        height: `calc(${window.innerHeight}px - 170px - 56px)`,
        overflow: 'auto',
      }}
    >
      {items.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <ListItemButton
              sx={{ pl: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}
              onClick={() => clickItem(item.date)}
            >
              <ListItem
                alignItems="center"
                sx={{ cursor: 'pointer', paddingLeft: 0, paddingRight: 0 }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  secondaryTypographyProps={{
                    variant: 'caption',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  primary={
                    <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {format(new Date(parseInt(item.date)), 'do MMM yyyy')}
                      {item.completed ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <CancelIcon color="action" />
                      )}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primaryInverseColour"
                      >
                        {`Total climbs - ${item.totalClimbs}`}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </ListItemButton>
            <Divider variant="fullWidth" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
};
