import React from 'react';

import { useMutation } from '@apollo/client';
import { Dialog, DialogContent, Typography } from '@mui/material';

import { BLOCK_USER } from '../../../api/mutation/blockUser';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getCommunityAchievements';
import { GET_PROFILE } from '../../../api/query/getProfile';
import { IUser } from '../../../api/types';
import { IAchievementDetails } from '../../../api/types/achievement';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { ErrorAlert } from '../../components/error-alert';
import { ModalHeader } from '../../components/modal-header';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IBlockUserModalProps {
  isOpen: boolean;
  user: IUser;
  onClose: () => void;
}

export const BlockUserModal: React.FC<IBlockUserModalProps> = ({ isOpen, user, onClose }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const [reportUser, { loading, error }] = useMutation(BLOCK_USER);

  const handleBlockUser = () => {
    reportUser({
      variables: {
        blockerId: cookies.signedInUser,
        blockeeId: user.id,
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_COMMUNITY_ACHIEVEMENTS,
            variables: { userId: cookies.signedInUser, limit: 10, offset: 0 },
          },
          (data) => ({
            getCommunityAchievements: {
              data: [
                ...(data?.getCommunityAchievements?.data || []).filter(
                  (item: IAchievementDetails) => item.user.id !== user.id,
                ),
              ],
              count: data?.getCommunityAchievements?.count - 1,
            },
          }),
        );
        cache.updateQuery(
          {
            query: GET_PROFILE,
            variables: { userId: user.id, myUserId: cookies.signedInUser },
          },
          (data) => ({
            getProfile: {
              ...data?.getProfile,
              blocked: {
                id: result?.data?.blockUser?.id,
              },
            },
          }),
        );
      },
    }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title={'Block user'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        <Typography sx={cardSubtitleFontStyles(colours)}>
          {`Are you sure you want to block ${getNameFromUser(user)}? This is irreversible.`}
        </Typography>
        <SubmitButton
          sx={{ mb: 1, mt: 2 }}
          onClick={handleBlockUser}
          loading={loading}
          label={'Block user'}
        />
        <ErrorAlert showError={Boolean(error?.message)} />
      </DialogContent>
    </Dialog>
  );
};
