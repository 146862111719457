import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import { Alert, Box, Button, Divider, List, Typography } from '@mui/material';

import { GET_FRIEND_LIST } from '../../../api/query/getFriendList';
import { Friend } from '../../../api/types/friend';
import { RoutePath } from '../../../constants';
import { ErrorAlert } from '../../components/error-alert';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ProfilePicture } from '../../components/profile-picture';
import { RouterLink } from '../../components/router-link';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { cardTitleFontStyles } from '../../styles/card-title-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';

interface IClubMembersProps {
  clubId: string;
}

export const ClubMembers: React.FC<IClubMembersProps> = ({ clubId }) => {
  const colours = useColours();

  const [paginationValues] = useState<{ limit: number; offset: number }>({
    limit: 10,
    offset: 0,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_FRIEND_LIST, {
    variables: {
      userId: clubId,
      limit: paginationValues.limit,
      offset: paginationValues.offset,
    },
    initialFetchPolicy: 'network-only',
  });

  return (
    <List sx={{ bgcolor: 'background.paper', paddingBottom: 0, p: 2 }} component="nav">
      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...cardTitleFontStyles, display: 'flex', alignItems: 'center', ml: 2 }}>
          Club members
        </Typography>
        <Button
          size="small"
          sx={{ mr: 1, minWidth: 0, color: colours.primaryInverseColour }}
          variant="contained"
          color="primary"
          onClick={() =>
            refetch({
              userId: clubId,
              limit: paginationValues.limit,
              offset: paginationValues.offset,
            })
          }
        >
          <ReplayIcon />
        </Button>
      </Box>
      {loading && (
        <LoadingSpinner
          sx={{
            margin: 'auto',
            justifyContent: 'center',
            height: `calc(${window.innerHeight}px - 170px - 56px)`,
            width: '100%',
            p: 2,
            pb: 0,
          }}
          label={'Loading club members...'}
        />
      )}
      {Boolean(data?.getFriendList?.data?.length === 0) && (
        <Alert sx={{ width: '100%', marginBottom: 2 }} severity="info">
          There are currently no members in this club.
        </Alert>
      )}
      <ErrorAlert showError={Boolean(error?.message)} />
      {data?.getFriendList?.data?.map((friend: Friend) => (
        <>
          <Box
            key={friend.id}
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pt: 1 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: 25, width: 25, mr: 2, flexShrink: 0 }}>
                <ProfilePicture userId={friend.friend.id} navigable />
              </Box>
              <Box sx={{ overflow: 'hidden' }}>
                <RouterLink href={`${RoutePath.PROFILE}${friend.friend.id}`}>
                  <Typography sx={cardTitleFontStyles}>{getNameFromUser(friend.friend)}</Typography>
                </RouterLink>
                <Typography
                  sx={cardSubtitleFontStyles(colours)}
                >{`${friend.friend.email}`}</Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
        </>
      ))}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        {Boolean(
          data?.getFriendList?.data?.length > 0 &&
            data?.getFriendList?.data?.length < data?.getFriendList?.count,
        ) && (
          <SubmitButton
            variant="outlined"
            sx={{ mr: 2 }}
            label={'Load more'}
            loading={loading}
            onClick={() =>
              fetchMore({
                variables: {
                  offset: data?.getFriendList?.data.length,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  return {
                    getFriendList: {
                      data: [
                        ...previousResult.getFriendList.data,
                        ...fetchMoreResult.getFriendList.data,
                      ],
                      count: fetchMoreResult.getFriendList.count,
                    },
                  };
                },
              })
            }
          />
        )}
      </Box>
    </List>
  );
};
