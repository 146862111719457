import React from 'react';

import { useParams } from 'react-router';

import { useQuery } from '@apollo/client';
import { Alert, Box, Stack } from '@mui/material';

import { GET_PLAN } from '../../api/query/getPlan';
import { LoadingSpinner } from '../../component-library/components/loading-spinner';
import { PlannerPost } from '../../component-library/page-layout/planner-post';
import { useColours } from '../../component-library/utils/use-colours';
import { useClimbingCommunityCookies } from '../../component-library/utils/use-cookies';

export const PlanPage: React.FC = () => {
  const { id } = useParams();
  const { cookies } = useClimbingCommunityCookies();

  const { data, loading, error } = useQuery(GET_PLAN, {
    variables: {
      planId: id,
      userId: cookies?.signedInUser,
    },
    initialFetchPolicy: 'network-only',
  });

  const colours = useColours();

  if (loading) {
    return (
      <LoadingSpinner
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
        label={'Loading plan...'}
      />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          margin: 'auto',
          justifyContent: 'center',
          height: `calc(${window.innerHeight}px - 170px - 56px)`,
          width: '100%',
          p: 2,
          pb: 0,
        }}
      >
        <Alert sx={{ width: '100%' }} severity="error">
          Oops! Something went wrong!
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: `calc(${window.innerHeight}px - 65px)`,
        backgroundColor: colours.pageBackgroundColour,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
        sx={{
          maxWidth: '576px',
          m: 'auto',
          pt: 2,
        }}
      >
        <PlannerPost
          defaultOpenComments
          plan={data?.getPlan}
          refetchPlannerVariables={{
            userId: cookies.signedInUser,
            limit: 10,
            offset: 0,
            next24Hrs: false,
          }}
        />
      </Stack>
    </Box>
  );
};
