import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, ListItem, ListItemButton, Popover } from '@mui/material';

import { IUser } from '../../../api/types';
import { selectIconStyles } from '../../styles/select-icon-styls';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { BlockUserModal } from '../block-user-modal';
import { ReportUserModal } from '../report-user-modal';

interface IProfileUserOptionsPopoverProps {
  user: IUser;
}
export const ProfileUserOptionsPopover: React.FC<IProfileUserOptionsPopoverProps> = ({ user }) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [isReportUserModalOpen, setIsReportUserModalOpen] = useState(false);
  const [isBlockUserModalOpen, setIsBlockUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverId = Boolean(anchorEl) ? `user-options-popover-${user.id}` : undefined;

  return (
    <>
      <ReportUserModal
        user={user}
        isOpen={isReportUserModalOpen}
        onClose={() => {
          setIsReportUserModalOpen(false);
          handleClosePopover();
        }}
      />
      <BlockUserModal
        user={user}
        isOpen={isBlockUserModalOpen}
        onClose={() => {
          setIsBlockUserModalOpen(false);
          handleClosePopover();
        }}
      />
      <IconButton onClick={handleClickPopover} sx={{ height: 15, p: 0 }}>
        <MoreVertIcon height={14} width={14} sx={selectIconStyles(colours)} />
      </IconButton>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {user?.id !== cookies.signedInUser && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => setIsReportUserModalOpen(true)}>
                Report user
              </ListItemButton>
            </ListItem>
          )}
          {user?.id !== cookies.signedInUser && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => setIsBlockUserModalOpen(true)}>
                Block user
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
