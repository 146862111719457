import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

import { profilePictureThumbnailUrl } from '../constants/profile-picture-thumbnail-url';
import { profilePictureUrl } from '../constants/profile-picture-url';

export const uploadProfileFirebaseImage = ({
  signedInUser,
  mainImage,
  thumbnailImage,
}: {
  signedInUser: string;
  mainImage: File | Blob;
  thumbnailImage: File | Blob;
}) => {
  const storage = getStorage();

  const metadata = {
    contentType: 'image/jpeg',
  };

  const storageMainRef = ref(storage, profilePictureUrl(signedInUser));
  const storageThumbnailRef = ref(storage, profilePictureThumbnailUrl(signedInUser));

  const uploadMainTask = uploadBytesResumable(storageMainRef, mainImage, metadata);

  uploadMainTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          break;
        case 'storage/canceled':
          break;
        case 'storage/unknown':
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadMainTask.snapshot.ref).then((downloadURL) => {});
    },
  );

  const uploadThumbnailTask = uploadBytesResumable(storageThumbnailRef, thumbnailImage, metadata);

  uploadThumbnailTask.on(
    'state_changed',
    (snapshot) => {
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          break;
        case 'storage/canceled':
          break;
        case 'storage/unknown':
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadMainTask.snapshot.ref).then((downloadURL) => {});
    },
  );
};
