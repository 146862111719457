import React, { useState } from 'react';

import { Box, Card, Stack, Tab, Tabs } from '@mui/material';

import { ManageClubCommunityContent } from '../../component-library/page-layout/manage-club-community-content';
import { COMPONENT_WIDTHS } from '../../constants/component-dimentions';
import { ClubCommunityTab } from './types';

export const ManageClubCommunityPage: React.FC = () => {
  const [tab, setTab] = useState<ClubCommunityTab>(ClubCommunityTab.members);

  const handleTabChange = (_event: React.SyntheticEvent<Element, Event>, value: ClubCommunityTab) =>
    setTab(value);

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Box p={2} style={{ width: COMPONENT_WIDTHS.BODY, maxWidth: '100%' }}>
        <Card sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="Club management tabs"
            variant="fullWidth"
          >
            <Tab sx={{ p: 1 }} value={ClubCommunityTab.members} label="Members" />
            <Tab sx={{ p: 1 }} value={ClubCommunityTab.requests} label="Requests" />
          </Tabs>
          <ManageClubCommunityContent tab={tab} />
        </Card>
      </Box>
    </Stack>
  );
};
