import { ClimbingType } from '../../../../pages/my-progress/types';

export const gradeToString = ({ type }: { type?: ClimbingType }) => {
  if (type === ClimbingType.BOULDER) {
    return 'Bouldering';
  }

  if (type === ClimbingType.TOP_ROPE) {
    return 'Top Rope';
  }

  if (type === ClimbingType.LEAD) {
    return 'Lead';
  }
};
