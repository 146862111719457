import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Slider, Typography } from '@mui/material';

import { IColours } from '../../../constants/colours';
import { IActivityDetails } from '../../../pages/my-progress/types';
import { IGoalDetails } from '../../../validation/schemas/set-goal-form/types';
import { cardBodyFontStyles } from '../../styles/card-font-styles';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';
import { getAttemptsSliderColour } from '../activity-details-modal/details/utils/get-attempts-slider-colour';
import { getGradeStyles } from '../activity-details-modal/details/utils/get-grade-styles';
import { getIntensitySliderColour } from '../activity-details-modal/details/utils/get-intensity-slider-colour';
import { numberToGrade } from '../progress-graph/utils/number-to-grade';

export const ActivityDetails: React.FC<{
  colours: IColours;
  activity?: IActivityDetails;
  goal?: IGoalDetails;
}> = ({ colours, activity, goal }) => {
  const { cookies } = useClimbingCommunityCookies();
  return (
    <>
      <Box m={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          size="small"
          sx={getGradeStyles(colours, goal?.grade, activity?.grade)}
          variant="contained"
        >
          {numberToGrade({
            boulderingGradeType: cookies.boulderingGradePreference,
            ropeGradeType: cookies.ropeGradePreference,
            grade: activity?.grade,
            type: activity?.type,
          })}
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {activity?.completed ? (
            <>
              <Typography sx={{ ...cardBodyFontStyles(colours), mr: 1 }}>Completed!</Typography>
              <CheckCircleIcon color="primary" />
            </>
          ) : (
            <>
              <Typography sx={{ ...cardBodyFontStyles(colours), mr: 1 }}>Unfinished!</Typography>
              <CancelIcon color="action" />
            </>
          )}
        </Box>
      </Box>
      <Box m={1} mt={2} sx={{ textAlign: 'left' }}>
        <Typography variant="caption">Attempts</Typography>
        <Box pl={1} pr={1}>
          <Slider
            sx={{ color: getAttemptsSliderColour(colours, activity?.attempts) }}
            valueLabelDisplay="off"
            max={5}
            min={1}
            step={1}
            value={activity?.attempts}
            marks={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: '5+', value: 5 },
            ]}
          />
        </Box>
      </Box>

      <Box m={1} mt={2} sx={{ textAlign: 'left' }}>
        <Typography variant="caption">Intensity</Typography>
        <Box pl={1} pr={1}>
          <Slider
            sx={{ color: getIntensitySliderColour(colours, activity?.intensity) }}
            valueLabelDisplay="off"
            max={10}
            min={0}
            step={1}
            value={activity?.intensity}
            marks={[
              { label: 0, value: 0 },
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 6, value: 6 },
              { label: 7, value: 7 },
              { label: 8, value: 8 },
              { label: 9, value: 9 },
              { label: 10, value: 10 },
            ]}
          />
        </Box>
      </Box>
    </>
  );
};
