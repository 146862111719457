import * as React from 'react';

import { Link } from 'react-router-dom';

import { styled } from '@mui/system';

/**
 * text-decoration - We don't want links to turn purple when clicked.
 * color - We also need the original colour to come through, rather than the default blue of a link.
 *  */
const LinkComponent = styled('div')({
  '> a': {
    'text-decoration': 'none',
    color: 'inherit',
    display: 'inline',
    height: '100%',
  },
});

/**
 * A wrapper component for the routing link. It is needed to override the default styling of links.
 */
export const RouterLink: React.FC<React.PropsWithChildren<{ href: string; sx?: any }>> = ({
  href,
  children,
  sx,
}) => {
  return (
    <LinkComponent sx={sx}>
      <Link to={href}>{children}</Link>
    </LinkComponent>
  );
};
