import * as Yup from 'yup';

import { ClimbingType } from '../../../pages/my-progress/types';
import { LogActivityFormInputs } from './types';

export const LogActivityFormFormSchema: Yup.SchemaOf<LogActivityFormInputs> = Yup.object({
  attempts: Yup.number().required(),
  comment: Yup.string().max(256),
  completed: Yup.boolean(),
  achievement: Yup.boolean(),
  intensity: Yup.number().required(),
  date: Yup.date().required(),
  grade: Yup.string().required(),
  type: Yup.mixed<ClimbingType>()
    .oneOf([ClimbingType.BOULDER, ClimbingType.LEAD, ClimbingType.TOP_ROPE])
    .required(),
});
