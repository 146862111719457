import { useEffect, useState } from 'react';

import { Dialog, DialogContent, Grid, List } from '@mui/material';

import { IBuddySearch } from '../../../../api/types';
import { ModalHeader } from '../../../components/modal-header';
import { ListingContent } from './listing-content';
import { OtherListings } from './other-listings';

interface IBuddyModalProps {
  open: boolean;
  selectOtherListing: (item: IBuddySearch) => void;
  handleClose: () => void;
  item?: IBuddySearch;
}

export const BuddyModal: React.FC<IBuddyModalProps> = ({
  open,
  selectOtherListing,
  handleClose,
  item,
}) => {
  const [viewAllListingsFromUser, setViewAllListingsFromUser] = useState(false);

  useEffect(() => {
    setViewAllListingsFromUser(false);
  }, [open]);

  const handleSelectOtherListing = (item: IBuddySearch) => {
    setViewAllListingsFromUser(false);
    selectOtherListing(item);
  };

  return (
    <Dialog
      open={open && Boolean(item)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader onClose={handleClose} title={`${item?.user.firstName} ${item?.user.lastName}`} />
      <DialogContent dividers>
        <Grid container spacing={3}>
          {viewAllListingsFromUser ? (
            <Grid item xs={12}>
              <List>
                <OtherListings selectOtherListing={handleSelectOtherListing} id={item?.user.id} />
              </List>
            </Grid>
          ) : (
            <ListingContent
              item={item}
              setViewAllListingsFromUser={() => setViewAllListingsFromUser(true)}
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
