import { useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, IconButton, List, ListItem, ListItemButton, Popover } from '@mui/material';

import { IAchievementDetails } from '../../../../api/types/achievement';
import { selectIconStyles } from '../../../styles/select-icon-styls';
import { useColours } from '../../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../../utils/use-cookies';
import { ReportPostModal } from '../../report-post-modal';
import { ReportPostIcon } from '../report-post-icon';

interface IViewerAchievementOptionsPopoverProps {
  achievement: IAchievementDetails;
  refetchAchievementsVariables: {
    userId: string;
    limit: number;
    offset: number;
  };
}

export const ViewerAchievementOptionsPopover: React.FC<IViewerAchievementOptionsPopoverProps> = ({
  achievement,
  refetchAchievementsVariables,
}) => {
  const colours = useColours();
  const { cookies } = useClimbingCommunityCookies();

  const [isReportPostModalOpen, setIsReportPostModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverId = Boolean(anchorEl) ? `achievement-options-popover-${achievement.id}` : undefined;

  return (
    <>
      <ReportPostModal
        achievementId={achievement.id}
        isOpen={isReportPostModalOpen}
        user={achievement.user}
        onClose={() => {
          setIsReportPostModalOpen(false);
          handleClosePopover();
        }}
        refetchAchievementsVariables={refetchAchievementsVariables}
      />
      <Box>
        {achievement.reported && <ReportPostIcon achievementId={achievement.id} />}
        <IconButton onClick={handleClickPopover} sx={{ height: 35 }}>
          <MoreHorizIcon
            height={24}
            width={24}
            sx={{ ...selectIconStyles(colours), color: colours.defaultTextColour }}
          />
        </IconButton>
      </Box>
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
      >
        <List
          sx={{
            bgcolor: 'background.paper',
            overflow: 'auto',
            p: 0,
          }}
        >
          {Boolean(achievement.user.id !== cookies.signedInUser) && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => setIsReportPostModalOpen(true)}>
                Report post
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};
