export const getDifferenceInGradeString = (grade: string = '0', goal: string = '0') => {
  const result = parseInt(goal) - parseInt(grade);

  if (result === undefined) {
    return '';
  }
  if (result > 1) {
    return `${result} grades from your goal`;
  }
  if (result === 1) {
    return `${result} grade from your goal`;
  }
  if (result === 0) {
    return 'This is your goal grade!';
  }
  if (result < 0) {
    return 'Better than your goal grade!';
  }
};
