import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';

import { REPORT_USER } from '../../../api/mutation/reportUser';
import { GET_COMMUNITY_ACHIEVEMENTS } from '../../../api/query/getCommunityAchievements';
import { GET_PROFILE } from '../../../api/query/getProfile';
import { IUser } from '../../../api/types';
import { IAchievementDetails } from '../../../api/types/achievement';
import { COMPONENT_WIDTHS } from '../../../constants/component-dimentions';
import { ReportFormSchema } from '../../../validation/schemas/report-form';
import { ReportFormInputs } from '../../../validation/schemas/report-form/types';
import { ErrorAlert } from '../../components/error-alert';
import { ModalHeader } from '../../components/modal-header';
import { SubmitButton } from '../../components/submit-button';
import { cardSubtitleFontStyles } from '../../styles/card-subtitle-font-styles';
import { getNameFromUser } from '../../utils/get-name-from-user';
import { useColours } from '../../utils/use-colours';
import { useClimbingCommunityCookies } from '../../utils/use-cookies';

interface IReportUserModalProps {
  isOpen: boolean;
  user: IUser;
  onClose: () => void;
}

export const ReportUserModal: React.FC<IReportUserModalProps> = ({ isOpen, user, onClose }) => {
  const { cookies } = useClimbingCommunityCookies();
  const colours = useColours();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(ReportFormSchema),
    defaultValues: {
      reason: '',
    },
  });

  const [block, setBlock] = useState(false);
  const [reportUser, { loading, error }] = useMutation(REPORT_USER);

  const handleReportUser = (formData: ReportFormInputs) => {
    reportUser({
      variables: {
        reporterId: cookies.signedInUser,
        reporteeId: user.id,
        reason: formData.reason,
        block: block,
      },
      update: (cache, result) => {
        cache.updateQuery(
          {
            query: GET_COMMUNITY_ACHIEVEMENTS,
            variables: { userId: cookies.signedInUser, limit: 10, offset: 0 },
          },
          (data) => ({
            getCommunityAchievements: {
              data: [
                ...(data?.getCommunityAchievements?.data || []).filter(
                  (item: IAchievementDetails) => item.user.id !== user.id,
                ),
              ],
              count: data?.getCommunityAchievements?.count - 1,
            },
          }),
        );
        cache.updateQuery(
          {
            query: GET_PROFILE,
            variables: { userId: user.id, myUserId: cookies.signedInUser },
          },
          (data) => ({
            getProfile: {
              ...data?.getProfile,
              blocked: {
                id: result?.data?.reportUser?.id,
              },
            },
          }),
        );
      },
    }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose} title={'Report user'} />
      <DialogContent sx={{ padding: 2, maxWidth: '100%', width: COMPONENT_WIDTHS.BODY }} dividers>
        <form onSubmit={handleSubmit(handleReportUser)} style={{ width: '100%' }}>
          <Typography sx={cardSubtitleFontStyles(colours)}>
            {`Please provide the reason for reporting ${getNameFromUser(
              user,
            )} so that we can review and act on it`}
          </Typography>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            sx={{ mb: 1, mt: 2, backgroundColor: colours.primaryInverseColour }}
            error={Boolean(errors.reason?.message)}
            helperText={errors.reason?.message}
            id="reason"
            label="Reason"
            variant="outlined"
            {...register('reason')}
          />
          <FormControlLabel
            onChange={() => setBlock(!block)}
            control={<Checkbox checked={block} />}
            label="Block user"
          />
          <SubmitButton sx={{ mb: 1 }} loading={loading} label={'Report user'} />
        </form>
        <ErrorAlert showError={Boolean(error?.message)} />
      </DialogContent>
    </Dialog>
  );
};
