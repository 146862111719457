import * as React from 'react';

import { ApolloError } from '@apollo/client';
import List from '@mui/material/List';

import { IMessageRequest } from '../../../api/types/message-request';
import { IMessageRoom } from '../../../api/types/message-room';
import { MessageRooms } from './messages';
import { Requests } from './requests';

interface IMessageListProps {
  messages?: IMessageRoom[];
  requests?: IMessageRequest[];
  handleSetSelectedRequestId: (id: string) => void;
  handleSetSelectedMessageId: (id: string) => void;
  messageRoomsLoading?: boolean;
  messageRoomsError: ApolloError | undefined;
  messageRequestsLoading: boolean;
  messageRequestsError?: ApolloError | undefined;
}

export const MessageList: React.FC<IMessageListProps> = ({
  messages,
  requests,
  handleSetSelectedMessageId,
  handleSetSelectedRequestId,
  messageRoomsLoading,
  messageRoomsError,
  messageRequestsLoading,
  messageRequestsError,
}) => {
  const [messagesOpen, setMessagesOpen] = React.useState(true);
  const [requestsOpen, setRequestsOpen] = React.useState(false);

  return (
    <List sx={{ bgcolor: 'background.paper', paddingBottom: 0 }} component="nav">
      <Requests
        requests={requests}
        handleSetSelectedRequestId={handleSetSelectedRequestId}
        messageRequestsLoading={messageRequestsLoading}
        messageRequestsError={messageRequestsError}
        requestsOpen={requestsOpen}
        setRequestsOpen={(val) => setRequestsOpen(val)}
        closeMessageList={() => setMessagesOpen(false)}
      />
      <MessageRooms
        messages={messages}
        handleSetSelectedMessageId={handleSetSelectedMessageId}
        messageRoomsLoading={messageRoomsLoading}
        messageRoomsError={messageRoomsError}
        messagesOpen={messagesOpen}
        setMessagesOpen={(val) => setMessagesOpen(val)}
        closeRequestsList={() => setRequestsOpen(false)}
      />
    </List>
  );
};
