import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: String!) {
    getNotifications(userId: $userId) {
      id
      date
      read
      achievement {
        id
      }
      achievementLike {
        user {
          firstName
          lastName
        }
        achievement {
          id
        }
      }
      achievementComment {
        user {
          firstName
          lastName
        }
        achievement {
          id
          user {
            id
          }
        }
      }
      friend {
        id
        friend {
          firstName
          lastName
        }
      }
      friendRequest {
        id
        sender {
          firstName
          lastName
        }
      }
      message {
        id
        message
        sender {
          firstName
          lastName
        }
      }
      messageRequest {
        id
        message
        requester {
          firstName
          lastName
        }
      }
      messageRoom {
        id
        user1 {
          firstName
          lastName
        }
      }
      planComment {
        id
        plan {
          id
          user {
            id
          }
        }
        user {
          firstName
          lastName
        }
      }
      planJoin {
        id
        plan {
          id
          user {
            id
          }
        }
        user {
          firstName
          lastName
        }
      }
    }
  }
`;
