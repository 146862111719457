import { gql } from '@apollo/client';

export const GET_GOAL = gql`
  query GetGoal($userId: String!) {
    getGoal(userId: $userId) {
      id
      type
      active
      grade
      flash
      targetDate
      beginningDate
      completionDate
    }
  }
`;
