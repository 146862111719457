import { gql } from '@apollo/client';

export const GET_MESSAGE_ROOMS = gql`
  query GetMessageRooms($userId: String!) {
    getMessageRooms(userId: $userId) {
      id
      lastMessage
      user1 {
        id
        email
        firstName
        lastName
      }
      user2 {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
